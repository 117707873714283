import React, { useContext, useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import { AuthContext } from "../../../auth/AuthProvider";
import { iconPath } from "../../../constants/constants";
import Pusher from "pusher-js";
import { useLocation } from "react-router-dom";

export const ChatBody = ({
  specificChat,
  selectedMessageId,
  handleFileSelection,
  pdf,
  addNewMessage,
  setMessage,
  message,
}) => {
  const chatBody = useRef();
  const path = iconPath();
  const [receiverData, setReceiverData] = useState(null);
  const { user } = useContext(AuthContext);
  const [allMessages, setAllMessages] = useState(specificChat || []);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const initialEmployeeId = searchParams.get("employeeId");
  const initialUserName = searchParams.get("username");
  const chatId = searchParams.get("chatId");
  const [employeeId, setEmployeeId] = useState(initialEmployeeId);
  const [username, setUsername] = useState(initialUserName);

  const renderFilePreview = () => {
    if (pdf) {
      const fileType = pdf?.type;

      // Check the pdf type and render preview accordingly
      if (fileType.startsWith("image/")) {
        // Render image preview
        return <img src={URL.createObjectURL(pdf)} alt="Preview" width={100} />;
      } else if (fileType === "application/pdf") {
        // Render PDF preview
        return <img src={`${path}filexx.svg`} alt="pdf-preview" />;
      } else if (fileType === "text/plain" || fileType === "text/csv") {
        // Render text or CSV preview
        const reader = new FileReader();
        reader.onload = (e) => {
          return <pre>{e.target.result}</pre>;
        };
        reader.readAsText(pdf);
      }
    }
  };

  useEffect(() => {
    if (selectedMessageId && allMessages) {
      if (chatBody.current) {
        chatBody.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }

      const receiverDataTemp = allMessages
        ?.filter(
          (message) =>
            message?.receiver?.email === user?.data?.email ||
            message?.sender?.email === user?.data?.email
        )
        ?.map((message) => {
          const isUserSender = message?.sender?.email === user?.data?.email;
          return {
            user: isUserSender ? message?.receiver : message?.sender,
            message: message?.message,
            file: message?.file,
            created_at: message?.created_at,
          };
        });

      setReceiverData(receiverDataTemp);
    }
  }, [selectedMessageId, allMessages, user?.data?.email]);

  useEffect(() => {
    const pusher = new Pusher("87639c06bf9f7a1e1934", {
      cluster: "eu",
    });
    const channel = pusher.subscribe("chat-channel");
    channel.bind("ChatEvent", function (data) {
      if (+data?.chat?.chat_id === +selectedMessageId) {
        setAllMessages([...allMessages, data?.chat]);
      }
    });

    // Cleanup function to disconnect Pusher when component unmounts
    return () => {
      pusher.disconnect();
    };
  });

  useEffect(() => {
    if (specificChat) {
      setAllMessages(specificChat);
    }
  }, [specificChat]);

  useEffect(() => {
    if (employeeId != null) {
      setEmployeeId(employeeId);
    }
  }, [employeeId]);

  const renderUserMessages = () => {
    return allMessages?.map((chat) => {
      if (
        chat?.sender?.email !== user?.data?.email &&
        chat?.receiver?.email === user?.data?.email
      ) {
        return (
          <div className="sender d-flex" key={chat?.id}>
            <div>
              <div className="img">
                {chat?.sender?.photo && (
                  <img src={chat?.sender?.photo} alt="proifleSender" />
                )}
              </div>
            </div>
            <div>
              <h4>
                {chat?.sender?.first_name} {chat?.sender?.last_name}{" "}
                <span>{chat?.created_at?.split(" ")[1]}</span>
              </h4>
              <div className="msg_box">
                {chat?.message !== "null" && chat?.message}
                <div className="file_attach">
                  {chat?.file && (
                    <a
                      href={chat?.file}
                      target="_blank"
                      download
                      rel="noreferrer"
                    >
                      {chat?.file_extension === "pdf" ? (
                        <SVG src={`${path}filexx.svg`} />
                      ) : (
                        <img src={chat?.file} alt="Preview" width={50} />
                      )}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }
      if (
        chat?.sender?.email === user?.data?.email &&
        chat?.receiver?.email !== user?.data?.email
      ) {
        return (
          <div className=" reciver d-flex justify-content-end" key={chat?.id}>
            <div>
              <h4>
                {chat?.sender?.first_name} {chat?.sender?.last_name}{" "}
                <span>{chat?.created_at?.split(" ")[1]}</span>
              </h4>
              <div className="msg_box">
                {chat?.message !== "null" && chat?.message}
                <div className="file_attach">
                  {chat?.file && (
                    <a
                      href={chat?.file}
                      target="_blank"
                      download
                      rel="noreferrer"
                    >
                      {chat?.file_extension === "pdf" ? (
                        <SVG src={`${path}filexx.svg`} />
                      ) : (
                        <img src={chat?.file} alt="Preview" width={50} />
                      )}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }
      return null;
    });
  };

  return (
    <>
      {selectedMessageId || username ? (
        <div className="chat_room" id={selectedMessageId || username}>
          <div className="chat_header shadow-sm">
            <div className="d-flex align-items-center">
              <div>
                {/* <div className="img">
                  {receiverData && receiverData[0]?.user?.photo && (
                    <img src={receiverData[0]?.user?.photo} alt="sender" />
                  )}
                </div> */}
              </div>
              <div className="w-100">
                <p>#{selectedMessageId}</p>
                <h4>
                  {receiverData && receiverData[0]?.user?.first_name && (
                    <>
                      {receiverData[0]?.user?.first_name}{" "}
                      {receiverData[0]?.user?.last_name}
                    </>
                  )}
                  {!receiverData && username}
                </h4>
              </div>
            </div>
          </div>
          <div className="chat_body">
            <div className="div-scroller" ref={chatBody}>
              {renderUserMessages()}
            </div>
          </div>
          <div className="chat_footer">
            <div className="input-group p-1 row align-items-center justify-content-between">
              <textarea
                className="textAreaDefault col-10"
                name="message"
                onChange={(e) => setMessage(e?.target?.value)}
                value={message}
                defaultValue={message}
              ></textarea>
              <ul className="d-flex col-2 justify-content-end">
                <li>
                  <div className="file-preview">{renderFilePreview()}</div>
                </li>
                <li>
                  <button className="btn border-0 ">
                    <span className="input-group-text bg-transparent border-0 p-0">
                      <label htmlFor="pdfInput" style={{ cursor: "pointer" }}>
                        <SVG src={`${path}attach.svg`} />
                      </label>
                      <input
                        disabled={!selectedMessageId}
                        type="file"
                        id="pdfInput"
                        accept=".pdf, image/*, .txt, .csv"
                        className="file-input"
                        onChange={(e) => handleFileSelection(e)}
                      />{" "}
                    </span>
                  </button>
                </li>
                <li>
                  <button
                    className="btn border-0"
                    onClick={() => {
                      if (selectedMessageId || message?.length || pdf) {
                        addNewMessage();
                      }
                    }}
                  >
                    <span className="input-group-text bg-transparent border-0 p-0">
                      <SVG src={`${path}send.svg`} />
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
  
  
};
