import React from "react";
import { useIntl } from "react-intl";
import {  Zoom, ToastContainer } from 'react-toastify';    
import PropTypes from "prop-types";

const ToastifyProvider = ({ children }) => {
  const { locale } = useIntl();

  //   toast.success(
  // 	<div>
  // 	  <span>This is a toast with an image:</span>
  // 	  <img src={`${path}loading.gif`} alt="Your Image" />
  // 	</div>,
  // 	{
  // 	  // Other options if needed
  // 	}
  //   )
  return (
    <>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        position="top-center"
        newestOnTop
        rtl={locale === "ar" && true}
        pauseOnFocusLoss={false}
        draggable
        transition={Zoom}

      />
      {children}
    </>
  );
};

ToastifyProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ToastifyProvider;
