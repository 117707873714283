import React, { useContext, useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { NavLink, useMatch, useNavigate } from "react-router-dom";
import {
  BsLayoutWtf,
  BsBoxArrowInRight,
  BsFillFilePersonFill,
  BsTelephoneOutbound,
  BsChatDots,
  BsWallet2,
  BsFillPersonLinesFill,
  BsQuestionCircle,
  BsBodyText,
  BsPostage,
} from "react-icons/bs";
import { toast } from "react-toastify";
import { iconPath } from "../../constants/constants";
import { AuthContext } from "../../auth/AuthProvider";
import { DashboaredHeader } from "../sharedComponents/DashboaredHeader";
import { PATH_PAGE } from "../../routes/Paths";
import { Tooltip, Zoom } from "@mui/material";
import { useIntl } from "react-intl";
import { useQueryClient, useQuery } from "react-query";
import Pusher from "pusher-js";
import { axiosInstance } from "../../config/api/AxiosConfig";
import { LocaleContext } from "./../../config/localization/LanguageProvider";
import axios from "axios";

export const SideBar = ({ collapse = true, setCollapse = () => {} }) => {
  const path = iconPath();
  const navigate = useNavigate();
  const { messages } = useIntl();
  const { user, setUser } = useContext(AuthContext);
  const { locale } = useContext(LocaleContext);
  const [count, setCount] = useState(0);
  const queryClient = useQueryClient();

  useEffect(() => {
    const pusher = new Pusher("87639c06bf9f7a1e1934", {
      cluster: "eu",
    });

    if (!user) {
      console.error("User is not defined.");
      return;
    }

    const fetchInitialUnreadCount = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("user_data"))?.token;

        if (!token) {
          console.error("User token not found.");
          return;
        }

        const response = await axios.get(
          "https://admin.uplevel.sa/public/api/chat-count",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        

        if (response.data && response.data.success) {
          setCount(response.data.data); // Assuming API response returns the unread message count
        } else {
          console.error("Invalid response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching initial unread count:", error);
      }
    };

    fetchInitialUnreadCount();

    const userId = user.data.id;
    const channel = pusher.subscribe(`user${userId}`);

    const handleUnreadMessageCountEvent = (data) => {
      // console.log(data, 'UnreadMessageCountEvent data');
      // if (data.unreadMessagesCount) {
        // fetchInitialU  nreadCount();
        try {
          const token = JSON.parse(localStorage.getItem("user_data"))?.token;
  
          if (!token) {
            console.error("User token not found.");
            return;
          }
  
          const response =  axios.get(
            "https://admin.uplevel.sa/public/api/chat-count",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ).then(res=>{
            setCount(res.data.data);
          });
        } catch (error) {
          console.error("Error fetching initial unread count:", error);
        }
        // setCount(data.unreadMessagesCount);
        queryClient.setQueryData("getNotifications", (prevData) => {
          const oldData = prevData?.data?.data || prevData;
          return {
            count: oldData?.count + 1,
            notifications: [data.unreadMessagesCount, ...oldData?.notifications],
          };
        });
      // }
    };

    channel.bind("chatCountEvent", handleUnreadMessageCountEvent);

    return () => {
      channel.unbind("chatCountEvent", handleUnreadMessageCountEvent);
      pusher.disconnect();
    };
  }, [user, queryClient]);

const handleLogout = () => {
  const userDataString = localStorage.getItem('user_data');
  if (userDataString) {
 
    const userData = JSON.parse(userDataString);
    const authToken = userData?.token;
    
    
    axios.post('https://admin.uplevel.sa/public/api/mentor/v1/auth/logout', {}, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    })
    
    .then((response) => {
      if (response.status === 200) {
        localStorage.removeItem('user_data');
        setUser(null);
        toast.success(messages["LogOUt"]);
        navigate('/login');
      } else {
        toast.error(response.data.message);
      }
    })
    
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
  }
};

  return (
    <div className={`sidebar ${!collapse ? "collapsed" : ""}`}>
      <div
        onClick={() => navigate(PATH_PAGE.clHome)}
        className={`sidebar-heading ${collapse ? "collapsed" : ""}`}
      >
        <img src={`${path}logo.png`} alt="logo" className="website-image" />
        {user?.data?.photo ? (
          <img src={user.data.photo} alt="profile" className="logo-image" />
        ) : (
          <div className="rounded-div"></div>
        )}
      </div>
      {!collapse && (
        <DashboaredHeader
          text={`${user?.data?.first_name} ${user?.data?.last_name}`}
          className="heading_dash  text-center"
        />
      )}

      <Nav
        defaultActiveKey="/home"
        className={`scroller ${
          collapse ? "align-items-center" : "px-4  justify-content-center"
        }`}
      >
        <Tooltip
          title={messages["dashboard"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.dashboard}
            className={`nav-link ${
              useMatch(PATH_PAGE.dashboard) ? "active-link" : ""
            } ${collapse ? "justify-content-center" : ""}`}
          >
            <BsLayoutWtf className={`${collapse && "increase-width"}`} />
            {!collapse && messages["dashboard"]}
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["profile"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.profile}
            className={`nav-link ${
              useMatch(PATH_PAGE.profile) ? "active-link" : ""
            } ${collapse ? "justify-content-center" : ""}`}
          >
            <BsFillFilePersonFill
              className={`${collapse && "increase-width"}`}
            />{" "}
            {!collapse && messages["profile"]}
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["public_profile"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.publicProfile}
            className={`nav-link ${
              useMatch(PATH_PAGE.publicProfile) ? "active-link" : ""
            } ${collapse ? "justify-content-center" : ""}`}
          >
            <BsFillPersonLinesFill
              className={`${collapse && "increase-width"}`}
            />{" "}
            {!collapse && messages["public_profile"]}
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["reservations"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.reserv}
            className={`nav-link ${
              useMatch(PATH_PAGE.reserv) ? "active-link" : ""
            } ${collapse ? "justify-content-center" : ""}`}
          >
            <BsTelephoneOutbound
              className={`${collapse && "increase-width"}`}
            />{" "}
            {!collapse && messages["reservations"]}
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["packages"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.services}
            className={`nav-link ${
              useMatch(PATH_PAGE.services) ? "active-link" : ""
            } ${collapse ? "justify-content-center" : ""}`}
          >
            <BsPostage className={`${collapse && "increase-width"}`} />
            {!collapse && messages["packages"]}
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["messages_chat"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.chat}
            className={`nav-link ${
              useMatch(PATH_PAGE.chat) ? "active-link" : ""
            }  ${collapse ? "justify-content-center" : ""}`}
          >
            <BsChatDots className={`${collapse && "increase-width"}`} />{" "}
            {!collapse && messages["messages_chat"]} ({count})
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["wallet"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.wallet}
            className={`nav-link ${
              useMatch(PATH_PAGE.wallet) ? "active-link" : ""
            } ${collapse ? "justify-content-center" : ""}`}
          >
            <BsWallet2 className={`${collapse && "increase-width"}`} />{" "}
            {!collapse && messages["wallet"]}
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["privacy_policy"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.privacyPolicy}
            className={`nav-link ${
              useMatch(PATH_PAGE.privacyPolicy) ? "active-link" : ""
            } ${collapse ? "justify-content-center" : ""}`}
          >
            <BsQuestionCircle className={`${collapse && "increase-width"}`} />{" "}
            {!collapse && messages["privacy_policy"]}
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["FAQ"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.faq}
            className={`nav-link ${
              useMatch(PATH_PAGE.faq) ? "active-link" : ""
            } ${collapse ? "justify-content-center" : ""}`}
          >
            <BsBodyText className={`${collapse && "increase-width"}`} />{" "}
            {!collapse && messages["FAQ"]}
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["logout"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <p
            onClick={handleLogout}
            className={`nav-link ${
              collapse ? "justify-content-center increase-width" : ""
            }`}
          >
            <BsBoxArrowInRight className={`${collapse && "increase-width"}`} />{" "}
            {!collapse && messages["logout"]}
          </p>
        </Tooltip>

        {/* Add more NavLink items with icons as needed */}
      </Nav>

      {/* Toggle Button */}
      <div
        className={`toggle-btn ${collapse && "left-postion"}`}
        onClick={() => setCollapse(!collapse)}
      >
        {collapse ? ">" : "<"}
      </div>
    </div>
  );
};
