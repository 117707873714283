import React from "react";
import { Link } from "react-router-dom";

import SVG from "react-inlinesvg";
import { iconPath } from "../../../../constants/constants";

import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { PATH_PAGE } from "../../../../routes/Paths";

export const DetailsBody = ({ clientReservationRate }) => {
  const path = iconPath();
  const { messages } = useIntl();
  const navigate = useNavigate();

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <div className="details_left details_card">
            <div className="d-flex justify-content-between">
              <p> #{clientReservationRate?.id} </p>
              <span>{messages[clientReservationRate?.service?.service]}</span>
            </div>
            <h3>{clientReservationRate?.service?.name}</h3>
            <ul>
              {/* <li>
                <SVG src={`${path}b1.svg`} />
                Chemical
              </li> */}
              <li>
                <SVG src={`${path}b2.svg`} />
                {clientReservationRate?.date}
              </li>
              <h3>
                {clientReservationRate?.service?.price}{" "}
                <small> {messages["sar"]} </small>
              </h3>
            </ul>
          </div>
        </div>
        {/* <div className="col-md-6">
          <div className="details_right details_card">
            <h3>Zoom Link</h3>
            <div className="input-group p-1 zoom_input">
              <button className="btn border-0">
                <span className="input-group-text bg-transparent border-0 p-0">
                  <SVG src={`${path}link-alt.svg`} />
                </span>
              </button>
              <input
                className="form-control border-0 bg-transparent"
                placeholder="Zoom Link"
                disabled
              />
              <button className="btn border-0">
                <span className="input-group-text bg-transparent border-0 p-0">
                  <SVG src={`${path}files.svg`} />
                </span>
              </button>
            </div>
          </div>
        </div> */}
        <div className="col-md-12">
          <div className="details_full details_card">
            <div className="d-md-flex justify-content-between align-items-center">
              <div className="d-flex">
                <div>
                  <img
                    src={clientReservationRate?.client?.photo}
                    className="avtar"
                    alt="profileImage"
                  />
                  {/* <SVG src={`${path}avatar.svg`} className="avtar" /> */}
                </div>
                <div>
                  <h3>
                    {" "}
                    {clientReservationRate?.mentor?.first_name}{" "}
                    {clientReservationRate?.mentor?.last_name}{" "}
                  </h3>
                  <ul>
                    <li> {clientReservationRate?.mentor?.job_title} </li>
                    <li> {clientReservationRate?.mentor?.city?.name} </li>
                  </ul>
                </div>
              </div>
              <div>
                <button
                  className="btn_link px-5 py-3"
                  onClick={() =>
                    navigate(
                      `${PATH_PAGE.MentorDetails}?id=${clientReservationRate?.mentor?.id}`
                    )
                  }
                >
                  {messages["view_profile"]}
                </button>
              </div>
            </div>
            <p className="mb-3">{clientReservationRate?.mentor?.bio}</p>
            <div>
              {clientReservationRate?.mentor?.experiences?.map(
                (data, index) => (
                  <span className="bg-transparent mx-1">{data?.name}</span>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
