import React, { useContext, useState } from "react";
import SVG from "react-inlinesvg";
import Modal from "react-bootstrap/Modal";
import { iconPath } from "../../../constants/constants";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../../auth/AuthProvider";
import { CallAPI } from "../../../config/api/ApiConfig";
import { PATH_PAGE } from "../../../routes/Paths";
import { useIntl } from "react-intl";
import { Button } from "react-bootstrap";

export const ProfileInfo = () => {
  const path = iconPath();
  const { messages } = useIntl();
  const [show, setShow] = useState(false);
  const { user } = useContext(AuthContext);

  const { refetch: suspend } = CallAPI({
    name: "suspend",
    url: "/mentor/v1/auth/toggle-suspend-profile",
    refetchOnWindowFocus: false,
    enabled: false,
    method: "post",
    body: {
      suspend_reason: "",
    },
    select: (res) => res?.data,
    onSuccess: (res) => {
      toast.success(res?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  return (
    <div className="profile_info">
      <div className="container-fluid">
        <div className="info_box">
          <div className="row">
            <div className="col-12">
              <div className="d-md-flex justify-content-between">
                <div className="info_left">
                  <h3>
                    {user?.data?.first_name} {user?.data?.last_name}
                  </h3>
                  <ul>
                    <li>
                      <SVG src={`${path}location.svg`} />
                      {user?.data?.country?.name},{user?.data?.city?.name},
                      {user?.data?.region?.name}
                    </li>
                    <li>
                      <SVG src={`${path}msg.svg`} />
                      {user?.data?.email}
                    </li>
                    <li>
                      <SVG src={`${path}call.svg`} />
                      +966{user?.data?.mobile}
                    </li>
                  </ul>
                </div>
                <div className="info_right">
                  <ul className="d-flex">
                    <li>
                      <Link to={PATH_PAGE.UserprofileEdit}>
                        <SVG src={`${path}edit.svg`} />
                      </Link>
                    </li>
                    <li>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setShow(true)}
                      >
                        <SVG src={`${path}del.svg`} />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)} className="unFav_modal">
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body className="text-center">
          <SVG src={`${path}warning.svg`} />
          <h3>{messages["are.you.sure"]}</h3>
          <p>{messages["suspend.account"]}</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="btn btn-cancel"
            onClick={() => {
              setShow(false);
            }}
          >
            {messages["no"]}
          </Button>
          <Button
            className="btn btn-yes"
            onClick={() => {
              suspend();
              setShow(false);
            }}
          >
            {messages["yes"]}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
