import React, { useState } from "react";
import { CategoriesDetailsCard } from "./CategoriesDetailsCard";
import { Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";
import { Group } from "./Group/Group";
import { Indvidual } from "./Indvidual/Indvidual";
import { Packages } from "./Packages/Packages";

import { CallAPI } from "../../../config/api/ApiConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { PATH_PAGE } from "../../../routes/Paths";

export const MentorDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const { messages } = useIntl();
  const [currentPage, setCurrentPage] = useState(1);

  const { data: mentor } = CallAPI({
    name: ["mentorDetails", currentPage],
    url: "/mentors/" + id + `?page=${currentPage}`,
    refetchOnWindowFocus: false,
    cacheTime: 500,
    staleTime: 500,
    enabled: true,
    select: (res) => res?.data?.data,     
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNavigation = (param, pack) => {
    const queryParams = { id: param };
    const searchParams = new URLSearchParams(queryParams).toString();
    if (pack) {
      navigate(`${PATH_PAGE.booking}?${searchParams}&disable="time"`);
    } else {
      navigate(`${PATH_PAGE.booking}?${searchParams}`);
    }
  };

  return (
    <div className="categoris_details-wrapper faq_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <CategoriesDetailsCard />
          </div>
          <div className="col-lg-4">
            <div className="mentor_info_view">
              <h3>
                <div>{messages["about_mentor"]}</div>
                {/* <a
                  href={mentor?.mentor?.resume_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages["download.resume"]}
                </a> */}
              </h3>

              <p>{mentor?.mentor?.bio}</p>
            </div>
          </div>
          <div className="col-12">
            <div className="mentor_info_view text-center mt-5">
              <h3>{messages["Sessions"]}</h3>
              <p>
                {
                  messages[
                    "find_out_about_the_services_provided_by_the_consulting_provider"
                  ]
                }
              </p>
            </div>
            <Tabs
              defaultActiveKey="Individual"
              id="fill-tab-example"
              fill
              className="mb-3"
              onSelect={() => setCurrentPage(1)}
            >
              <Tab
                eventKey="Individual"
                title={messages["sessions.individual"]}
              >
                <Indvidual
                  mentorData={mentor}
                  itemServices={mentor?.services?.individual_session}
                  handlePageChange={handlePageChange}
                  handleNavigation={handleNavigation}
                />
              </Tab>
              <Tab eventKey="Group" title={messages["sessions.group"]}>
                <Group
                  mentorData={mentor}
                  itemServices={mentor?.services?.group_session}
                  handlePageChange={handlePageChange}
                  handleNavigation={handleNavigation}
                />
              </Tab>
              <Tab eventKey="Packages" title={messages["sessions.package"]}>
                <Packages
                  mentorData={mentor}
                  itemServices={mentor?.services?.packages}
                  handlePageChange={handlePageChange}
                  handleNavigation={handleNavigation}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};
