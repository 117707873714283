import React, { useContext } from "react";
import { AuthContext } from "../../../auth/AuthProvider";
import { useIntl } from "react-intl";

export const ProfileAvatar = () => {
  const { user } = useContext(AuthContext);
  const { messages } = useIntl();

  return (
    <div className="profile_avatar">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="head_box">
              <h2 className="heading_dash mb-1">{messages["profile"]}</h2>
              <p className="heading_dis">
                {messages["view_and_edit_your_data_from_here"]}
              </p>
            </div>
            <div className="avatar_banner">
              <div>
                <div
                  className="avtart_img"
                  style={{
                    border: user?.data?.photo ? "none" : "6px solid #fff",
                  }}
                >
                  {user?.data?.photo && (
                    <img src={user?.data?.photo} alt="profile" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
