import { Profile } from "../../pages/dashboard/profile/Profile";
import { Review } from "../../pages/dashboard/reviews/Review";
import { Report } from "../../pages/dashboard/reviews/report/Report";
import { Client } from "../../pages/dashboard/reviews/client/Client";
import { Reserv } from "../../pages/dashboard/reserv/Reserv";

import { ReservDetails } from "../../pages/dashboard/reserv/reservDetails/ReservDetails";
import { ChatRoom } from "../../pages/dashboard/chat/ChatRoom";

import { ProfileEdit } from "../../pages/dashboard/profile/profileEdit/ProfileEdit";
import { PublicProfile } from "../../pages/dashboard/publicProfile/PublicProfile";
import { PublicEdit } from "../../pages/dashboard/publicProfile/publicProfileEdit/PublicEdit";
import { PublicReserv } from "../../pages/dashboard/publicProfile/publicEditReserv/PublicReserv";
import { PublicResum } from "../../pages/dashboard/publicProfile/publicEditResum/PublicResum";

import { Contact } from "../../pages/contact/Contact";
import { TermsAndConidiotns } from "../../pages/TermsAndConditions/TermsAndConidiotns";
import { Faq } from "../../pages/faq/Faq";
import { HomeDashboard } from "../../pages/dashboard/HomeDashboard";
import { SignUp } from "../../pages/Authentications/SignUp/SignUp";
import { ResetPassword } from "../../pages/Authentications/ResetPassword/ResetPassword";
import { About } from "../../pages/About/About";
import { PrivacyPolicy } from "../../pages/PrivacyPolicy/PrivacyPolicy";
import Login from "../../pages/Authentications/Login/Login";
import { PATH_PAGE } from "../../routes/Paths";
import { initialStructure } from "../../constants/constants";
import { Wallet } from "../../pages/dashboard/wallet/Wallet";
import { WalletWithdraw } from "../../pages/dashboard/wallet/withdraw/WalletWithdraw";
import { Services } from "../../pages/dashboard/services/Services";
import { ServicesDetails } from "../../pages/dashboard/services/ServicesDetails";
import { AddNewService } from "../../pages/dashboard/services/AddNewService";
import { NotificationSettings } from "../../pages/dashboard/NotificationSettings/NotificationSettings";
import { RegisterCompleted } from "../../pages/Authentications/SignUp/RegisterCompleted";

export const mentorStructure = [
  {
    ...initialStructure,
    path: PATH_PAGE.dashboard,
    name: "dashboard",
    element: <HomeDashboard />,
    isPrivate: true,
    hasFooter: true,
    hasNav: true,
  },

  {
    ...initialStructure,
    path: PATH_PAGE.termsConditions,
    name: "termsAndCondition",
    element: <TermsAndConidiotns />,
    isPrivate: false,
    hasFooter: true,
    hasNav: false,
  },
  {
    path: PATH_PAGE.reviews,
    name: "reviews",
    element: <Review />,
    isPrivate: true,
    hasFooter: false,
    hasNav: true,
  },
  {
    path: PATH_PAGE.report,
    name: "report",
    element: <Report />,
    isPrivate: true,
    hasFooter: false,
    hasNav: true,
  },
  {
    path: PATH_PAGE.client,
    name: "client",
    element: <Client />,
    isPrivate: true,
    hasFooter: false,
    hasNav: true,
  },
  {
    path: PATH_PAGE.profile,
    name: "profile",
    element: <Profile />,
    isPrivate: true,
    hasFooter: false,
    hasNav: true,
  },
  {
    path: PATH_PAGE.chat,
    name: "chat",
    element: <ChatRoom />,
    isPrivate: true,
    hasFooter: false,
    hasNav: true,
  },

  {
    path: PATH_PAGE.profileEdit,
    name: "profileEdit",
    element: <ProfileEdit />,
    isPrivate: true,
    hasFooter: false,
    hasNav: true,
  },
  {
    path: PATH_PAGE.publicProfile,
    name: "publicProfile",
    element: <PublicProfile />,
    isPrivate: true,
    hasFooter: false,
    hasNav: true,
  },
  {
    path: PATH_PAGE.publicProfileEdit,
    name: "publicProfileEdit",
    element: <PublicEdit />,
    isPrivate: true,
    hasFooter: false,
    hasNav: true,
  },
  {
    path: PATH_PAGE.publicEditReserv,
    name: "publicEditReserv",
    element: <PublicReserv />,
    isPrivate: true,
    hasFooter: false,
    hasNav: true,
  },
  {
    path: PATH_PAGE.publicEditResum,
    name: "publicEditResum",
    element: <PublicResum />,
    isPrivate: true,
    hasFooter: false,
    hasNav: true,
  },

  {
    ...initialStructure,
    path: PATH_PAGE.contact,
    name: "contact",
    element: <Contact />,
    isPrivate: true,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.faq,
    name: "faq",
    element: <Faq />,
    isPrivate: false,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.about,
    name: "About",
    element: <About />,
    isPrivate: false,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.privacyPolicy,
    name: "privacyPolicy",
    element: <PrivacyPolicy />,
    isPrivate: false,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.login,
    name: "Login",
    element: <Login />,
    isPrivate: false,
    hasFooter: true,
    hasNav: false,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.signUp,
    name: "signUp",
    element: <SignUp />,
    isPrivate: false,
    hasFooter: true,
    hasNav: false,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.resetPassword,
    name: "ResetPassword",
    element: <ResetPassword />,
    isPrivate: false,
    hasFooter: true,
    hasNav: false,
  },

  {
    ...initialStructure,
    path: PATH_PAGE.wallet,
    name: "wallet",
    element: <Wallet />,
    isPrivate: true,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.walletWithdraw,
    name: "walletwithdraw",
    element: <WalletWithdraw />,
    isPrivate: true,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.services,
    name: "services",
    element: <Services />,
    isPrivate: true,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.servicesDetails,
    name: "services",
    element: <ServicesDetails />,
    isPrivate: true,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.addNewService,
    name: "addNewService",
    element: <AddNewService />,
    isPrivate: true,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.reserv,
    name: "reserv",
    element: <Reserv />,
    isPrivate: true,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.reservDetails,
    name: "reservDetails",
    element: <ReservDetails />,
    isPrivate: true,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.notfication,
    name: "notoficationSettings",
    element: <NotificationSettings />,
    isPrivate: true,
    hasFooter: true,
    hasNav: true,
  },
  {
    path: PATH_PAGE.signUpComplete,
    name: "completeRegister",
    element: <RegisterCompleted />,
    isPrivate: true,
    hasFooter: true,
    hasNav: true,
  },
];
