import React from "react";

export const TransferDesign = ({
  text = "transferDesign",
  className = "red",
}) => {
  return (
    <div className="transfer">
      <p className={className}>{text}</p>
    </div>
  );
};
