import React, { useContext, useEffect, useState } from "react";
import { ChatList } from "./ChatList";
import { ChatBody } from "./ChatBody";
import { useIntl } from "react-intl";
import { CallAPI } from "../../../config/api/ApiConfig";
import { AuthContext } from "../../../auth/AuthProvider";
import { AxiosContext } from "../../../config/api/AxiosConfig";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

export const ChatRoom = () => {
  const { messages } = useIntl();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [message, setMessage] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [receiverId, setReceiverId] = useState(null);
  const id = searchParams.get("id");

  const { user } = useContext(AuthContext);
  const { setLoader } = useContext(AxiosContext);

  useEffect(() => {
    if (id) {
      setSelectedMessageId(id);
    }
  }, []);

  const { data: chats } = CallAPI({
    name: "getChatsUser",
    url: "/client/v1/chats",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const { data: specificChat, refetch: getSpecificChat } = CallAPI({
    name: ["specificChatClient", selectedMessageId],
    url: `/client/v1/chats/${selectedMessageId}`,
    refetchOnWindowFocus: false,
    enabled: selectedMessageId ? true : false,
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      if (res) {
        setReceiverId(
          res
            ?.filter(
              (which) =>
                which?.receiver?.email !== user?.data?.email ||
                which?.sender?.email !== user?.data?.email
            )
            ?.map((d) => ({
              user: d?.receiver || d?.sender,
              message: d?.message,
              file: d?.file,
              created_at: d?.created_at,
            }))[0]?.user?.id
        );
      }
    },
  });

  const addNewMessage = async () => {
    setLoader(true);
    const formData = new FormData();

    formData.append("message", message);
    formData.append("booking_id", selectedMessageId);
    formData.append("receiver_id", receiverId);
    // need to adjust this line
    if (pdf) {
      formData.append("file", pdf || "");
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/client/v1/chats`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );

      if (response?.data?.status) {
        toast.success(messages["messages_send_success"]);
        setMessage("");
        setPdf(null);
      } else {
        toast.error(response?.data?.message);
      }
      setLoader(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoader(false);
    }
  };

  const handleFileSelection = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setPdf(selectedFile);
    }
  };

  return (
    <div className="container-fluid chat_wrapper">
      <div className="head_box">
        <h2 className="heading_dash mb-1">{messages["messages"]}</h2>
        <p className="heading_dis">{messages["messages_chat_info"]}</p>
      </div>
      <div className="row">
        <div className="col-md-4 d-md-block d-lg-block d-none">
          <ChatList
            chats={chats}
            setSelectedMessageId={setSelectedMessageId}
            selectedMessageId={selectedMessageId}
          />
        </div>
        <div className="col-md-8">
          {selectedMessageId && specificChat &&(
            <ChatBody
              specificChat={specificChat}
              selectedMessageId={selectedMessageId}
              handleFileSelection={handleFileSelection}
              pdf={pdf}
              addNewMessage={addNewMessage}
              setMessage={setMessage}
              message={message}
            />
          )}
        </div>
      </div>
    </div>
  );
};
