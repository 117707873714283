import React from "react";
import { Col, Row } from "react-bootstrap";
import { PATH_PAGE } from "../../../routes/Paths";
import { Link, useNavigate } from "react-router-dom";
import { FirstHeader } from "../../../components/sharedComponents/FirstHeader";
import { ButtonCustom } from "../../../components/sharedComponents/ButtonCustom";
import { CallAPI } from "../../../config/api/ApiConfig";
import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { BsArrowLeft } from "react-icons/bs";
import { useIntl } from "react-intl";

export const SignUpStepTwo = ({
  setSignUpSteps,
  signUpSteps,
  register,
  errors,
  control,
  setValue,
  clearErrors,
  getValues,
  goBack,
}) => {
  const navigate = useNavigate();
  const { messages, locale } = useIntl();

  const { data: tools } = CallAPI({
    name: "toolsList",
    url: "/tools",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) =>
      res?.data?.data?.map((d) => ({
        id: d?.id,
        label: d?.name,
        // label: locale === "ar" ? d?.name_ar : d?.name_en,
      })),
    // onError: (err) => toast.error(err?.response?.data),
  });

  const { isLoading: experienceLoad, data: experiences } = CallAPI({
    name: "callExperience",
    url: "/mentor/v1/experiences",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) =>
      res?.data?.data?.map((d) => ({ id: d?.id, label: d?.name })),
    // onError: (err) => toast.error(err?.response?.data),
  });

  const { data: langs } = CallAPI({
    name: "langs",
    url: "/languages",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) =>
      res?.data?.data?.map((d) => ({
        id: d?.id,
        label: d?.name,
        // label: locale === "ar" ? d?.name_ar : d?.name_en,
      })),
    // onError: (err) => toast.error(err?.response?.data),
  });

  const { data: industries } = CallAPI({
    name: "callIndustries",
    url: "/industries",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
    // onError: (err) => toast.error(err?.response?.data),
  });

  const { data: categories } = CallAPI({
    name: ["callCategories", getValues("industry_id")],
    url: `/industries/${getValues("industry_id")}/categories`,
    refetchOnWindowFocus: false,
    enabled: getValues("industry_id")?.length ? true : false,
    select: (res) => res?.data?.data,
    // onError: (err) => toast.error(err?.response?.data),
  });

  return (
    <Row className={`border w-100 mx-auto p-4`}>
      <Col>
        <Row className="mb-4">
          <Col xs={12} className="mb-3 d-flex align-items-center px-0">
            <BsArrowLeft className="mx-2 rotate-arrow " onClick={goBack} />
            <FirstHeader
              text="sign up"
              onClick={() => navigate(PATH_PAGE.signUp)}
            />
          </Col>
          <Col xs="auto">
            <p
              className="text-dark-blue"
              style={{ textDecoration: "underline" }}
            >
              {messages["already.have.account"]}{" "}
              <Link to={PATH_PAGE.login}>
                <u className="text-green">{messages["sign in"]}</u>
              </Link>
            </p>
          </Col>
          <Col xs={12}>
            <FirstHeader text="Work Information" className="auth-page-info" />
          </Col>
          <Col>
            <FirstHeader
              className="auth-page-info"
              text="work.info"
              onClick={() => navigate(PATH_PAGE.signUp)}
            />
          </Col>
        </Row>

        <Row className="mb-4">
          <Col md={6}>
            <Row>
              <Col md={12} className="mb-3">
                <div className="mentor-input">
                  <label className="mentor-input__label">
                    {messages["job_title"]}
                  </label>
                  <input
                    className="mentor-input__field"
                    type="text"
                    name="job_title"
                    {...register("job_title", {
                      required: messages["job_title_required"],
                      minLength: {
                        value: 3,
                        message: messages["job_title_min"],
                      },
                      maxLength: {
                        value: 50,
                        message: messages["job_title_max"],
                      },
                    })}
                  />
                  {errors.job_title && (
                    <span className="mentor-input__field-error">
                      {errors.job_title?.message}
                    </span>
                  )}
                </div>
              </Col>
              <Col md={12} className="mb-3">
                <div className="d-flex flex-column position-relative mb-10">
                  <label className="selectInputLabel" htmlFor="industry_id">
                    {messages["industry"]}
                  </label>
                  <select
                    {...register("industry_id", {
                      required: messages["industry_required"],
                    })}
                    control={control}
                    rules={{ required: "Select at least one option" }}
                    name="industry_id"
                    className="sign-select selectInputDefault"
                    onChange={(e) => {
                      setValue("industry_id", e?.target?.value);
                      setValue("category_id", e?.target?.value);
                      clearErrors("industry_id");
                    }}
                    // disabled={!countries?.length || countryLoad}
                  >
                    <option value=""></option>
                    {industries?.map((industry) => (
                      <option key={industry?.id} value={industry?.id}>
                        {industry?.name}
                      </option>
                    ))}
                  </select>
                  {errors.industry_id && (
                    <span className="mentor-input__field-error">
                      {errors.industry_id?.message}
                    </span>
                  )}
                </div>
              </Col>
              <Col md={12} className="mb-3">
                <div className="d-flex flex-column position-relative mb-10">
                  <label className="selectInputLabel" htmlFor="category_id">
                    {messages["specialization"]}
                  </label>
                  <select
                    {...register("category_id", {
                      required: messages["specialization_required"],
                    })}
                    name="category_id"
                    className="sign-select selectInputDefault"
                    onChange={(e) => {
                      setValue("category_id", e?.target?.value);
                      clearErrors("category_id");
                    }}
                  >
                    <option value=""></option>
                    {categories?.map((cat) => (
                      <option key={cat?.id} value={cat?.id}>
                        {cat?.name}
                      </option>
                    ))}
                  </select>
                  {errors.category_id && (
                    <span className="mentor-input__field-error">
                      {errors.category_id?.message}
                    </span>
                  )}
                </div>
              </Col>
              <Col md={12} className="mb-3 position-relative">
                <label className="autoComplete-label">
                  {messages["languages"]}
                </label>
                <Controller
                  name="langs"
                  control={control}
                  rules={{ required: messages["languages_required"] }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      className="autoComplete"
                      disablePortal
                      disabled={false}
                      multiple
                      id="langs"
                      options={langs || []}
                      //   getoptionselected={(option, value) =>
                      //     option?.label === value?.label
                      //   }
                      onChange={(_, selectedOptions) => {
                        field.onChange(selectedOptions);
                      }}
                      renderTags={(tagValue, getTagProps) => {
                        return (
                          <Box className="dataChips">
                            {tagValue.map((option, index) => (
                              <Chip
                                key={option?.id}
                                {...getTagProps({ index })}
                                label={option.label}
                              />
                            ))}
                          </Box>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                    />
                  )}
                />
                {errors.langs && (
                  <span className="mentor-input__field-error-multiple">
                    {errors.langs?.message}
                  </span>
                )}
              </Col>
            </Row>
          </Col>

          <Col md={6}>
            <Row>
              <Col md={12} className="mb-3 position-relative">
                <label className="autoComplete-label">
                  {messages["experiences"]}
                </label>
                <Controller
                  name="experiences"
                  control={control}
                  rules={{ required: messages["experiences.required"] }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      className="autoComplete"
                      disablePortal
                      disabled={false}
                      multiple
                      id="experiences"
                      options={experiences || []}
                      //   getoptionselected={(option, value) =>
                      //     option?.label === value?.label
                      //   }
                      onChange={(_, selectedOptions) => {
                        field.onChange(selectedOptions);
                      }}
                      renderTags={(tagValue, getTagProps) => {
                        return (
                          <Box className="dataChips">
                            {tagValue.map((option, index) => (
                              <Chip
                                key={option?.id}
                                {...getTagProps({ index })}
                                label={option.label}
                              />
                            ))}
                          </Box>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                    />
                  )}
                />
                {errors.experiences && (
                  <span className="mentor-input__field-error-multiple">
                    {errors.experiences?.message}
                  </span>
                )}
              </Col>
              <Col md={12} className="mb-3">
                <div className="mentor-input">
                  <label className="mentor-input__label">
                    {messages["company_name"]}
                  </label>
                  <input
                    className="mentor-input__field"
                    type="text"
                    name="company_name"
                    {...register("company_name", {
                      minLength: {
                        value: 3,
                        message: messages["company_name_min"],
                      },
                      maxLength: {
                        value: 10,
                        message: messages["company_name_max"],
                      },
                    })}
                  />
                  {errors.company_name && (
                    <span className="mentor-input__field-error">
                      {errors.company_name?.message}
                    </span>
                  )}
                </div>
              </Col>
              <Col md={12} className="mb-3">
                <div className="mentor-input">
                  <label className="mentor-input__label">
                    {messages["linkedin"]}
                  </label>
                  <input
                    className="mentor-input__field"
                    type="text"
                    name="linkedIn"
                    {...register("linkedIn", {
                      pattern: {
                        value:
                          /^(https:\/\/)?(www\.)?linkedin\.com\/in\/[A-Za-z0-9_-]+\/?$/,
                        message: messages["linkedin_validate"],
                      },
                    })}
                    // need  validation
                  />
                  {errors.linkedIn && (
                    <span className="mentor-input__field-error">
                      {errors.linkedIn?.message}
                    </span>
                  )}
                </div>
              </Col>
              <Col md={12} className="mb-3 position-relative">
                <label className="autoComplete-label">
                  {messages["tools"]}
                </label>
                <Controller
                  name="tools"
                  control={control}
                  //   rules={{ required: "Tools should be selected" }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      className="autoComplete"
                      disablePortal
                      disabled={false}
                      multiple
                      id="tools"
                      options={tools || []}
                      //   getoptionselected={(option, value) =>
                      //     option?.label === value?.label
                      //   }
                      onChange={(_, selectedOptions) => {
                        field.onChange(selectedOptions);
                      }}
                      renderTags={(tagValue, getTagProps) => {
                        return (
                          <Box className="dataChips">
                            {tagValue.map((option, index) => (
                              <Chip
                                key={option?.id}
                                {...getTagProps({ index })}
                                label={option.label}
                              />
                            ))}
                          </Box>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                    />
                  )}
                />
                {errors.tools && (
                  <span className="mentor-input__field-error-multiple">
                    {errors.tools?.message}
                  </span>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col xs="auto">
            <ButtonCustom
              className="sm-btn"
              text="continue"
              type="submit"
              //   onClick={() => {
              //     setSignUpSteps(signUpSteps + 1);
              //   }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
