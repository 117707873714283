import React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { FormattedMessage, useIntl } from "react-intl";
import { CallAPI } from "../../../config/api/ApiConfig";
import { useNavigate } from "react-router-dom";
import { PATH_PAGE } from "../../../routes/Paths";
import { Else, If, Then } from "react-if";

const Subscrip = () => {
  const navigate = useNavigate();
  const { messages, locale } = useIntl();
  const [show, setShow] = useState(false);
  const [renewId, setRenewId] = useState(null);
  const [serviceId, setServiceId] = useState(null);

  const { data, isLoading } = CallAPI({
    name: "getSubscriptions",
    url: "/client/v1/subscriptions",
    refetchOnWindowFocus: false,
    select: (res) => res?.data?.data,
    // onSuccess: (res) => {
    //   console.log(res);
    // },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { refetch } = CallAPI({
    name: "renewSubscription",
    url: `/client/v1/subscriptions/${renewId}/resubscribe`,
    refetchOnWindowFocus: false,
    enabled: false,
    method: "post",
    body: {
      service_id: serviceId,
      payment_type: "wallet",
    },
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      setRenewId(null);
      setServiceId(null);
      toast.success(messages["send_request_successfully"]);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
      setRenewId(null);
      setServiceId(null);
    },
  });

  const renderButton = (data) => {
    const buttonClass = {
      active: "btn btn-1",
      expired: "btn btn-2",
      drained: "btn btn-3",
    };

    const className = buttonClass[data?.status_in_english] || "btn btn-1";

    return (
      <button className={className}>
        {locale === "ar" ? data?.status : data?.status_in_english}
      </button>
    );
  };

  const navigateMentorDetails = (param) => {
    const queryParams = { id: param };
    const searchParams = new URLSearchParams(queryParams).toString();
    navigate(`${PATH_PAGE.MentorDetails}?${searchParams}`);
  };

  const bookService = (param, pack) => {
    const queryParams = { id: param };
    const searchParams = new URLSearchParams(queryParams).toString();
    if (pack) {
      navigate(`${PATH_PAGE.booking}?${searchParams}&disable="time"`);
    } else {
      navigate(`${PATH_PAGE.booking}?${searchParams}`);
    }
  };

  return (
    <div className="subscrip_wrapper">
      <div className="container-fluid">
        <div className="head_box">
          <h2 className="heading_dash mb-1">{messages["subcriptions"]}</h2>
          <p className="heading_dis">{messages["subcriptions.info"]}</p>
        </div>
        {data?.map((sub) => (
          <div key={sub?.id} className="My_sub rounded-4 p-3 border  mb-3">
            <div className="row">
              <div className="col-lg-4">
                <div className="sub_left">
                  <p>#{sub?.id}</p>
                  <If
                    condition={
                      sub?.service?.service_type == "private_sessions_package"
                    }
                  >
                    <Then>
                      <h2
                      // onClick={() => bookService(sub?.service?.id, "package")}
                      >
                        {sub?.service?.title}
                      </h2>
                    </Then>
                    <Else>
                      <Then>
                        <h2 onClick={() => bookService(sub?.service?.id)}>
                          {sub?.service?.title}
                        </h2>
                      </Then>
                    </Else>
                  </If>

                  <h3 onClick={() => navigateMentorDetails(sub?.mentor?.id)}>
                    {sub?.mentor?.photo ? (
                      <img
                        src={sub?.mentor?.photo}
                        className="avtar"
                        alt="mentor"
                      />
                    ) : (
                      <div className="avatar"></div>
                    )}
                    {sub?.mentor?.first_name} {sub?.mentor?.last_name}
                  </h3>

                  <h4>{sub?.service?.description}</h4>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="sub_center d-md-flex justify-content-between">
                  <div>
                    <p>{messages["sessions"]}</p>
                    <h2>{sub?.package_session_number}</h2>
                    <span>
                      {sub?.package_session_number - sub?.number_of_use}{" "}
                      {messages["remaining"]}
                    </span>
                  </div>
                  <div>
                    <p>{messages["duration"]}</p>
                    <h2>
                      {`${sub?.package_validity} ${
                        sub?.package_validity < 12
                          ? messages["months"]
                          : messages["year"]
                      }`}
                    </h2>

                    <span></span>
                  </div>
                  <div>
                    <p>{messages["price"]}</p>
                    <h2>
                      <FormattedMessage
                        id="wallet.balance.num"
                        values={{
                          num: sub?.price,
                        }}
                      />
                    </h2>
                    <span></span>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="sub_right">
                  {renderButton(sub)}
                  <button
                    className="btn btn-def"
                    onClick={() => {
                      if (
                        sub?.status_in_english === "expired" ||
                        sub?.status_in_english === "drained"
                      ) {
                        setServiceId(sub?.service?.id);
                        setRenewId(sub?.id);
                        setShow(true);
                      } else {
                        navigate(
                          `${PATH_PAGE.booking}?id=${sub?.service?.id}&type='subscription'`
                        );
                      }
                    }}
                  >
                    {sub?.status_in_english === "expired" ||
                    sub?.status_in_english === "drained"
                      ? messages["renew.package"]
                      : messages["make.session"]}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
        {!isLoading && !data?.length && (
          <div className="no-data-found">{messages["no.data.found"]}</div>
        )}
      </div>
      <Modal show={show} onHide={() => setShow(false)} className="sub_modal">
        <Modal.Header closeButton className="border-0">
          <Modal.Title>{messages["renew.package"]}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {/* <div class="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                disabled
              />
              <label className="form-check-label" for="flexRadioDefault1">
                Online
              </label>
            </div> */}
            <div className="d-flex justify-content-between">
              <div class="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked
                />
                <label className="form-check-label" for="flexRadioDefault2">
                  {messages["wallet"]}
                </label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault3"
                  checked
                />
                <label className="form-check-label" for="flexRadioDefault3">
                  {messages["online_payment"]}
                </label>
              </div>

              <div>
                <b>{data && data[0]?.wallet}</b>
                <span>{messages["sar"]}</span>
              </div>
            </div>
          </form>
          <button
            className="btn  btn-default"
            onClick={() => {
              setShow(false);
              refetch();
            }}
          >
            {messages["pay"]}
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Subscrip;
