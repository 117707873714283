import React from "react";
import { WalletHeader } from "../WalletHeader";
import { WalletWithdrawData } from "../Userwithdraw/WalletWithdrawData";
import { PATH_PAGE } from "../../../../routes/Paths";
import { useMatch } from "react-router-dom";
import { DepositeWallet } from "../DepositeWallet/DepositeWallet";
import { CallAPI } from "../../../../config/api/ApiConfig";

export const UserWalletWithdraw = () => {
  const { data: wallet, refetch: callWalletAmount } = CallAPI({
    name: "walletAmountUser",
    url: "/client/v1/wallets-amount",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });
  return (
    <div className="profile mb-2">
      <WalletHeader wallet={wallet} />
      {useMatch(PATH_PAGE.walletWithdrawUser) ? (
        <WalletWithdrawData />
      ) : (
        <DepositeWallet callWalletAmount={callWalletAmount} />
      )}
    </div>
  );
};
