import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CallAPI } from "../../config/api/ApiConfig";
import { useNavigate } from "react-router-dom";
import { PATH_PAGE } from "../../routes/Paths";
import { PaginationBar } from "../../components/sharedComponents/PaginationBar";

export const CategoriesMain = () => {
  const { messages } = useIntl();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsShow = 20;
  const [paginationHelper, setPaginationHelper] = useState({
    totalItems: 1,
    itemsPerPage: itemsShow,
    totalPages: Math.ceil(1 / itemsShow),
  });
  const [showDataHelper, setShowDataHelper] = useState({
    startIndex: (currentPage - 1) * paginationHelper?.itemsPerPage,
    endIndex: Math.min(
      (currentPage - 1) * paginationHelper?.itemsPerPage +
        paginationHelper?.itemsPerPage,
      paginationHelper.totalPages
    ),
  });

  const { data: tableData } = CallAPI({
    name: "allCategories",
    url: "/categories",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      setPaginationHelper({
        totalItems: res?.length,
        itemsPerPage: itemsShow,
        totalPages: Math.ceil(res?.length / itemsShow),
      });
      setShowDataHelper({
        startIndex: (currentPage - 1) * itemsShow,
        endIndex: Math.min(
          (currentPage - 1) * itemsShow + itemsShow,
          res?.length
        ),
      });
    },
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (currentPage) {
      setShowDataHelper({
        startIndex: (currentPage - 1) * itemsShow,
        endIndex: Math.min(
          (currentPage - 1) * itemsShow + itemsShow,
          tableData?.length
        ),
      });
    }
  }, [currentPage]);

  return (
    <div className="container">
      <div className="head_box text-center mb-5">
        <h2 className="FirstHeaderDefault"> {messages["categories"]} </h2>
        <p className="heading_dis">
          {messages["choose_which_category_you_are_interested_in"]}
        </p>
      </div>
      <div className="row">
        {tableData
          ?.slice(showDataHelper?.startIndex, showDataHelper?.endIndex)
          ?.map((category, index) => (
            <div
              className="content col-lg-3 col-md-4"
              onClick={() =>
                navigate(`${PATH_PAGE.CategoriesDetails}?id=${category?.id}`)
              }
            >
              <div className="inner d-flex align-items-center text-center">
                <img src={category?.image} alt="cate" width={80} />
                <div className="subject">{category?.name}</div>
                <div className="number">{category?.mentor_count}mentor</div>
              </div>
            </div>
          ))}
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center mt-3">
          <PaginationBar
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            totalPages={paginationHelper?.totalPages}
          />
        </div>
      </div>
    </div>
  );
};
