import React, { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ImgDialog from "./ImgDialog";
import getCroppedImg from "./cropImage";
import { styles } from "./styles";
import SVG from "react-inlinesvg";
import { iconPath } from "../../../../constants/constants";

const DemoTest = ({ classes, onCroppedImage, photo, editView }) => {
  const path = iconPath();

  const [selectedImg, setSelectedImg] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [liveOutput, setLiveOutput] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);
        canvas.toBlob((blob) => {
          const pngFile = new File([blob], "uploadedImage.png", {
            type: "image/png",
          });
          setSelectedImg(URL.createObjectURL(pngFile));
          onCroppedImage(pngFile);
        }, "image/png");
      };
      img.src = reader.result;
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    showCroppedImage();
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        selectedImg,
        croppedAreaPixels,
        rotation
      );
      setLiveOutput(croppedImage);

      const response = await fetch(croppedImage);
      const blob = await response.blob();
      const file = new File([blob], "croppedImage.png", { type: "image/png" });

      setCroppedImage(file);
      onCroppedImage(file);
    } catch (error) {
      console.error("Error while cropping image:", error);
    }
  };

  return (
    <div>
      {!editView && (
        <div className="d-flex justify-content-center mb-5">
          <img
            className="profile_img"
            src={photo}
            alt="profile"
            width="35%"
            height="35%"
          />
        </div>
      )}
      {editView && (
        <div className="custom_upload_box">
          <div className={classes.cropContainer}>
            <div className="upload-icon">
              <input type="file" onChange={handleImageUpload} />

              <SVG src={`${path}cam.svg`} />
            </div>
            <Cropper
              image={selectedImg}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
        </div>
      )}

      {selectedImg && editView && (
        <React.Fragment>
          <div className="d-none">
            <div className={classes.resultContainer}>
              <img
                src={selectedImg}
                alt="Selected"
                className={classes.resultImage}
              />
              {liveOutput && (
                <Typography
                  variant="body1"
                  classes={{ root: classes.liveOutput }}
                >
                  Live Output: {liveOutput}
                </Typography>
              )}
            </div>
          </div>
          <div className={classes.controls}>
            <div
              className={classes.sliderContainer}
              style={{ display: selectedImg ? "flex" : "none" }}
            >
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
              >
                Zoom
              </Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                classes={{ root: classes.slider }}
                onChange={(e, zoom) => {
                  setZoom(zoom);
                  showCroppedImage();
                }}
              />
            </div>
            <div
              className={classes.sliderContainer}
              style={{ display: selectedImg ? "flex" : "none" }}
            >
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
              >
                Rotation
              </Typography>
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                classes={{ root: classes.slider }}
                onChange={(_, rotation) => {
                  showCroppedImage();
                  setRotation(rotation);
                }}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default withStyles(styles)(DemoTest);
