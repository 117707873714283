import React, { useContext, useState } from "react";
import { FirstHeader } from "../../../components/sharedComponents/FirstHeader";
import { Col, Row } from "react-bootstrap";
import { PATH_PAGE } from "../../../routes/Paths";
import { Link, useNavigate } from "react-router-dom";

import { MobileLogin } from "../Login/MobileLogin";
import { iconPath } from "../../../constants/constants";
import { ButtonCustom } from "../../../components/sharedComponents/ButtonCustom";
import { CallAPI } from "../../../config/api/ApiConfig";
import { Modals } from "../../../components/sharedComponents/Modals";
import { OtpConfirm } from "../Otpconfirm/OtpConfirm";
import { toast } from "react-toastify";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";
import { useIntl } from "react-intl";
import { LocaleContext } from "../../../config/localization/LanguageProvider";

export const SignUpStepOne = ({
  passwordType,
  setPasswordType,
  confirmPasswordType,
  setConfirmPasswordType,
  setSignUpSteps,
  signUpSteps,
  register,
  errors,
  control,
  getValues,
  setValue,
  clearErrors,
  setUserVerifyOtp,
  userVerifyOtp,
  otp,
  setOtp,
  goBack,
  watch,
}) => {
  const path = iconPath();
  const { messages } = useIntl();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const { locale } = useContext(LocaleContext);
  const [changeOtpHint, setChangeOtpHint] = useState(false);

  const { isLoading: countryLoad, data: countries } = CallAPI({
    name: "callCountries",
    url: "/countries",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      setTimeout(() => {
        setValue("country_id", res?.find((d) => d?.id === 194)?.id?.toString());
      }, 500);
    },
  });

  const { data: regions, isLoading: regionLoad } = CallAPI({
    name: ["callRegions", getValues("country_id")],
    url: `/countries/${getValues("country_id")}/regions`,
    refetchOnWindowFocus: false,
    enabled: getValues("country_id")?.length ? true : false,
    select: (res) => res?.data?.data,
    // onError: (err) => toast.error(err?.response?.data),
  });

  const { data: cities, isLoading: citiesLoad } = CallAPI({
    name: ["callRegions", getValues("region_id")],
    url: `/regions/${getValues("region_id")}/cities`,
    refetchOnWindowFocus: false,
    enabled: getValues("region_id")?.length ? true : false,
    select: (res) => res?.data?.data,
    // onError: (err) => toast.error(err?.response?.data),
  });

  const {
    refetch: sendOtpToEmail,
    isLoading: sendOtpLoad,
    data: sendOtpEmal,
  } = CallAPI({
    name: "sendOtpToEmail",
    method: "post",
    url: "/send-email",
    refetchOnWindowFocus: false,
    body: {
      email: watch("email"),
    },
    enabled: false,
    select: (res) => res?.data?.data,
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    onSuccess: (red) => {
      setOpenModal(true);
    },
  });

  const { refetch: verifyOtpSent } = CallAPI({
    name: "verifyOtpSent",
    method: "post",
    url: "/verify-code-that-send-to-email",
    refetchOnWindowFocus: false,
    body: {
      email: watch("email"),
      otp: otp,
    },
    enabled: false,
    select: (res) => res?.data,
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    onSuccess: (err) => {
      if (err?.status) {
        setUserVerifyOtp(true);
        setOpenModal(false);
        toast.success(err?.message);
        setChangeOtpHint(true);
      } else {
        toast.error(err?.message);
      }
    },
  });

  return (
    <Row className={`border w-100 mx-auto p-4`}>
      <Col>
        <Row className="mb-4">
          <Col xs={12} className="mb-3 d-flex align-items-center px-0">
            <BsArrowLeft className="mx-2 rotate-arrow " onClick={goBack} />
            <FirstHeader
              text="sign up"
              onClick={() => navigate(PATH_PAGE.signUp)}
            />
          </Col>
          <Col xs="auto">
            <p
              className="text-dark-blue"
              style={{ textDecoration: "underline" }}
            >
              {messages["already.have.account"]}{" "}
              <Link to={PATH_PAGE.login}>
                <u className="text-green">{messages["sign in"]}</u>
              </Link>
            </p>
          </Col>
          <Col xs={12}>
            <FirstHeader
              text="Personal Information"
              className="auth-page-info"
            />
          </Col>
          <Col>
            <FirstHeader
              className="auth-page-info"
              text="personla.info"
              onClick={() => navigate(PATH_PAGE.signUp)}
            />
          </Col>
        </Row>

        <Row className="mb-4">
          <Col md={6}>
            <Row>
              <Col md={12} className="mb-3">
                <div className="mentor-input">
                  <label className="mentor-input__label">
                    {messages["first.name"]}
                  </label>
                  <input
                    className="mentor-input__field"
                    type="text"
                    name="firstName"
                    {...register("first_name", {
                      required: messages["first.name.required"],
                      minLength: {
                        value: 3,
                        message: messages["first.name.min.char"],
                      },
                      maxLength: {
                        value: 10,
                        message: messages["first.name.max.char"],
                      },
                    })}
                  />
                  {errors.first_name && (
                    <span className="mentor-input__field-error">
                      {errors.first_name?.message}
                    </span>
                  )}
                </div>
              </Col>
              <Col md={12} className="mb-3">
                <div className="mentor-input">
                  <label className="mentor-input__label">
                    {messages["last.name"]}
                  </label>
                  <input
                    className="mentor-input__field"
                    type="text"
                    name="firstName"
                    {...register("last_name", {
                      required: messages["last.name.required"],
                      minLength: {
                        value: 3,
                        message: messages["last.name.min.char"],
                      },
                      maxLength: {
                        value: 10,
                        message: messages["last.name.max.char"],
                      },
                    })}
                  />
                  {errors.last_name && (
                    <span className="mentor-input__field-error">
                      {errors.last_name?.message}
                    </span>
                  )}
                </div>{" "}
              </Col>
              <Col md={12} className="mb-3">
                <MobileLogin control={control} />
              </Col>
              <Col md={12} className="mb-3 email-container">
                <div className="mentor-input">
                  <label className="mentor-input__label">
                    {messages["e_mail"]}
                  </label>
                  <input
                    className="mentor-input__field"
                    type="text"
                    name="email"
                    {...register("email", {
                      required: messages["email_required"],
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: messages["email_invalid"],
                      },
                    })}
                    disabled={changeOtpHint}
                  />
                  {errors.email && (
                    <span className="mentor-input__field-error">
                      {errors.email?.message}
                    </span>
                  )}
                </div>{" "}
                <button
                  type="button"
                  disabled={!getValues("email") || errors?.email}
                  onClick={() => {
                    if (changeOtpHint) {
                      setChangeOtpHint(false);
                      setUserVerifyOtp(false);
                    } else {
                      sendOtpToEmail();
                    }
                  }}
                >
                  {changeOtpHint
                    ? messages["change_email"]
                    : messages["send_otp"]}
                </button>
              </Col>
              <Col md={12} className="mb-3">
                <div className="d-flex flex-column position-relative mb-10">
                  <label className="selectInputLabel" htmlFor="country_id">
                    {messages["country"]}
                  </label>
                  <select
                    {...register("country_id", {
                      required: messages["country_required"],
                    })}
                    control={control}
                    name="country_id"
                    className="sign-select selectInputDefault"
                    onChange={(e) => {
                      setValue("country_id", e?.target?.value);
                      setValue("region_id", "");
                      setValue("city_id", "");
                      clearErrors("country_id");
                    }}
                    disabled={!countries?.length || countryLoad}
                  >
                    <option value=""></option>
                    {countries?.map((coun) => (
                      <option key={coun?.id} value={coun?.id}>
                        {locale === "ar" ? coun?.native : coun?.name}
                      </option>
                    ))}
                  </select>
                  {errors.country_id && (
                    <span className="mentor-input__field-error">
                      {errors.country_id?.message}
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          </Col>

          <Col md={6}>
            <Row>
              <Col md={12} className="mb-3">
                <div className="d-flex flex-column position-relative mb-10">
                  <label className="selectInputLabel" htmlFor="region_id">
                    {messages["region"]}
                  </label>
                  <select
                    {...register("region_id", {
                      required: messages["region_required"],
                    })}
                    name="region_id"
                    className="sign-select selectInputDefault"
                    onChange={(e) => {
                      setValue("region_id", e?.target?.value);
                      setValue("city_id", "");
                      clearErrors("region_id");
                    }}
                    disabled={!regions?.length || regionLoad}
                  >
                    <option value=""></option>
                    {regions?.map((reg) => (
                      <option key={reg?.id} value={reg?.id}>
                        {locale === "ar" ? reg?.name_ar : reg?.name}
                      </option>
                    ))}
                  </select>
                  {errors.region_id && (
                    <span className="mentor-input__field-error">
                      {errors.region_id?.message}
                    </span>
                  )}
                </div>
              </Col>
              <Col md={12} className="mb-3">
                <div className="d-flex flex-column position-relative mb-10">
                  <label className="selectInputLabel" htmlFor="city_id">
                    {messages["city"]}
                  </label>
                  <select
                    {...register("city_id", {
                      required: messages["city_required"],
                    })}
                    name="city_id"
                    className="sign-select selectInputDefault"
                    onChange={(e) => {
                      setValue("city_id", e?.target?.value);
                      clearErrors("city_id");
                    }}
                    disabled={!cities?.length || citiesLoad}
                  >
                    <option value=""></option>
                    {cities?.map((cit) => (
                      <option key={cit?.id} value={cit?.id}>
                        {locale === "ar" ? cit?.name_ar : cit?.name}
                      </option>
                    ))}
                  </select>
                  {errors.city_id && (
                    <span className="mentor-input__field-error">
                      {errors.city_id?.message}
                    </span>
                  )}
                </div>
              </Col>
              <Col md={12} className="mb-3 position-relative">
                <div className="mentor-input">
                  <label className="mentor-input__label">
                    {messages["password"]}
                  </label>
                  <input
                    className="mentor-input__field"
                    type={passwordType ? "password" : "text"}
                    name="password"
                    {...register("password", {
                      required: messages["mobile_required"],
                      minLength: {
                        value: 6,
                        message: messages["mobile_min"],
                      },
                    })}
                  />
                  {errors.password && (
                    <span className="mentor-input__field-error">
                      {errors.password?.message}
                    </span>
                  )}
                </div>
                {passwordType ? (
                  <BsEyeSlash
                    className="eye-password"
                    onClick={() => {
                      setPasswordType(!passwordType);
                    }}
                  />
                ) : (
                  <BsEye
                    className="eye-password"
                    onClick={() => {
                      setPasswordType(!passwordType);
                    }}
                  />
                )}
              </Col>
              <Col md={12} className="mb-3 position-relative">
                <div className="mentor-input">
                  <label className="mentor-input__label">
                    {messages["confirm_password"]}
                  </label>
                  <input
                    className="mentor-input__field"
                    type={confirmPasswordType ? "password" : "text"}
                    name="password_confirmation"
                    {...register("password_confirmation", {
                      required: messages["password_match"],
                      validate: (value) =>
                        value === getValues("password") ||
                        messages["password_not_match"],
                    })}
                  />
                  {errors.password_confirmation && (
                    <span className="mentor-input__field-error">
                      {errors.password_confirmation?.message}
                    </span>
                  )}
                </div>

                {confirmPasswordType ? (
                  <BsEyeSlash
                    className="eye-password"
                    onClick={() => {
                      setConfirmPasswordType(!confirmPasswordType);
                    }}
                  />
                ) : (
                  <BsEye
                    className="eye-password"
                    onClick={() => {
                      setConfirmPasswordType(!confirmPasswordType);
                    }}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col xs="auto" className="flex-column d-flex">
            <ButtonCustom
              className="sm-btn"
              text="continue"
              type="submit"
              disabled={!userVerifyOtp}
            />
            {!userVerifyOtp && (
              <small className="pt-1">{messages["need.enter.otp"]}</small>
            )}
          </Col>
        </Row>
      </Col>

      <Modals
        title="otp_confirm"
        body={
          <OtpConfirm
            otp={otp}
            setOtp={setOtp}
            onClickSend={() => sendOtpToEmail()}
          />
        }
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          //   setOtp("");
        }}
        onClickSend={() => otp?.length === 4 && verifyOtpSent()}
      />
    </Row>
  );
};
