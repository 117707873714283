import React, { useRef } from "react";
import SVG from "react-inlinesvg";
import { convertMinutesToHours, iconPath } from "../../../../constants/constants";
import { useIntl } from "react-intl";
import { BsChatDots } from "react-icons/bs";
import { CallAPI } from "../../../../config/api/ApiConfig";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Else, If, Then } from "react-if";
import { PATH_PAGE } from "../../../../routes/Paths";

export const DetailsCard = () => {
  const path = iconPath();
  const inputRef = useRef(null);
  const { messages } = useIntl();
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const { data: booking } = CallAPI({
    name: ["reviewClient", id],
    url: "/mentor/v1/bookings/" + id,
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      navigator.clipboard
        .writeText(inputRef?.current?.value)
        .then(() => {
          toast.success(messages["text_copied"]);
        })
        .catch((error) => {
          toast.error("Unable to copy: ", error);
        });
    }
  };

  const { refetch: addNewMessage } = CallAPI({
    name: "sendMsgFromMentor",
    url: "/mentor/v1/chats",
    method: "post",
    body: {
      message: "Hello",
      booking_id: booking?.id,
      receiver_id: booking?.client?.id,
    },
    enabled: false,
    refetchOnWindowFocus: false,
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      navigate(`${PATH_PAGE?.chat}?id=${res?.id}`);
    },
  });

  const renderTypeOfService = (type, sessionNum) => {
    if (type === "private_session") {
      return messages["services.individual"];
    }
    if (type === "group_session") {
      return messages["group_session"];
    }
    if (type === "private_sessions_package") {
      return `${sessionNum} ${messages["session"]}`;
    }
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <div className="details_card">
          <div className="d-flex justify-content-between align-items-center">
            <p>#{booking?.id}</p>
          </div>
          <h3>{booking?.session?.title}</h3>
          <ul>
            <li>
              <SVG src={`${path}d2.svg`} />
              {booking?.start_session}
            </li>
          </ul>
        </div>
      </div>
      <div className="col-md-6">
        <div className="details_card">
          <h3>{messages["client"]}</h3>
          <div className="d-flex user_details align-items-center mb-3">
            {booking?.employee?.photo ? (
              <img
                src={booking?.employee?.photo}
                className="img"
                alt="profileImage"
              />
            ) : (
              <div className="round"></div>
            )}
            <div>
              <h5>
                {booking?.employee?.first_name} {booking?.employee?.last_name}
                <div
                  className="chat-with"
                  onClick={() => navigate(`/chat/?id=${booking?.chat_id}&username=${booking?.employee.first_name}&employeeId=${booking?.employee.id}`)} // Navigate to ChatBody with ID
                >
                  <BsChatDots />
                </div>
              </h5>
              {booking?.status == "complete" && (
                <span>{messages["attended"]}</span>
              )}
            </div>
          </div>

          <If condition={booking?.status == "scheduled"}>
            <Then>
              <h3>{messages["zoom_link"]}</h3>
              <div className="input-group p-1 zoom_input justify-content-between">
                <button className="btn border-0" onClick={handleCopy}>
                  <span className="input-group-text bg-transparent border-0 p-0">
                    <SVG src={`${path}link-alt.svg`} />
                  </span>
                </button>
                <span>
                  <input
                    className="form-control border-0 bg-transparent"
                    placeholder="Zoom Link"
                    value={booking?.zoom_link}
                    disabled
                    ref={inputRef}
                    hidden
                  />
                  {booking?.zoom_link}
                </span>
                <Link className="btn border-0" target="_blank" to={`${booking?.zoom_link}`}>
                  <span className="input-group-text bg-transparent border-0 p-0">
                    <SVG src={`${path}files.svg`} />
                  </span>
                </Link>
              </div>
            </Then>
          </If>
        </div>
      </div>
    </div>
  );
};
