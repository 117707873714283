import React, { useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TransferDesign } from "./TransferDesign";
import { ActionMenu } from "./ActionMenu";
import axios from "axios";

import { calculateTop } from "../../../constants/constants";
import { useIntl, FormattedMessage } from "react-intl";
import { CallAPI } from "../../../config/api/ApiConfig";
import { useNavigate } from "react-router-dom";
import { PATH_PAGE } from "../../../routes/Paths";
import { Else, If, Then } from "react-if";
import { AuthContext } from "../../../auth/AuthProvider";

export const ReservTable = () => {
  const navigate = useNavigate();
  const { messages, locale } = useIntl();
  const { user } = useContext(AuthContext);
  const { data: tableData, refetch } = CallAPI({
    name: "reviewsTable",
    url: "/mentor/v1/bookings",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const tableHeader = [
    messages["reservation_id"],
    messages["employee_name"],

    messages["reservation_date"],
    messages["booking_statue"],
    messages["attendance_status"],
    messages["chang_attendance_status"],
    messages["actions"],
  ];

  const handleNavigation = (param) => {
    const queryParams = { id: param };
    const searchParams = new URLSearchParams(queryParams).toString();
    navigate(`${PATH_PAGE.reservDetails}?${searchParams}`);
  };

  const handleAttendanceChange = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    try {
      await axios.post(
        "https://admin.uplevel.sa/api/mentor/v1/bookings/change-attend-status",
        {
          booking_id: id,
          attend_status: newStatus,
        },
        {
          headers: {
            "Accept-Language": locale,
            Authorization: user?.token ? `Bearer ${user.token}` : null,
          },
        }
      );
      refetch(); // Refetch data to update the table with the new status
    } catch (error) {
      console.error("Failed to change attendance status:", error);
    }
  };

  return (
    <div className="mt-5 wallet-table">
      <div className="container-fluid">
        <Row>
          <Col xs={12}>
            <TableContainer className="parent-table">
              <Table className="table">
                <TableHead className="table_header">
                  <TableRow className="table_header-row">
                    {tableHeader?.map((d) => (
                      <TableCell
                        key={d}
                        align="center"
                        className="table_header-row_heading"
                      >
                        {d}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.map((data, index) => (
                    <TableRow
                      key={data?.id}
                      className="table_body-row"
                      style={{
                        top: calculateTop(index, tableData?.length),
                      }}
                    >
                      <TableCell align="center" className="table_body-row_data">
                        {data?.id}
                      </TableCell>
                      <TableCell align="center" className="table_body-row_data">
                        {data?.employee?.full_name}
                      </TableCell>
                      <TableCell align="center" className="table_body-row_data">
                        {data?.start_session}
                      </TableCell>
                      <TableCell align="center" className="table_body-row_data">
                        <If condition={data?.status == "complete"}>
                          <Then>
                            <TransferDesign
                              className="green"
                              text={messages["completed"]}
                            />
                          </Then>
                          <Else>
                            <If condition={data?.status == "canceled"}>
                              <Then>
                                <TransferDesign
                                  className="red"
                                  text={messages[data?.status]}
                                />
                              </Then>
                            </If>
                            <Else>
                              <If condition={data?.status == "scheduled"}>
                                <Then>
                                  <TransferDesign
                                    className="orange"
                                    text={messages[data?.status]}
                                  />
                                </Then>
                              </If>
                            </Else>
                          </Else>
                        </If>
                      </TableCell>
                      <TableCell align="center" className="table_body-row_data">
                        <If condition={data?.attend_status == 1}>
                      {messages["attended"]}

                        {/* {messages[data?.status]} */}
                          <Else>
                            <If condition={data?.attend_status == "0"}>
                              {/* {messages[data?.status]} */}
                           {messages["notAttended"]}

                            </If>
                          </Else>
                        </If>
                      </TableCell>
                      <TableCell align="center" className="table_body-row_data">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={data?.attend_status === 1}
                            onChange={() =>
                              handleAttendanceChange(
                                data?.id,
                                data?.attend_status
                              )
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </TableCell>
                      <TableCell align="center" className="table_body-row_data">
                        <ActionMenu
                          onClick={() => handleNavigation(data?.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Col>
        </Row>
      </div>
    </div>
  );
};
