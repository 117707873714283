import React from "react";
import { BsWallet, BsStopwatch, BsCalendar3 } from "react-icons/bs";
import { TbCalendarRepeat } from "react-icons/tb";
import { Else, If, Then } from "react-if";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ButtonCustom } from "../../../components/sharedComponents/ButtonCustom";
import { PATH_PAGE } from "../../../routes/Paths";

export const ServiceCard = ({
  header = "Ui Session",
  info = "infoAboutService",
  price = 120,
  hours = 2,
  service = {},
  setServiceId = () => {},
  packageValidity = null,
  sessionNum = null,
  groupDate = null,
}) => {
  const navigate = useNavigate();
  const { messages } = useIntl();

  const handleNavigation = () => {
    const queryParams = { id: service?.id, type: service?.service };
    const searchParams = new URLSearchParams(queryParams).toString();
    navigate(`${PATH_PAGE.addNewService}?${searchParams}`);
  };

  return (
    <div className="service">
      <div className="service-data">
        <div className="service-data--header">{header}</div>
        <div className="service-data--info">{info}</div>
        <div className="service-data--wallet">
          <BsWallet />{" "}
          <span>
            {price} {messages["SAR"]}
          </span>
          <If condition={service.vat_included}>
            <Then>
              <span className="small">({messages["vat_included"]})</span>
            </Then>
          </If>
        </div>
        <div className="service-data--wallet">
          <BsStopwatch />
          <span>
            {hours} {messages["hours"]}
          </span>
        </div>

        {sessionNum && (
          <div className="service-data--wallet">
            <TbCalendarRepeat />
            <span>
              {sessionNum} {messages["sessions"]}
            </span>
          </div>
        )}

        {packageValidity && (
          <div className="service-data--wallet">
            <BsCalendar3 />
            <span>
              {packageValidity} {messages["month"]}
            </span>
          </div>
        )}

        {groupDate && (
          <div className="service-data--wallet">
            <BsCalendar3 />
            <span>{groupDate}</span>
          </div>
        )}
        <div className="service-data--footer">
          <ButtonCustom
            text="edit_service"
            onClick={() => handleNavigation()}
          />
          <ButtonCustom
            text={
              +service?.is_active === 1
                ? "deactivate_service"
                : "activate_service"
            }
            className={`${+service?.is_active === 0 && "active"}`}
            onClick={() => {
              setServiceId(service?.id);
            }}
          />
        </div>
      </div>
    </div>
  );
};
