import { Link, useLocation } from "react-router-dom";
import { ButtonCustom } from "../../../../components/sharedComponents/ButtonCustom";

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useIntl, FormattedMessage } from "react-intl";
import { CallAPI } from "../../../../config/api/ApiConfig";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

export const Client = () => {
  const { messages } = useIntl();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const { data: review } = CallAPI({
    name: "review",
    url: "/mentor/v1/reviews/" + id,
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      message: "",
    },
  });
 

  return (
    <div className="review_wrapper">
      <div className="container-fluid">
        <div className="head_box">
          <h2 className="heading_dash mb-1">{messages["review_client"]}</h2>
          <p className="heading_dis">{review?.comment}</p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="rev_card border-0">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h4>{review?.name}</h4>
                  <div className="rating">
                    <>
                      {(() => {
                        const mentorReviews = [];
                        for (let i = 0; i < review?.rate; i++) {
                          mentorReviews.push(
                            <FontAwesomeIcon
                              icon={faStar}
                              className="checked"
                            />
                          );
                        }
                        return mentorReviews;
                      })()}
                    </>
                  </div>
                </div>
                <span>{review?.date}</span>
              </div>

              <hr />

              {/* <form onSubmit={handleSubmit(onSubmit)}>
                <div className="position-relative">
                  <label className="textAreaLabelDefault">
                    {messages["message"]}
                  </label>
                  <textarea
                    className="textAreaDefault"
                    name="message"
                    {...register("message", {
                      required: messages["message_required"],
                      minLength: {
                        value: 3,
                        message: `Name at least 3 characters`,
                      },
                    })}
                  ></textarea>
                </div>
                <div className="row mt-3">
                  <div className="col-md-3">
                    <ButtonCustom className="w-100" text="send" type="Save" />
                  </div>
                  <div className="col-md-3">
                    <Link
                      to="/publicProfile/reviews"
                      className="w-100 btn_dark"
                    >
                      {messages["cancel"]}
                    </Link>
                  </div>
                </div>
              </form> */}
            </div>

          </div>

          <div className="row col-md-12 text-center">
              <div className="col-md-5 text-center rev_card border-0">
                <Link to="/publicProfile/reviews" className="w-100 btn_dark">
                  {messages["back_to_reviews"]}
                </Link>
              </div>
            </div>

        </div>
      </div>
    </div>
  );
};
