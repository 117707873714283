import React, { useState, useContext } from 'react';
import axios from "axios";
import { AuthContext } from "../../../../auth/AuthProvider";
import { toast } from "react-toastify";
import { LocaleContext } from "../../../../config/localization/LanguageProvider";
import { useIntl } from "react-intl";

const ChangePasswordForm = () => {
  const { messages } = useIntl();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { user } = useContext(AuthContext);
  const { locale } = useContext(LocaleContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password do not match.");
      return;
    }

    const formData = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/auth/change-password`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
            "Accept-Language": locale,
          },
        }
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while changing the password.");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className='row'>
      <div className='col-md-4'>
        <label className='mentor-input__label'>{messages["currentPassword"]}</label>
        <input
          className='mentor-input__field w-100'
          type="password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
      </div>
      <div className='col-md-4'>
        <label className='mentor-input__label'>{messages["newPassword"]}</label>
        <input
          className='mentor-input__field w-100'
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </div>
      <div className='col-md-4'>
        <label className='mentor-input__label'>{messages["confirmPassword"]}</label>
        <input
          className='mentor-input__field w-100'
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>
      <div className='col-md-4 mt-3'>
        <button type="submit" className='btn buttonCustomDefault'>{messages["update"]}</button>
      </div>
    </form>
  );
};

export default ChangePasswordForm;
