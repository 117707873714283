import React, { useState } from "react";
import { useIntl } from "react-intl";
import { RadialBarChart, RadialBar, Legend, Cell } from "recharts";
import { CallAPI } from "../../config/api/ApiConfig";

const SalesBars = () => {
  const { messages, locale } = useIntl();
  const [potentialHours, setPotentialHours] = useState("");

  const { data, refetch } = CallAPI({
    name: "potentialHours",
    url: "/client/v1/dashboard-goal",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) =>
      Object.entries(res?.data?.data)
        .filter(([key, value]) => key !== "message")
        .map(([key, value]) => ({ name: key, value })),
  });

  const { refetch: addHour } = CallAPI({
    name: "addHours",
    url: "/client/v1/dashboard-goal",
    method: "put",
    body: {
      potential_hours: +potentialHours,
    },
    refetchOnWindowFocus: false,
    enabled: false,
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      setPotentialHours("");
      refetch();
    },
  });

  const COLORS = ["#135895", "#004F58"]; // Define custom colors for the radial bars

  // Calculate the total value
  //   const totalValue = data?.reduce(
  //     (accumulator, currentValue) => accumulator + currentValue.value,
  //     0
  //   );
  const barBackground = "#F0F0F0"; // Set the desired background color for the bars

  const CustomLegend = ({ data }) => (
    <div className="custom-legend">
      {data?.map((entry, index) => (
        <div key={`legend-${index}`} className="legend-item">
          <div className="legend-color">
            <div
              style={{ backgroundColor: COLORS[index % COLORS.length] }}
              className="color-box"
            />
          </div>
          <div className="legend-label">
            {locale === "ar" && <span>{entry.value} :</span>}
            <span>{messages[entry.name]}</span>
            {locale !== "ar" && <span>: {entry.value}</span>}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="chart_sales  align-items-center">
      <div className="rad_cust">
        <label>{messages["potential_hours"]}</label>
        <input
          value={potentialHours}
          onChange={(e) => {
            const isValid = /^\d+$/.test(e.target?.value);
            if (!isValid && e.target?.value) {
              e.preventDefault();
            } else {
              setPotentialHours(e?.target?.value);
            }
          }}
          onKeyPress={(e) => {
            const isValid = /^\d+$/.test(e?.key);
            if (!isValid) {
              e.preventDefault();
            } else {
              setPotentialHours(e?.target?.value);
            }
          }}
          className="form-control"
          type="number"
        />

        <button
          className="btn"
          onClick={() =>
            potentialHours?.length && +potentialHours > 0 && addHour()
          }
        >
          {messages["Save"]}
        </button>
      </div>
      <div className="chart-container">
        <RadialBarChart
          width={500}
          height={300}
          data={data}
          innerRadius={50}
          outerRadius={90}
        >
          <RadialBar
            dataKey="value"
            fill={COLORS[0]}
            background={{ fill: barBackground }} // Set the background color for the bars
            clockWise
            startAngle={90}
            endAngle={-270}
            minAngle={15}
            label={false}
          >
            {data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
            {/* <LabelList
              data={`${data && data[1]?.value}/${totalValue}`}
              position="center"
            /> */}
          </RadialBar>
          {/* <Legend position="center" /> */}
          <Legend content={<CustomLegend data={data} position="center" />} />
        </RadialBarChart>
      </div>
    </div>
  );
};

export default SalesBars;
