import React from "react";
import { ProfileInfo } from "../profile/ProfileInfo";
import { WalletHeader } from "./WalletHeader";
import { WalletTable } from "./WalletTable";

export const Wallet = () => {
  return (
    <div className="profile mb-2">
      <WalletHeader />
      <WalletTable />
    </div>
  );
};
