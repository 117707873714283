import SVG from "react-inlinesvg";

export const RoundedIcon = ({
  src,
  alt = "svg",
  onClick = () => {},
  className = "roundedIcon",
}) => {
  const iconPath = process.env.PUBLIC_URL + "/imgs/";

  return (
    <SVG
      className={className}
      src={`${iconPath}${src}`}
      alt={alt}
      onClick={onClick}
    />
  );
};
