import React from "react";
import { Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ButtonCustom } from "../../../../components/sharedComponents/ButtonCustom";
import { MobileLogin } from "../../../Authentications/Login/MobileLogin";
import { CallAPI } from "../../../../config/api/ApiConfig";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PATH_PAGE } from "../../../../routes/Paths";

export const WithdrawStc = () => {
  const { messages } = useIntl();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      username: "",
      mobile: "",
      transaction_name: "تحويل",
    },
  });

  const { refetch } = CallAPI({
    name: "withDrawalStc",
    url: "/client/v1/wallets/withdrawal-stc",
    refetchOnWindowFocus: false,
    method: "post",
    body: {
      ...watch(),
    },
    enabled: false,
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    onSuccess: (red) => {
      navigate(PATH_PAGE.walletUser);
      toast.success(messages["send_request_successfully"]);
    },
  });

  const onSubmit = (data) => {
    refetch();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container-fluid">
        <Row>
          <Col md={6} className="mb-3">
            <div className="mentor-input">
              <label className="mentor-input__label">
                {messages["wallet.account_holder"]}
              </label>
              <input
                className="mentor-input__field"
                type="text"
                name="username"
                {...register("username", {
                  required: messages["name_required"],
                  minLength: {
                    value: 3,
                    message: messages["name.min.char"],
                  },
                  maxLength: {
                    value: 20,
                    message: messages["name.max.char"],
                  },
                })}
              />
              {errors.username && (
                <span className="mentor-input__field-error">
                  {errors.username?.message}
                </span>
              )}
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <MobileLogin control={control} name="mobile" />
          </Col>
        </Row>

        {/* <Row className="mb-3">
          <Col md={6}>
            <div className="mentor-input">
              <label className="mentor-input__label">
                {messages["wallet.transaction_name"]}
              </label>
              <input
                className="mentor-input__field"
                type="text"
                name="transaction_name"
                {...register("transaction_name", {
                  required: messages["wallet.transaction_name.required"],
                  minLength: {
                    value: 3,
                    message: messages["wallet.transaction_name.min.char"],
                  },
                  maxLength: {
                    value: 20,
                    message: messages["wallet.transaction_name.max.char"],
                  },
                })}
              />
              {errors.transaction_name && (
                <span className="mentor-input__field-error">
                  {errors.transaction_name?.message}
                </span>
              )}
            </div>
          </Col>
        </Row> */}

        <Row>
          <Col xs={4} md={3} className="mt-4">
            <ButtonCustom className="w-100" text="send_request" type="submit" />
          </Col>
        </Row>
      </div>
    </form>
  );
};
