import React, { useRef } from "react";

import SVG from "react-inlinesvg";
import { iconPath } from "../../../../constants/constants";
import { useIntl } from "react-intl";
import { PATH_PAGE } from "../../../../routes/Paths";
import { useNavigate } from "react-router-dom";
import { BsGeoAlt } from "react-icons/bs";
import { BsChatDots } from "react-icons/bs";
import { CallAPI } from "../../../../config/api/ApiConfig";
import { Else, If, Then } from "react-if";

export const DetailsBody = ({ clientReservation, id }) => {
  const path = iconPath();
  const { messages, locale } = useIntl();
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const handleCopy = () => {
    if (inputRef.current) {
      handleNavigation(clientReservation?.id);
      //   inputRef.current.select();
      //   navigator.clipboard
      //     .writeText(inputRef?.current?.value)
      //     .then(() => {
      //       toast.success(messages["text_copied"]);
      //     })
      //     .catch((error) => {
      //       toast.error("Unable to copy: ", error);
      //     });
    }
  };

  const { data: getGapBetweenSession } = CallAPI({
    name: "getGapBetweenSession",
    url: "/setting/gap_between_sessions",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const handleNavigation = (param) => {
    let sessionTime = parseInt(clientReservation?.service?.period);
    let totalTime = sessionTime + parseInt(getGapBetweenSession?.value);
    let timeToClose = totalTime * 60 * 1000;

    const queryParams = { id: param };
    const searchParams = new URLSearchParams(queryParams).toString();
    navigate(`${PATH_PAGE.meeting}?${searchParams}`);
    setTimeout(() => {
      navigate(`${PATH_PAGE.detailsRating}?id=${id}`);
    }, timeToClose);
  };

  const { refetch: addNewMessage } = CallAPI({
    name: "sendFirstMag",
    url: "/mentor/v1/chats",
    method: "post",
    body: {
      message: "Hello",
      booking_id: clientReservation?.id,
      receiver_id: clientReservation?.mentor?.user_id,
    },
    enabled: false,
    refetchOnWindowFocus: false,
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      navigate(`${PATH_PAGE?.chatUser}?id=${res?.id}`);
    },
  });

  // var currentdate = new Date();
  // var hours = currentdate.getHours();
  // if (hours > 12) {
  //   hours -= 12;
  // } else if (hours === 0) {
  //   hours = 12;
  // }
  // var currentDateTime =
  //   currentdate.getFullYear() +
  //   "-" +
  //   (currentdate.getMonth() + 1) +
  //   "-" +
  //   currentdate.getDate() +
  //   "  " +
  //   hours +
  //   ":" +
  //   currentdate.getMinutes();

  // console.log(currentDateTime);

  function getCurrentDateTime(date) {
    var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var time = hours + ":" + minutes;
    return time;
  }

  var currentDateTime = getCurrentDateTime(new Date());
  console.log(currentDateTime);
  console.log(clientReservation?.meeting_start_at);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <div className="details_left details_card">
            <div className="d-flex justify-content-between">
              <p> #{clientReservation?.id} </p>
              <span>{messages[clientReservation?.service?.service]}</span>
            </div>
            <h3>{clientReservation?.service?.name}</h3>
            <ul>
              <li>
                <SVG src={`${path}b1.svg`} />
                {clientReservation?.mentor?.category?.name}
              </li>
              <li>
                <SVG src={`${path}b2.svg`} />
                {messages["valid_untill"]} {clientReservation?.date}
              </li>
              <h3>
                {clientReservation?.service?.price}{" "}
                <small> {messages["sar"]} </small>
              </h3>
            </ul>
          </div>
        </div>

        <If
          condition={
            clientReservation?.booking_status == "scheduled" &&
            clientReservation?.zoom_link
          }
        >
          <Then>
            <div className="col-md-6">
              <div className="details_right details_card">
                <h3>{messages["zoom_link"]}</h3>
                <div className="input-group p-1 zoom_input">
                  <If
                    condition={
                      clientReservation?.meeting_start_at <= currentDateTime
                    }
                  >
                    <Then>
                      <button className="btn border-0">
                        <button
                          onClick={() =>
                            handleNavigation(clientReservation?.id)
                          }
                          className="open-link"
                        >
                          <span className="input-group-text bg-transparent border-0 p-0">
                            {/* <SVG src={`${path}link-alt.svg`} /> */}
                            <SVG src={`${path}files.svg`} />
                          </span>
                        </button>
                      </button>
                      <input
                        className="form-control border-0 bg-transparent"
                        placeholder="Google Link"
                        value={clientReservation?.zoom_link}
                        disabled
                        ref={inputRef}
                      />
                      <button
                        className="btn border-0"
                        onClick={() => handleNavigation(clientReservation?.id)}
                      >
                        <span className="input-group-text bg-transparent border-0 p-0">
                          {/* <SVG src={`${path}files.svg`} /> */}
                          {messages["zoom.link"]}
                        </span>
                      </button>
                    </Then>
                    <Else>
                      <p>{messages["meeting_will_start_before_5_min"]}</p>
                    </Else>
                  </If>
                </div>
              </div>
            </div>
          </Then>
        </If>

        <div className="col-md-12">
          <div className="details_full details_card">
            <div className="d-md-flex justify-content-between align-items-center">
              <div className="d-flex">
                <div>
                  <img
                    src={clientReservation?.mentor?.photo}
                    className="avtar"
                    alt="profileImage"
                  />
                  {/* <SVG src={`${path}avatar.svg`} className="avtar" /> */}
                </div>
                <div>
                  <h3>
                    {" "}
                    {clientReservation?.mentor?.first_name}{" "}
                    {clientReservation?.mentor?.last_name}{" "}
                    <div className="chat-with" onClick={() => addNewMessage()}>
                      <BsChatDots />
                    </div>
                  </h3>
                  <ul>
                    <li> {clientReservation?.mentor?.job_title} </li>
                    <li>
                      <BsGeoAlt /> {clientReservation?.mentor?.country?.name},{" "}
                      {clientReservation?.mentor?.city?.name}.{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <button
                  className="btn_link px-5 py-3"
                  onClick={() =>
                    navigate(
                      `${PATH_PAGE.MentorDetails}?id=${clientReservation?.mentor?.id}`
                    )
                  }
                >
                  {messages["view_profile"]}
                </button>
              </div>
            </div>
            <p className="mb-3">{clientReservation?.mentor?.bio}</p>
            <div className="info-mentor">
              {clientReservation?.mentor?.industry && (
                <span className="bg-transparent mx-1">
                  {clientReservation?.mentor?.industry?.name}
                </span>
              )}

              {clientReservation?.mentor?.tools.map((tool) => (
                <span className="bg-transparent mx-1" key={tool?.id}>
                  {locale === "ar" ? tool?.name_ar + " " : tool?.name_en + " "}
                </span>
              ))}

              {clientReservation?.mentor?.experiences?.map((data, index) => (
                <span className="bg-transparent mx-1" key={data?.id}>
                  {data?.name}
                </span>
              ))}

              {clientReservation?.mentor?.langs?.map((data, index) => (
                <span className="bg-transparent mx-1" key={data?.id}>
                  {locale === "ar" ? data?.name_ar + " " : data?.name_en + " "}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
