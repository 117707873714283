import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../auth/AuthProvider";
import { PATH_PAGE } from "./Paths";

const isAuthenticated = (User) => User?.token || false;

export const PrivateRouteMentor = ({ path, element }) => {
  const { user } = useContext(AuthContext);
  return isAuthenticated(user) && user?.data?.type === "mentor" ? (
    <Outlet />
  ) : (
    <Navigate to={PATH_PAGE.login} />
  );
};

export const PrivateRouteClient = ({ path, element }) => {
  const { user } = useContext(AuthContext);
  return isAuthenticated(user) && user?.data?.type === "client" ? (
    <Outlet />
  ) : (
    <Navigate to={PATH_PAGE.UserLogin} />
  );
};

export const AuthPublicRoutes = ({ path, element }) => {
  const { user } = useContext(AuthContext);

  return !isAuthenticated(user) ? (
    <Outlet />
  ) : user?.data?.type === "mentor" ? (
    <Navigate to={PATH_PAGE.dashboard} />
  ) : (
    <Navigate to={PATH_PAGE.clHome} />
  );
};
