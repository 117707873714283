import axios from "axios";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../auth/AuthProvider";
import { ButtonCustom } from "../../../../components/sharedComponents/ButtonCustom";
import { PATH_PAGE } from "../../../../routes/Paths";
import { CallAPI } from "../../../../config/api/ApiConfig";
import { AxiosContext } from "../../../../config/api/AxiosConfig";
import { LocaleContext } from "../../../../config/localization/LanguageProvider";

import {
  dayTimeInMinutes,
  getMinutesFromTime,
} from "../../../../constants/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

const newTimeData = [
  {
    checked: true,
    day: "saturday",
    slots: [{ startTime: "00:00", endTime: "00:05" }],
  },
  {
    checked: false,
    day: "sunday",
    slots: [{ startTime: "00:00", endTime: "00:05" }],
  },
  {
    checked: false,
    day: "monday",
    slots: [{ startTime: "00:00", endTime: "00:05" }],
  },
  {
    checked: false,
    day: "tuesday",
    slots: [{ startTime: "00:00", endTime: "00:05" }],
  },
  {
    checked: false,
    day: "wednesday",
    slots: [{ startTime: "00:00", endTime: "00:05" }],
  },
  {
    checked: false,
    day: "thursday",
    slots: [{ startTime: "00:00", endTime: "00:05" }],
  },
  {
    checked: false,
    day: "friday",
    slots: [{ startTime: "00:00", endTime: "00:05" }],
  },
];

let daysOfWeek = [
  "saturday",
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
];

export const RegularTime = () => {
  const { messages } = useIntl();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { setLoader } = useContext(AxiosContext);
  const [days, setDays] = React.useState(newTimeData);

  const timeData = [
    { checked: false, day: "saturday", startTime: "00:00", endTime: "00:05" },
    { checked: false, day: "sunday", startTime: "00:00", endTime: "00:05" },
    { checked: false, day: "monday", startTime: "00:00", endTime: "00:05" },
    { checked: false, day: "tuesday", startTime: "00:00", endTime: "00:05" },
    { checked: false, day: "wednesday", startTime: "00:00", endTime: "00:05" },
    { checked: false, day: "thursday", startTime: "00:00", endTime: "00:05" },
    { checked: false, day: "friday", startTime: "00:00", endTime: "00:05" },
  ];

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      data: newTimeData,
    },
  });

  const handleCheckboxChange = (index) => {
    const newdata = watch("data").map((day, i) => {
      if (i === index) {
        clearErrors(`startTime[${index}]`);
        clearErrors(`endTime[${index}]`);
        return {
          ...day,
          startTime: day.startTime,
          endTime: day.endTime,
          checked: !day.checked,
        };
      } else {
        return day;
      }
    });
    setValue("data", newdata);
  };

  //   {
  //     "day": "saturday",
  //     "total": 2,
  //     "mentor_id": 55,
  //     "slots": [
  //         "05:00 - 06:00",
  //         "06:00 - 07:00"
  //     ]
  // }
  CallAPI({
    name: "getDataTime",
    url: "/mentor/v1/time_slots",
    refetchOnWindowFocus: false,
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      console.log(res);
      let existingDaysMap = {};
      res.forEach((day) => {
        existingDaysMap[day.day] = day.slots.map((slot) => {
          const [startTime, endTime] = slot.split(" - ");
          return { startTime, endTime };
        });
      });

      let result = daysOfWeek.map((day) => {
        return {
          checked: !!existingDaysMap[day],
          day: day,
          slots: existingDaysMap[day] || [
            { startTime: "00:00", endTime: "00:05" },
          ],
        };
      });

      console.log(result);
      setDays(result);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const updateTimeReservation = async () => {
    const formData = new FormData();
    const langs = watch("data")
      ?.filter((d) => d?.checked)
      ?.map((d) => ({
        day: d?.day,
        startTime: d?.checked ? d?.startTime : null,
        endTime: d?.checked ? d?.endTime : null,
      }));
    langs?.forEach((langId, index) => {
      formData.append(
        `available_time[${langId?.day}]`,
        `${langId.startTime}, ${langId.endTime}`
      );
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/mentor/v1/auth/edit-public-profile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );

      if (response) {
        toast.success(response?.data?.message);
        navigate("/publicProfile");
      }
      setLoader(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoader(false);
    }
  };
  const { locale } = useContext(LocaleContext);

  const onSubmit = async (data) => {
    // setLoader(true);
    // updateTimeReservation();

    const slots = days.reduce((result, day) => {
      if (day.checked) {
        result[day.day] = day?.slots?.map((slot) => {
          return `${slot.startTime},${slot.endTime}`;
        });
      }
      return result;
    }, {});

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/mentor/v1/time_slots/update`,
        { slots },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user?.token}`,
            "Accept-Language": locale,


          },
        }
      );

      if (response) {
        toast.success(response?.data?.message);
        navigate("/publicProfile");
      }
      setLoader(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoader(false);
    }
  };

  const removeTimeSlot = (dayIndex, slotIndex) => {
    const arr = [...days];
    console.log(days);

    const time = arr[dayIndex].slots.splice(slotIndex, 1);
    console.log(arr);
    setDays(arr);
  };

  const addTimeSlot = (dayIndex) => {
    const arr = [...days];
    arr[dayIndex].slots.push({ startTime: "00:00", endTime: "00:05" });
    setDays(arr);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 className="heading_dash mb-3">
        <small>{messages["Regular_availability_times"]}</small>
      </h2>
      <div className="days">
        {/* {watch("data")?.map((day, index) => (
          <div key={day?.day} className="days_logic">
            <input
              className="mb-10"
              type="checkbox"
              checked={day?.checked}
              onChange={() => handleCheckboxChange(index)}
            />
            <div className="days_logic--name mb-10">{messages[day?.day]}</div>
            
            <div className="d-flex flex-column position-relative mb-10">
              <select
                {...register(`data[${index}].startTime`, {
                  validate: (value) => {
                    const endTimeValue = watch(`data[${index}].endTime`);
                    if (value && endTimeValue) {
                      const startTimeMinutes = getMinutesFromTime(value);
                      const endTimeMinutes = getMinutesFromTime(endTimeValue);
                      return startTimeMinutes < endTimeMinutes ||
                        endTimeMinutes === 0
                        ? true
                        : messages["time.validate.start"];
                    }
                    return true;
                  },
                })}
                className="sign-select selectInputDefault"
                disabled={!day.checked}
              >
                {dayTimeInMinutes(messages)?.map((cit) => (
                  <option key={cit?.id} value={cit?.id}>
                    {cit?.text}
                  </option>
                ))}
              </select>
              {errors?.data &&
                errors?.data[index] &&
                errors?.data[index]?.startTime && (
                  <span className="mentor-input__field-error">
                    {errors?.data[index]?.startTime?.message}
                  </span>
                )}
            </div>{" "}
            <div className="mb-10">:</div>
            <div className="d-flex flex-column position-relative mb-10">
              <select
                {...register(`data[${index}].endTime`, {
                  validate: (value) => {
                    const startTimeValue = watch(`data[${index}].startTime`);
                    if (value && startTimeValue) {
                      const endTimeMinutes = getMinutesFromTime(value);
                      const startTimeMinutes =
                        getMinutesFromTime(startTimeValue);
                      return endTimeMinutes > startTimeMinutes ||
                        endTimeMinutes === 0
                        ? true
                        : messages["time.validate.end"];
                    }
                    return true;
                  },
                })}
                className="sign-select selectInputDefault"
                disabled={!day.checked}
              >
                {dayTimeInMinutes(messages)?.map((cit) => (
                  <option key={cit?.id} value={cit?.id}>
                    {cit?.text}
                  </option>
                ))}
              </select>
              {errors?.data &&
                errors?.data[index] &&
                errors?.data[index]?.endTime && (
                  <span className="mentor-input__field-error">
                    {errors?.data[index]?.endTime?.message}
                  </span>
                )}
            </div>
          </div>
        ))} */}
        {days?.map((day, index, arr) => (
          <div key={day?.day} className="days_logic align-items-start">
            <input
              className="mb-10"
              type="checkbox"
              checked={day?.checked}
              onChange={() => {
                const arr = [...days];
                arr[index].checked = !arr[index].checked;
                setDays(arr);
              }}
            />
            <div className="days_logic--name mb-10">{messages[day?.day]}</div>

            <div className="w-75">
              {day.slots?.map((slot, i, ar) => (
                <div className="d-flex gap-3 align-items-center">
                  <div className="d-flex flex-column position-relative mb-10">
                    <select
                      className="sign-select selectInputDefault"
                      disabled={!day.checked}
                      value={slot?.startTime}
                      onChange={(e) => {
                        const arr = [...days];
                        arr[index].slots[i].startTime = e.target.value;
                        setDays(arr);
                      }}
                    >
                      {dayTimeInMinutes(messages)?.map((cit) => (
                        <option key={cit?.id} value={cit?.id}>
                          {cit?.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-10">:</div>
                  <div className="d-flex flex-column position-relative mb-10">
                    <select
                      className="sign-select selectInputDefault"
                      disabled={!day.checked}
                      value={slot?.endTime}
                      onChange={(e) => {
                        const arr = [...days];
                        arr[index].slots[i].endTime = e.target.value;
                        setDays(arr);
                      }}
                    >
                      {dayTimeInMinutes(messages)?.map((cit) => (
                        <option key={cit?.id} value={cit?.id}>
                          {cit?.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  {day?.checked && i < ar?.length && i > 0 && (
                    <FontAwesomeIcon
                      icon={faMinus}
                      onClick={() => removeTimeSlot(index, i)}
                    />
                  )}
                  {day?.checked && i === ar?.length - 1 && (
                    <FontAwesomeIcon
                      icon={faPlus}
                      onClick={() => addTimeSlot(index)}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="row mt-3">
        <div className="col-sm-5">
          <ButtonCustom className="w-100" text="Save" type="submit" />
        </div>
        <div className="col-sm-5">
          <ButtonCustom
            className="w-100 btn_dark"
            text="Decline"
            onClick={() => navigate(PATH_PAGE.publicProfile)}
          />
        </div>
      </div>
    </form>
  );
};
