import { Link, useNavigate, useNavigation } from "react-router-dom";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useIntl, FormattedMessage } from "react-intl";
import { CallAPI } from "../../../config/api/ApiConfig";
import { PATH_PAGE } from "../../../routes/Paths";
import { ButtonCustom } from "../../../components/sharedComponents/ButtonCustom";

export const Review = () => {
  const { messages } = useIntl();
  const navigate = useNavigate();

  const { data: reviews } = CallAPI({
    name: "reviews",
    url: "/mentor/v1/reviews",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const handleNavigation = (param) => {
    const queryParams = { id: param };
    const searchParams = new URLSearchParams(queryParams).toString();
    navigate(`${PATH_PAGE.report}?${searchParams}`);
  };

  const handleClientNavigation = (param) => {
    const queryParams = { id: param };
    const searchParams = new URLSearchParams(queryParams).toString();
    navigate(`${PATH_PAGE.client}?${searchParams}`);
  };

  return (
    <div className="review_wrapper">
      <div className="container-fluid">
        <div className="head_box">
          <h2 className="heading_dash mb-1">{messages["Reviews"]}</h2>
          <p className="heading_dis">
            {messages["reviews.follow_up_your_feedback"]}
          </p>
        </div>
        <div className="row">
          {reviews?.map((data, index) => (
            <div className="col-md-4 col-6">
              <div className="rev_card">
                <div className="d-lg-flex justify-content-between align-items-center">
                  <div>
                    <h4>{data?.name}</h4>
                    <div className="rating">
                      <>
                        {(() => {
                          const mentorReviews = [];
                          for (let i = 0; i < data?.rate; i++) {
                            mentorReviews.push(
                              <FontAwesomeIcon
                                icon={faStar}
                                className="checked"
                              />
                            );
                          }
                          return mentorReviews;
                        })()}
                      </>
                    </div>
                  </div>
                  <span>{data?.date}</span>
                </div>
                <p>{data?.comment}</p>

                <div className="btn-container">
                  <ButtonCustom
                    className="red w-50"
                    text="report_review"
                    type="button"
                    onClick={() => handleNavigation(data?.id)}
                  />

                  <ButtonCustom
                    className="green w-50"
                    text="review_client"
                    type="button"
                    onClick={() => handleClientNavigation(data?.id)}
                  />
                </div>

                {/* <Link
                  to={`/publicProfile/reviews/client/${data?.id}`}
                  className="green"
                >
                  {messages["review_client"]}
                </Link> */}
              </div>
            </div>
          ))}
          {reviews?.length === 0 && (
            <div className="col-md-12">
              <div className="no_data">
                <p className="text-center">There is no reviews</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
