import React from "react";
import { Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ButtonCustom } from "../../../../components/sharedComponents/ButtonCustom";
import { CallAPI } from "../../../../config/api/ApiConfig";
import { PATH_PAGE } from "../../../../routes/Paths";

export const DepositeWallet = ({ callWalletAmount }) => {
  const { messages } = useIntl();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      amount: "",
    },
  });

  const { refetch } = CallAPI({
    name: "chargeWallet",
    url: "/client/v1/wallets/charge",
    refetchOnWindowFocus: false,
    method: "post",
    body: {
      amount: +watch("amount"),
    },
    enabled: false,
    onSuccess: (res) => {
      navigate(PATH_PAGE.walletUser);
      toast.success(messages["send_request_successfully"]);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const onSubmit = (data) => {
    refetch();
  };

  return (
    <div className="faq_wrapper wallet_wrapper mt-5 container-fluid">
      <Row>
        <Col xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid">
              <Row>
                <Col md={6} className="mb-3">
                  <div className="mentor-input">
                    <label className="mentor-input__label">
                      {messages["amount"]}
                    </label>
                    <input
                      className="mentor-input__field"
                      type="text"
                      name="amount"
                      {...register("amount", {
                        required: messages["amount.required"],
                        pattern: {
                          value: /^[0-9]+(?:\.[0-9]{1,2})?$/, // Regex pattern for a valid price (e.g., 10 or 10.99)
                          message: messages["amount.not.valid"],
                        },
                      })}
                    />
                    {errors.amount && (
                      <span className="mentor-input__field-error">
                        {errors.amount?.message}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={3} className="mt-4">
                  <ButtonCustom
                    className="w-100"
                    text="send_request"
                    type="submit"
                  />
                </Col>
              </Row>
            </div>
          </form>
        </Col>
      </Row>
    </div>
  );
};
