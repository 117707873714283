import React, { useEffect, useState } from "react";
import { DashboaredHeader } from "../../components/sharedComponents/DashboaredHeader";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";

import { dayDateMonth, iconPath } from "../../constants/constants";
import { CallAPI } from "../../config/api/ApiConfig";
import { toast } from "react-toastify";
import { PaginationBar } from "../../components/sharedComponents/PaginationBar";
import { useIntl } from "react-intl";
import { PATH_PAGE } from "../../routes/Paths";

const Statistics = () => {
  const path = iconPath();
  const { locale, messages } = useIntl();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsShow = 2;
  const [paginationHelper, setPaginationHelper] = useState({
    totalItems: 1,
    itemsPerPage: itemsShow,
    totalPages: Math.ceil(1 / itemsShow),
  });
  const [showDataHelper, setShowDataHelper] = useState({
    startIndex: (currentPage - 1) * paginationHelper?.itemsPerPage,
    endIndex: Math.min(
      (currentPage - 1) * paginationHelper?.itemsPerPage +
        paginationHelper?.itemsPerPage,
      paginationHelper.totalPages
    ),
  });

  const { data } = CallAPI({
    name: "bookings",
    url: "/client/v1/dashboard-bookings",
    refetchOnWindowFocus: false,
    select: (res) => res?.data?.data,
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    onSuccess: (res) => {
      setPaginationHelper({
        totalItems: res?.length,
        itemsPerPage: itemsShow,
        totalPages: Math.ceil(res?.length / itemsShow),
      });
      setShowDataHelper({
        startIndex: (currentPage - 1) * itemsShow,
        endIndex: Math.min(
          (currentPage - 1) * itemsShow + itemsShow,
          res?.length
        ),
      });
      setCurrentPage(1);
    },
  });

  useEffect(() => {
    if (currentPage) {
      setShowDataHelper({
        startIndex: (currentPage - 1) * itemsShow,
        endIndex: Math.min(
          (currentPage - 1) * itemsShow + itemsShow,
          data?.length
        ),
      });
    }
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid mt-lg-5">
      <div className="d-md-flex justify-content-between">
        <DashboaredHeader
          text={messages["upcoming_reservation"]}
          className="heading_dash mb-3"
        />
        <Link to={PATH_PAGE.UserReserv}>
          <DashboaredHeader
            text={messages["view_all"]}
            className="heading_dash mb-3"
          />
        </Link>
      </div>
      <div className="container-static">
        {data
          ?.slice(showDataHelper?.startIndex, showDataHelper?.endIndex)
          ?.map((book) => (
            <div
              key={book?.id}
              className="static_box mb-3 p-3 d-md-flex justify-content-between align-items-center"
            >
              <div className="up_coming">
                <p>#{book?.id}</p>
                <h3>{book?.service_title}</h3>
                {/* <h4>
                {" "}
                <SVG src={`${path}up3.svg`} />
                Tarek mohamed
              </h4> */}
                <ul>
                  <li>
                    {/* <SVG src={`${path}up2.svg`} /> Chemical */}
                    {book?.booking_status}
                  </li>
                  <li>
                    <SVG src={`${path}up1.svg`} />{" "}
                    {dayDateMonth(book?.date, locale)}{" "}
                  </li>
                </ul>
              </div>

              <Link
                to={`${PATH_PAGE.detailsInfo}?id=${book?.id}`}
                className="buttonCustomDefault w-auto btn-mentor d-flex align-items-center  mt-md-0 mt-3"
              >
                {messages["reservations_details"]}
              </Link>
            </div>
          ))}
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-end px-4">
          <PaginationBar
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            totalPages={paginationHelper?.totalPages}
          />
        </div>
      </div>
    </div>
  );
};

export default Statistics;
