import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import OtpInput from "react-otp-input";

export const OtpConfirm = ({
  timer = "00:06",
  otp = "",
  setOtp = () => {},
  onClickSend = () => {},
  numInputs = 4,
  autoTrigger = false,
  callApi = () => {},
}) => {
  const { messages } = useIntl();

  useEffect(() => {
    if (autoTrigger && otp?.length === numInputs) {
      setTimeout(() => {
        callApi();
      }, 500);
    }
  }, [otp]);

  return (
    <div className="otp text-dark-blue">
      <div className="head">{messages["OTP"]}</div>
      <div className="info">{messages["otp_info"]}</div>       
      <OtpInput
        value={otp}
        inputType="tel"
        onChange={setOtp}
        numInputs={numInputs}
        containerStyle="otpContainStyle"
        renderSeparator={<span className="otp-seperator"></span>}
        renderInput={(props) => <input className="otp-inputs" {...props} />}
      />
      {/* <div className="otp-timer">{timer}</div> */}
      <button type="button" className="otp-resend" onClick={onClickSend}>
        {messages["resend_code"]}
      </button>
    </div>
  );
};
