import { Tab } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Row, Col, Tabs } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { CallAPI } from "../../../config/api/ApiConfig";
import { ButtonCustom } from "../../../components/sharedComponents/ButtonCustom";
import { PATH_PAGE } from "../../../routes/Paths";
import { GroupService } from "./groupService/GroupService";
import { IndividualService } from "./individualService/IndividualService";
import { PackageService } from "./packageService/PackageService";
import { toast } from "react-toastify";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { calculateTop } from "../../../constants/constants";
import { Else, If, Then } from "react-if";
import { ActionMenu } from "./ActionMenu";

export const ServicesData = () => {
  const isSmallScreen = useMediaQuery("(max-width:700px)");

  const { messages } = useIntl();
  const navigate = useNavigate();
  const [serviceId, setServiceId] = useState(null);
  const [activeTab, setActiveTab] = useState("private_session");

  const tableHeader = [
    messages["package_name"],
    messages["package_category"],
    messages["package_expiry_date"],
    messages["num_employees"],
    messages["num_sessions"],
    messages["actions"],
  ];
  const { data: tableData, refetch: getServices } = CallAPI({
    name: "serviesData",
    url: "/mentor/v1/packages",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data || [],
  });
  console.log(tableData);

  const { refetch: deActivate } = CallAPI({
    name: "deactivateService",
    url: `/mentor/v1/services/${serviceId}/toggle-status`,
    refetchOnWindowFocus: false,
    enabled: false,
    method: "post",
    select: (res) => res?.data,
    onSuccess: (res) => {
      getServices();
      toast.success(res?.message);
      setServiceId(null);
    },
    onError: (err) => {
      setServiceId(null);
    },
  });

  const handleNavigation = (param) => {
    const queryParams = { id: param };
    const searchParams = new URLSearchParams(queryParams).toString();
    navigate(`${PATH_PAGE.servicesDetails}?${searchParams}`);
  };

  useEffect(() => {
    if (serviceId) {
      deActivate();
    }
  }, [serviceId, deActivate]);

  return (
    <div className="faq_wrapper mt-5 services_wrapper container-fluid">
      <Row className="services_wrapper--row">
        {/* <Col xs={2} style={{ display: isSmallScreen ? "none" : "block" }}></Col>
        <Col xs={12} md={7}>
          <Tabs
            defaultActiveKey={activeTab}
            id="service-example"
            fill
            className="mb-3"
            onSelect={(tab) => setActiveTab(tab)}
          >
            <Tab
              eventKey="private_session"
              title={messages["services.individual"]}
            >
              <IndividualService
                data={services?.filter((d) => d?.service === "private_session")}
                setServiceId={setServiceId}
              />
            </Tab>
            <Tab eventKey="group_session" title={messages["services.group"]}>
              <GroupService
                data={services?.filter((d) => d?.service === "group_session")}
                setServiceId={setServiceId}
              />
            </Tab>
            <Tab
              eventKey="private_sessions_package"
              title={messages["services.package"]}
            >
              <PackageService
                data={services?.filter(
                  (d) => d?.service === "private_sessions_package"
                )}
                setServiceId={setServiceId}
              />
            </Tab>
          </Tabs>
        </Col>
        <Col
          xs={12}
          md={3}
          style={{ justifyContent: isSmallScreen ? "center" : "flex-end" }}
          className="services_wrapper--row_btn"
        >
          <ButtonCustom
            text="Services.add.new"
            className="w-75 h-75"
            onClick={() =>
              navigate(`${PATH_PAGE.addNewService}?type=${activeTab}`)
            }
          />
        </Col> */}
        <Col xs={12}>
          <TableContainer className="parent-table">
            <Table className="table">
              <TableHead className="table_header">
                <TableRow className="table_header-row">
                  {tableHeader?.map((d) => (
                    <TableCell
                      key={d}
                      align="center"
                      className="table_header-row_heading"
                    >
                      {d}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(tableData) &&
                  tableData?.map((data, index) => (
                    <TableRow
                      key={data?.id}
                      className="table_body-row"
                      style={{
                        top: calculateTop(index, tableData?.length),
                      }}
                    >
                      <TableCell align="center" className="table_body-row_data">
                        {data?.package?.name || "-"}
                      </TableCell>

                      <TableCell align="center" className="table_body-row_data">
                        {data?.package?.category?.name || "-"}
                      </TableCell>

                      <TableCell align="center" className="table_body-row_data">
                        {data?.package?.expiry_date || "-"}
                      </TableCell>
                      <TableCell align="center" className="table_body-row_data">
                        {data?.package?.number_of_employees || "0"}
                      </TableCell>
                      <TableCell align="center" className="table_body-row_data">
                        {data?.package?.number_of_sessions || "0"}
                      </TableCell>

                      <TableCell align="center" className="table_body-row_data">
                        <ActionMenu
                          onClick={() => handleNavigation(data?.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
      </Row>
    </div>
  );
};
