import React, { useContext, useEffect, useState } from "react";
import { ChatList } from "./ChatList";
import { ChatBody } from "./ChatBody";
import { CallAPI } from "../../../config/api/ApiConfig";
import { toast } from "react-toastify";
import axios from "axios";
import { AxiosContext } from "../../../config/api/AxiosConfig";
import { AuthContext } from "../../../auth/AuthProvider";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import Pusher from "pusher-js";

export const ChatRoom = () => {
  const { messages } = useIntl();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [message, setMessage] = useState("");
  const [pdf, setPdf] = useState(null);
  const [receiverId, setReceiverId] = useState(null);
  const chatId = searchParams.has("id") ? searchParams.get("id") : null;
  const employeeId = searchParams.get("employeeId");

  const { user } = useContext(AuthContext);
  const { setLoader } = useContext(AxiosContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [pusher, setPusher] = useState(null);
  const [chats, setChats] = useState([]);

  useEffect(() => {
    const pusherInstance = new Pusher("87639c06bf9f7a1e1934", {
      cluster: "eu",
    });
    setPusher(pusherInstance);

    return () => {
      if (pusherInstance) {
        pusherInstance.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    console.log(pusher)
    if (!pusher) return;

    const channel = pusher.subscribe("chat-channel");

    const handleChatEvent = (data) => {
      console.log(data,'data from socet')
      setChats((prevChats) =>
        prevChats.map((ch) => {
          if (
            ch.chat_id === data.chat.chat_id &&
            ch.receiver.id !== data.chat.sender.id
          ) {
            return {
              ...ch,
              count_un_read_message: ch.count_un_read_message + 1,
            };
          }
          return ch;
        })
      );
    };

    channel.bind("ChatEvent", handleChatEvent);

    return () => {
      channel.unbind("ChatEvent", handleChatEvent);
      pusher.unsubscribe("chat-channel");
    };
  }, [pusher]);

  const { data: chatData, error: chatError } = CallAPI({
    name: "getAllChats",
    url: "/mentor/v1/chats",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  useEffect(() => {
    if (chatError) {
      console.error("Error fetching chats:", chatError);
    } else if (chatData) {
      setChats(chatData || []);
    }
  }, [chatData, chatError]);

  useEffect(() => {
    if (chatId !== null && chatId !== "null") {
      setSelectedMessageId(chatId);
    } else if (employeeId) {
      setReceiverId(employeeId);
    }
  }, [chatId, employeeId]);

  const { data: specificChat, refetch: getSpecificChat } = CallAPI({
    name: ["getSpecificChat", selectedMessageId],
    url: `/mentor/v1/chats/${selectedMessageId}`,
    refetchOnWindowFocus: false,
    enabled: !!selectedMessageId,
    select: (res) => res?.data?.data,
    onSuccess: async (res) => {
      if (res) {
        setChats((prevChats) =>
          prevChats.map((ch) => {
            if (selectedMessageId === ch.chat_id) {
              return {
                ...ch,
                count_un_read_message: 0,
              };
            }
            return ch;
          })
        );
  
        const reciverDataTemp = res
          ?.filter(
            (message) =>
              message?.receiver?.email === user?.data?.email ||
              message?.sender?.email === user?.data?.email
          )
          ?.map((message) => {
            const isUserSender = message?.sender?.email === user?.data?.email;
            return {
              user: isUserSender ? message?.receiver : message?.sender,
              message: message?.message,
              file: message?.file,
              created_at: message?.created_at,
            };
          });
  
        // Set receiver ID ensuring it's not the current user
        setReceiverId(
          reciverDataTemp
            ?.filter((which) => which?.user?.email !== user?.data?.email)[0]
            ?.user?.id
        );
      }
    },
  });
  
  const addNewMessage = async () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("message", message);
    formData.append("receiver_id", receiverId);

    if (pdf) {
      formData.append("file", pdf);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/mentor/v1/chats`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );

      if (response?.data?.status) {
        setChats((prevChats) =>
          prevChats.map((ch) => {
            if (selectedMessageId === response.data.data.chat_id) {
              return {
                ...ch,
                count_un_read_message: 0,
              };
            }
            return ch;
          })
        );
        setMessage("");
        setPdf(null);
        
        if (searchParams.has("id") && chatId == "null") {
          navigate("/chat"); // Navigate to '/chat' route
          window.location.reload(); // Refresh the page
        }
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  const handleFileSelection = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setPdf(selectedFile);
    }
  };

  return (
    <div className="container-fluid chat_wrapper">
      <div className="head_box">
        <h2 className="heading_dash mb-1">{messages["messages"]}</h2>
        <p className="heading_dis">{messages["messages_chat_info"]}</p>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="cust_scroll">
            <ChatList
              chats={chats}
              setSelectedMessageId={setSelectedMessageId}
              selectedMessageId={selectedMessageId}
            />
          </div>
        </div>
        <div className="col-md-8">
          <ChatBody
            specificChat={specificChat}
            selectedMessageId={selectedMessageId}
            handleFileSelection={handleFileSelection}
            pdf={pdf}
            addNewMessage={addNewMessage}
            setMessage={setMessage}
            message={message}
          />
        </div>
      </div>
    </div>
  );
};
