import React from "react";
import { useIntl } from "react-intl";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { dayWithTime, yearMonthDay } from "../../../../constants/constants";
import DateTimePickerValue from "../../../../components/sharedComponents/DateTimePickerValue";
import dayjs from "dayjs";
import { CallAPI } from "../../../../config/api/ApiConfig";
import { toast } from "react-toastify";
import { Else, If, Then } from "react-if";
import { TransferDesign } from "../TransferDesign";
import { ButtonCustom } from "../../../../components/sharedComponents/ButtonCustom";

import { useForm } from "react-hook-form";

export const DetailsHeading = ({
  clientReservation,
  id,
  callDetails,
  setValue,
  clearErrors,
}) => {
  const { messages, locale } = useIntl();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const rateValues = [1, 2, 3, 4, 5];

  const [showReserve, setShowReserve] = useState(false);
  const handleCloseReserve = () => setShowReserve(false);
  const handleShowReserve = () => setShowReserve(true);
  const [showRescudle, setShowRescudle] = useState(false);
  const handleCloseRescudle = () => setShowRescudle(false);
  const handleShowRescudle = () => setShowRescudle(true);
  const [cancelReason, setCancelReason] = useState("");
  const [reasonId, setReasonId] = useState("");
  const [reschudleReason, setRescudleReason] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  const [timeValue, setTimeValue] = useState(null);
  const [timeSelected, setTimeSelected] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      rate: "",
      comment: "",
      booking_id: id,
      reason_id: null,
    },
  });

  const { refetch: cancelReserve } = CallAPI({
    name: "cancelReserve",
    url: `/client/v1/bookings/${id}/update-booking-status`,
    refetchOnWindowFocus: false,
    enabled: false,
    method: "put",
    body: {
      booking_status: "canceled",
      // cancellation_reason: cancelReason,
      reason_id: watch("reason_id"),
    },
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      handleCloseReserve();
      callDetails();
    },
    onError: (err) => toast.error(err?.response?.data?.message),
  });

  /* -------------------------------------------------------------------------- */
  /*                              reschudle booking                             */
  /* -------------------------------------------------------------------------- */
  const { refetch: rescheduleRefetch } = CallAPI({
    name: "reschudle",
    url: `/client/v1/bookings/${id}/reschedule_date`,
    refetchOnWindowFocus: false,
    enabled: false,
    method: "put",
    body: {
      reschedule_date: `${yearMonthDay(selectedDate)} ${timeValue}`,
      reschedule_reason: reschudleReason,
    },
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      handleCloseRescudle();
      callDetails();
    },
    onError: (err) => toast.error(err?.response?.data?.message),
  });

  /* -------------------------------------------------------------------------- */
  /*                    available time mentor depend on date                    */
  /* -------------------------------------------------------------------------- */
  const { isLoading } = CallAPI({
    name: ["timeSlotsforReschudle", selectedDate],
    url: `/client/v1/available-time/${
      clientReservation?.service?.id
    }?day=${yearMonthDay(selectedDate)}`,
    refetchOnWindowFocus: false,
    enabled: clientReservation ? true : false,
    onSuccess: (res) => {
      setTimeValue(null);
      setTimeSelected(null);
      setTimeSlots(res);
    },
    select: (res) => res?.data?.time_slots,
    onError: (err) => {
      setTimeSlots([]);
      setTimeValue(null);
      setTimeSelected(null);
      if (showRescudle) {
        toast.error(err?.response?.data?.data);
      }
    },
  });

  const { refetch:rateReserv } = CallAPI({
    name: "rateMyBooking",
    url: "/client/v1/reviews",
    refetchOnWindowFocus: false,
    method: "post",
    body: {
      rate: watch("rate"),
      booking_id: id,
      comment: watch("comment"),
    },
    enabled: false,
    onSuccess: (res) => {
      toast.success(messages["send_rate_successfully"]);
      handleClose();
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { data: cancellationReasons } = CallAPI({
    name: "cancellationReasons",
    url: "/cancellation_reasons",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const onSubmit = (data) => {
    // refetch();
    handleClose();
  };

  return (
    <div className="container-fluid mb-4">
      <div className="row align-items-center">
        <div className="col-md-6">
          <h2 className="rating_head">
            {messages["reservations_details"]}{" "}
            <If condition={clientReservation?.booking_status == "completed"}>
              <Then>
                <TransferDesign
                  className="green mx-2"
                  text={messages[clientReservation?.booking_status]}
                />
              </Then>
              <Else>
                <If condition={clientReservation?.booking_status == "canceled"}>
                  <Then>
                    <TransferDesign
                      className="red mx-2"
                      text={messages[clientReservation?.booking_status]}
                    />
                  </Then>
                </If>
                <Else>
                  <If
                    condition={clientReservation?.booking_status == "scheduled"}
                  >
                    <Then>
                      <TransferDesign
                        className="orange mx-2"
                        text={messages[clientReservation?.booking_status]}
                      />
                    </Then>
                  </If>
                </Else>
              </Else>
            </If>
            {/* {clientReservation?.booking_status === "canceled" ? (
              <span className="endStatus">{messages["canceled"]}</span>
            ) : (
              <span>{messages["scheduled"]}</span>
            )} */}
          </h2>
        </div>
        <div className="col-md-6 d-md-flex justify-content-end align-items-center">
          <div>
            <If condition={clientReservation?.booking_status == "scheduled"}>
              <Then>
                <button
                  className="btn outLine_btn_red"
                  variant="primary"
                  onClick={handleShowReserve}
                >
                  {messages["cancel_reservation"]}
                </button>
                <button
                  className="btn  w_unset buttonCustomDefault"
                  variant="primary"
                  onClick={handleShowRescudle}
                >
                  {messages["reschedule"]}
                </button>
              </Then>
              <Else>
                <If
                  condition={clientReservation?.booking_status == "completed"}
                >
                  <Then>
                    <button
                      className="btn  buttonCustomDefault"
                      variant="primary"
                      onClick={handleShow}
                    >
                      {messages["rate"]}
                    </button>
                  </Then>
                </If>
              </Else>
            </If>
          </div>
        </div>
      </div>

      <Modal className="reserv_modal" show={show} onHide={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className="border-0 ">
            <Modal.Title className="cancelTitle">
              {messages["rate"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="row">
            <div className="col-md-12">
              <div className="position-relative">
                <label className="textAreaLabelDefault">
                  * {messages["select_rate"]}
                </label>

                <select
                  className="form-select bg-transparent border-0"
                  //   className="sign-select selectInputDefault"
                  aria-label="Select Rate"
                  {...register("rate", {
                    required: messages["rate_required"],
                  })}
                  name="rate"
                >
                  <option value="">{messages["choose"]}</option>
                  {rateValues?.map((rateValue, index) => (
                    <option value={rateValue}>{rateValue}</option>
                  ))}
                </select>
                {errors.payment_type && (
                  <span className="mentor-input__field-error">
                    {messages["choose_payment_method"]}
                  </span>
                )}
              </div>
            </div>

            <div className="col-12">
              <div className="position-relative">
                <textarea
                  className="textAreaDefault"
                  name="cancellation_reason"
                  placeholder={messages["leave_comment"]}
                  {...register("comment", {
                    required: messages["comment_required"],
                    minLength: {
                      value: 3,
                      message: [messages["comment_at_lease_3char"]],
                    },
                  })}
                ></textarea>
                {errors.comment && (
                  <span className="mentor-input__field-error-text-box">
                    {errors.comment?.message}
                  </span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button
              type="button"
              className="btn  w-100 buttonCustomDefault"
              variant="primary"
              // disabled={reasonId}
              onClick={() => rateReserv()}
            >
              {messages["send"]}
            </button>
            {/* <ButtonCustom className="w-100" text="send" type="submit" /> */}

            {/* <button
              className="btn  w-100 buttonCustomDefault"
              variant="primary"
            >
              {messages["send"]}
            </button> */}
          </Modal.Footer>
        </form>
      </Modal>

      {/* cancel modal */}
      <Modal
        className="reserv_modal"
        show={showReserve}
        onHide={handleCloseReserve}
      >
        <form>
          <Modal.Header closeButton className="border-0 ">
            <Modal.Title className="cancelTitle">
              {messages["cancel_reservation"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="row">
            <div className="col-6">
              <h4>{messages["session.with"]}</h4>
              <h2>
                {clientReservation?.mentor?.first_name}{" "}
                {clientReservation?.mentor?.last_name}
              </h2>
              <h4>{messages["session.code"]}</h4>

              <h3>{clientReservation?.meet_code}</h3>
            </div>
            <div className="col-6">
              <h4>{messages["session_date"]}</h4>
              <h3>{dayWithTime(clientReservation?.created_at, locale)}</h3>
            </div>
            <div className="col-12">
              <select
                className="form-select bg-transparent border-0"
                //   className="sign-select selectInputDefault"
                aria-label="Select Cancellatio Reason"
                {...register("reason_id", {
                  required: messages["cancellation_reason_required"],
                })}
                name="reason_id"
              >
                <option value="">{messages["choose"]}</option>
                {cancellationReasons?.map((cancellationReason, index) => (
                  <option value={cancellationReason?.id}>
                    {cancellationReason?.reason}
                  </option>
                ))}
              </select>

              {/* <select
                name="reason_id"
                {...register("reason_id", {
                  required: messages["cancellation_reason_required"],
                })}
                className="sign-select selectInputDefault"
                // onChange={(e) => {
                //   setValue("reason_id", e?.target?.value);
                //   clearErrors("reason_id");
                // }}
              >
                <option value=""></option>
                {cancellationReasons?.map((cancellationReason) => (
                  <option
                    key={cancellationReason?.id}
                    // value={cancellationReason?.id}
                    value={reasonId}
                    onChange={(e) => setReasonId(e?.target?.value)}
                  >
                    {cancellationReason?.reason}
                  </option>
                ))}
              </select> */}
              {errors.reason_id && (
                <span className="mentor-input__field-error">
                  {errors.reason_id?.message}
                </span>
              )}

              {/* <textarea
                className="textAreaDefault"
                name="cancellation_reason"
                placeholder={messages["cancel.reason"]}
                value={cancelReason}
                onChange={(e) => setCancelReason(e?.target?.value)}
              ></textarea> */}
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button
              type="button"
              className="btn  w-100 buttonCustomDefault"
              variant="primary"
              disabled={reasonId}
              onClick={() => cancelReserve()}
            >
              {messages["send"]}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {/* reservation modal */}
      <Modal
        className="reserv_modal"
        show={showRescudle}
        onHide={handleCloseRescudle}
      >
        <form>
          <Modal.Header closeButton className="border-0 ">
            <Modal.Title className="cancelTitle">
              {messages["reschedule"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="row">
            <div className="col-6">
              <h4>{messages["session.with"]}</h4>
              <h2>
                {clientReservation?.mentor?.first_name}{" "}
                {clientReservation?.mentor?.last_name}
              </h2>
              <h4>{messages["session.code"]}</h4>

              <h3>{clientReservation?.meet_code}</h3>
              <h4 className="mt-4">{messages["reschudle.date"]}</h4>
            </div>
            <div className="col-6">
              <h4>{messages["session_date"]}</h4>
              <h3>{dayWithTime(clientReservation?.created_at, locale)}</h3>
            </div>
            <div className="col-12 mb-3">
              <div className="align-items-center  gap-2">
                <div className="date-picker mb-3">
                  <DateTimePickerValue
                    dateValue={selectedDate}
                    setDateValue={setSelectedDate}
                  />
                </div>
                <h4>{messages["session_time"]}</h4>

                {!isLoading &&
                  (timeSlots?.length ? (
                    <div className="scroller">
                      {timeSlots?.map((timeSlot, index) => (
                        <div
                          className={`${"time"} ${
                            timeSlot?.start_time === timeSelected && "selected"
                          }`}
                          key={timeSlot?.start_time + index}
                          onClick={() => {
                            setTimeSelected(timeSlot?.start_time);
                            const [hours, minutes] = timeSlot?.start_time
                              .split(":")
                              .slice(0, 2);
                            const formattedTime = `${hours}:${minutes}`;
                            setTimeValue(formattedTime);
                          }}
                        >
                          {timeSlot?.start_time} - {timeSlot?.end_time}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <b>{messages["mentor.has.no.time"]}</b>
                  ))}
              </div>
            </div>
            <div className="col-12">
              <textarea
                className="textAreaDefault"
                name="cancellation_reason"
                placeholder={messages["reschudle.reason"]}
                value={reschudleReason}
                onChange={(e) => setRescudleReason(e?.target?.value)}
              ></textarea>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button
              className="btn  w-100 buttonCustomDefault"
              variant="primary"
              disabled={reschudleReason?.length < 3 || !timeValue}
              type="button"
              onClick={() => rescheduleRefetch()}
            >
              {messages["send"]}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};
