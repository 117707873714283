import { PATH_PAGE } from "../../routes/Paths";
import { initialStructure } from "../../constants/constants";
import { ChatRoom } from "../../pages/userDashboard/chatUser/ChatRoom";

import { UserrDashboard } from "../../pages/userDashboard/UserrDashboard";

import { UserReserv } from "../../pages/userDashboard/UserReserv/UserReserv";

import { CategoriesMain } from "../../pages/categories/CategoriesMain";
import { CategoriesDetails } from "../../pages/categories/CategoriesDetails/CategoriesDetails";
import { MentorDetails } from "../../pages/categories/MentorDetails/MentorDetails";

import { TermsConidiotns } from "../../pages/UsertermsConditions/TermsConidiotns";
import { UserPrivacyPolicy } from "../../pages/UserPrivacyPolicy/UserPrivacyPolicy";
import { UserFaq } from "../../pages/UserFaq/UserFaq";
import { UserContact } from "../../pages/UserContact/UserContact";
import { UserAbout } from "../../pages/UserAbout/UserAbout";

import UserLogin from "../../pages/UserAuth/UserLogin/UserLogin";
import { UserSignUp } from "../../pages/UserAuth/UserSignUp/UserSignUp";
import { UserResetPassword } from "../../pages/UserAuth/UserResetPassword/UserResetPassword";
import { UserProfile } from "../../pages/userDashboard/Userprofile/UserProfile";
import { UserProfileEdit } from "../../pages/userDashboard/Userprofile/UserprofileEdit/UserProfileEdit";
// import UserProfileEdit from '../../pages/userDashboard/Userprofile/UserprofileEdit/UserProfileEdit';
import { UserWallet } from "../../pages/userDashboard/Userwallet/UserWallet";

import { UserWalletWithdraw } from "../../pages/userDashboard/Userwallet/Userwithdraw/UserWalletWithdraw";
import Favourites from "../../pages/userDashboard/favourites/Favourites";
import BookingUser from "../../pages/userDashboard/booking/BookingUser";
import { Meeting } from "../../pages/userDashboard/booking/Meeting";
import Subscrip from "../../pages/userDashboard/subscrip/Subscrip";
import { UserDetails } from "../../pages/userDashboard/UserReserv/detailsRating/UserDetails";
import { UserDetailsInfo } from "../../pages/userDashboard/UserReserv/detailsInfo/UserDetailsInfo";

import { NotificationSettings } from "../../pages/dashboard/NotificationSettings/NotificationSettings";
import { MentorsAll } from "../../pages/MentorsAll/MentorsAll";

export const clientStructure = [
  {
    ...initialStructure,
    path: PATH_PAGE.clDemo,
    name: "Home",
    element: <h1>DEMO</h1>,
    isPrivate: true,
    hasFooter: true,
    hasNav: true,
  },
  {
    path: PATH_PAGE.Userwallet,
    name: "Userwallet",
    element: <UserWallet />,
    isPrivate: true,
    hasFooter: false,
    hasNav: true,
  },
  {
    path: PATH_PAGE.Userwithdraw,
    name: "Userwithdraw",
    element: <UserWalletWithdraw />,
    isPrivate: true,
    hasFooter: false,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.categories,
    name: "categories",
    element: <CategoriesMain />,
    isPrivate: false,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.CategoriesDetails,
    name: "CategoriesDetails",
    element: <CategoriesDetails />,
    isPrivate: false,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.mentors,
    name: "allMentors",
    element: <MentorsAll />,
    isPrivate: false,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.MentorDetails,
    name: "MentorDetails",
    element: <MentorDetails />,
    isPrivate: false,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.UsertermsConditions,
    name: "UsertermsConditions",
    element: <TermsConidiotns />,
    isPrivate: false,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.UsertermsHome,
    name: "UsertermsConditions",
    element: <TermsConidiotns />,
    isPrivate: false,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.UserPrivacyPolicy,
    name: "UserPrivacyPolicy",
    element: <UserPrivacyPolicy />,
    isPrivate: false,
    hasFooter: true,
    hasNav: true,
  },

  {
    ...initialStructure,
    path: PATH_PAGE.UserAbout,
    name: "UserAbout",
    element: <UserAbout />,
    isPrivate: true,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.UserLogin,
    name: "UserLogin",
    element: <UserLogin />,
    isPrivate: false,
    hasFooter: true,
    hasNav: false,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.UserSignUp,
    name: "UserSignUp",
    element: <UserSignUp />,
    isPrivate: false,
    hasFooter: true,
    hasNav: false,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.UserResetPassword,
    name: "UserResetPassword",
    element: <UserResetPassword />,
    isPrivate: false,
    hasFooter: true,
    hasNav: false,
  },

  {
    path: PATH_PAGE.booking,
    name: "booking",
    element: <BookingUser />,
    isPrivate: true,
    hasFooter: false,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.UserContactUs,
    name: "UserContact",
    element: <UserContact />,
    isPrivate: true,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.UserFaqhome,
    name: "UserFaq",
    element: <UserFaq />,
    isPrivate: false,
    hasFooter: true,
    hasNav: true,
  },
];

// links which appear inside the client dashbaord layout
// note: don't duplicate it any location
export const clientDashboard = [
  {
    ...initialStructure,
    path: PATH_PAGE.userDashboard,
    element: <UserrDashboard />,
    isPrivate: false,
    hasFooter: true,
    hasNav: false,
  },
  {
    path: PATH_PAGE.Userprofile,
    name: "Userprofile",
    element: <UserProfile />,
    isPrivate: true,
    hasFooter: true,
    hasNav: true,
  },
  {
    path: PATH_PAGE.UserprofileEdit,
    name: "UserprofileEdit",
    element: <UserProfileEdit />,
    isPrivate: true,
    hasFooter: false,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.notficationUser,
    name: "settingNotificationUser",
    element: <NotificationSettings />,
    isPrivate: true,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.walletUser,
    name: "walletUser",
    element: <UserWallet />,
    isPrivate: false,
    hasFooter: true,
    hasNav: false,
  },
  {
    path: PATH_PAGE.walletWithdrawUser,
    name: "Userwithdraw",
    element: <UserWalletWithdraw />,
    isPrivate: true,
    hasFooter: false,
    hasNav: true,
  },
  {
    path: PATH_PAGE.walletCharge,
    name: "walletChargeMoney",
    element: <UserWalletWithdraw />,
    isPrivate: true,
    hasFooter: false,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.UserPrivacyPolicyDashboard,
    name: "UserPrivacyPolicyDashboard",
    element: <UserPrivacyPolicy />,
    isPrivate: false,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.UserReserv,
    name: "UserReserv",
    element: <UserReserv />,
    isPrivate: true,
    hasFooter: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.chatUser,
    name: "chatUser",
    element: <ChatRoom />,
    isPrivate: true,
    hasFooter: false,
    hasNav: true,
  },
  {
    path: PATH_PAGE.favourites,
    name: "favourites",
    element: <Favourites />,
    isPrivate: true,
    hasFooter: false,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.detailsInfo,
    name: "detailsInfo",
    element: <UserDetailsInfo />,
    isPrivate: true,
    hasFooter: false,
    hasNav: false,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.meeting,
    name: "meeting",
    element: <Meeting />,
    isPrivate: true,
    hasFooter: false,
    hasNav: false,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.detailsRating,
    name: "detailsRating",
    element: <UserDetails />,
    isPrivate: true,
    hasFooter: false,
    hasNav: false,
  },
  {
    path: PATH_PAGE.subscrip,
    name: "subscrip",
    element: <Subscrip />,
    isPrivate: true,
    hasFooter: false,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.UserFaq,
    name: "UserFaq",
    element: <UserFaq />,
    isPrivate: false,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.UserAboutDashboard,
    name: "UserAbout",
    element: <UserAbout />,
    isPrivate: true,
    hasFooter: true,
    hasNav: true,
  },
  {
    ...initialStructure,
    path: PATH_PAGE.UserContact,
    name: "UserContact",
    element: <UserContact />,
    isPrivate: true,
    hasFooter: true,
    hasNav: true,
  },
];
