import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { PATH_PAGE } from "../../../routes/Paths";
import { Link, useNavigate } from "react-router-dom";
import { FirstHeader } from "../../../components/sharedComponents/FirstHeader";
import { ButtonCustom } from "../../../components/sharedComponents/ButtonCustom";
import { BsArrowLeft, BsPaperclip } from "react-icons/bs";
import { useIntl } from "react-intl";

export const SignUpStepThree = ({
  setSignUpSteps,
  signUpSteps,
  register,
  errors,
  setPdf,
  pdf,
  chekTerms,
  setCheckTerms,
  goBack,
  setValue,
  watch,
}) => {
  const navigate = useNavigate();
  const { messages } = useIntl();

  const handleFileSelection = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setPdf(selectedFile);
    }
  };

  return (
    <Row className={`border w-100 mx-auto p-4`}>
      <Col>
        <Row className="mb-4">
          <Col xs={12} className="mb-3 d-flex align-items-center px-0">
            <BsArrowLeft className="mx-2 rotate-arrow " onClick={goBack} />
            <FirstHeader
              text="sign up"
              onClick={() => navigate(PATH_PAGE.signUp)}
            />
          </Col>
          <Col xs="auto">
            <p
              className="text-dark-blue"
              style={{ textDecoration: "underline" }}
            >
              {messages["already.have.account"]}{" "}
              <Link to={PATH_PAGE.login}>
                <u className="text-green">{messages["sign in"]}</u>
              </Link>
            </p>
          </Col>
          <Col xs={12}>
            <FirstHeader text="resume_info" className="auth-page-info" />
          </Col>
        </Row>

        <Row className="mb-4">
          <Col md={6}>
            <Row>
              <Col md={12} className="mb-3">
                <div className=" position-relative">
                  <label
                    className="textAreaLabelDefault"
                    htmlFor="personal_introduction"
                  >
                    {messages["personla_introduction"]}
                  </label>
                  <textarea
                    className="textAreaDefault"
                    id="personal_introduction"
                    name="personal_introduction"
                    {...register("personal_introduction", {
                      required: messages["personal_introduction_required"],
                      minLength: {
                        value: 10,
                        message: messages["personal_introduction_min"],
                      },
                      maxLength: {
                        value: 100,
                        message: messages["personal_introduction_max"],
                      },
                    })}
                  ></textarea>

                  {errors.personal_introduction && (
                    <span className="mentor-input__field-error-text-box">
                      {errors.personal_introduction?.message}
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          </Col>

          <Col md={6}>
            <Row>
              <Col md={12} className="mb-3">
                <div className="mentor-input">
                  <label className="label-file">
                    {messages["upload_resume"]}
                  </label>
                  <label htmlFor="pdfInput" className="file-input-label">
                    <BsPaperclip className="file-pdf" />
                    {watch("pdf") && Object?.values(watch("pdf"))[0]
                      ? Object.values(watch("pdf"))[0]?.name
                      : messages["upload_resume"]}
                  </label>
                  <input
                    type="file"
                    id="pdfInput"
                    accept=".pdf"
                    className="file-input"
                    onChange={(e) => handleFileSelection(e)}
                    {...register("pdf", {
                      required: messages["upload_resume_required"],
                    })}
                  />{" "}
                  {errors.pdf && (
                    <span className="mentor-input__field-error">
                      {errors.pdf?.message}
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <Form.Check
              className="position-relative"
              type="checkbox"
              id="default-checkbox"
              label={
                <a
                  href={PATH_PAGE.termsConditions}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {messages["terms_conditions"]}
                </a>
              }
              checked={chekTerms}
              onChange={() => setCheckTerms(!chekTerms)}
            />
          </Col>
        </Row>

        <Row className="mb-4">
          <Col xs="auto">
            <ButtonCustom
              className="sm-btn"
              text="sign up"
              type="submit"
              disabled={!chekTerms}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
