import React, { useState } from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toast } from "react-toastify";
import { iconPath } from "../../../constants/constants";
import CalendarComponent from "../../../components/sharedComponents/CalendarComponent";
import { CallAPI } from "../../../config/api/ApiConfig";
import { BsPaperclip } from "react-icons/bs";
import { PATH_PAGE } from "../../../routes/Paths";
import { useIntl } from "react-intl";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

export const PublicProfile = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [show, setShow] = useState(false);
  const { messages, locale } = useIntl();
  const [showExperience, setShowExperience] = useState(true);

  const handleToggle = () => {
    setShow(true);
  };

  const path = iconPath();

  const { data: publicProfile } = CallAPI({
    name: "publicProfile",
    url: "/mentor/v1/auth/public-profile",
    refetchOnWindowFocus: false,
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      setIsChecked(res?.availability);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { refetch: toggleONOff } = CallAPI({
    name: "toggle",
    url: "/mentor/v1/auth/toggle-availability",
    refetchOnWindowFocus: false,
    enabled: false,
    select: (res) => res?.data,
    method: "put",
    onSuccess: (res) => {
      toast.success(res?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const StarRating = (rate) => {
    const fullStars = Math.floor(rate);
    const hasHalfStar = rate % 1 !== 0;

    const stars = Array.from({ length: 5 }, (_, index) => {
      if (index < fullStars) {
        return <FaStar key={index} color="#ffc107" />;
      } else if (hasHalfStar && index === fullStars) {
        return <FaStarHalfAlt key={index} color="#ffc107" />;
      } else {
        return <FaStar key={index} color="#e4e5e9" />;
      }
    });

    return <>{stars}</>;
  };

  return (
    <div className="profile">
      <div className="container-fluid">
        <div className="head_box">
          <h2 className="heading_dash mb-1">{messages["public_profile"]}</h2>
          <p className="heading_dis">{messages["public_profile_info"]}</p>
        </div>
        <div className="row">
          <div className="col-12 mb-3">
            <div className="prof_card ">
              <div className="prof_edit d-flex justify-content-end mb-3">
                <Link
                  to={`${PATH_PAGE.publicProfileEdit}?industry=${publicProfile?.industry?.id}`}
                >
                  <SVG src={`${path}edit.svg`} />
                </Link>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <div className="public_avatar">
                    {publicProfile?.photo && (
                      <img src={publicProfile?.photo} alt="profile-img" />
                    )}
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="public_info">
                    <h3>
                      {publicProfile?.first_name} {publicProfile?.last_name}
                    </h3>
                    <p>{publicProfile?.job_tile}</p>
                    <div className="d-lg-flex">
                      <ul>
                        {publicProfile?.industry && (
                          <li>
                            <SVG src={`${path}l1.svg`} />
                            {publicProfile?.industry?.name}
                          </li>
                        )}
                        {publicProfile?.category && (
                          <li>
                            <SVG src={`${path}l1.svg`} />
                            {publicProfile?.category?.name}
                          </li>
                        )}
                        {publicProfile?.country && (
                          <li>
                            <SVG src={`${path}l2.svg`} />
                            {publicProfile?.country?.name},
                            {publicProfile?.city?.name}
                          </li>
                        )}
                        {publicProfile?.langs.length > 0 && (
                          <li>
                            <SVG src={`${path}l3.svg`} />
                            {publicProfile?.langs
                              ?.map((exp) =>
                                // locale === "ar" ? exp?.name_ar : exp?.name_en
                              exp?.name
                              )
                              ?.join(", ")}
                          </li>
                        )}
                        {publicProfile?.linkedIn && (
                          <li className="text-truncate link">
                            <SVG src={`${path}linkedInUrl.svg`} />
                            {publicProfile?.linkedIn}
                          </li>
                        )}
                        {publicProfile?.company_name && (
                          <li>
                            <SVG src={`${path}company.svg`} />
                            {publicProfile?.company_name}
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="badg_wrapper">
                      <span
                        className={`badg_cust ${
                          // showExperience ? "badg_def" : "badg_outline"
                          showExperience ? "badg_outline" : "badg_def"
                        } `}
                        onClick={() => setShowExperience(true)}
                      >
                        {messages["experiences"]}
                      </span>
                      <span
                        className={`badg_cust ${
                          // !showExperience ? "badg_def" : "badg_outline"
                          !showExperience ? "badg_outline" : "badg_def"
                        } `}
                        onClick={() => setShowExperience(false)}
                      >
                        {messages["tools"]}
                      </span>
                    </div>

                    <div className="public_link row mt-3">
                      {showExperience ? (
                        publicProfile?.experiences?.length ? (
                          publicProfile?.experiences?.map((exp) => (
                            <div className="col-auto" key={exp?.id}>
                              <h4>{exp?.name}</h4>
                            </div>
                          ))
                        ) : (
                          <span>{messages["no.data.found"]}</span>
                        )
                      ) : publicProfile?.tools?.length ? (
                        publicProfile?.tools?.map((exp) => (
                          <div className="col-auto" key={exp?.id}>
                            <h4>{exp?.name}</h4>
                          </div>
                        ))
                      ) : (
                        <span>{messages["no.data.found"]}</span>
                      )}

                      {}
                    </div>
                  </div>
                </div>
                <div className="col-md-3 tog_card">
                  <div className="d-flex mb-3">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleToggle}
                      />
                      <span className="slider round"></span>
                    </label>
                    <span className="cheac_lab">
                      {messages[`${isChecked ? "available" : "unavailable"}`]}
                    </span>
                  </div>
                  {/* <h2>
                    <b>{publicProfile?.session_price}</b>{" "}
                    {messages["sr_session"]}
                  </h2> */}
                  <h2>{StarRating(publicProfile?.rating)}</h2>
                  <Link to={PATH_PAGE.reviews}>
                    {publicProfile?.number_of_rating} {messages["reviews"]}
                  </Link>
                  <Link to="" className="blue">
                    / {publicProfile?.session_number} {messages["sessions"]}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <h2 className="heading_dash mb-3">
              {messages["available_reservations"]}
            </h2>
            <div className="prof_card ">
              <div className="prof_edit d-flex justify-content-end mb-3">
                <Link to="/publicProfile/publicEditReserv">
                  <SVG src={`${path}edit.svg`} />
                </Link>
              </div>
              <CalendarComponent
                tileDisabled={() => true}
                showNavigation={false}
                selectedDate={new Date()}
              />
            </div>
          </div>
          <div className="col-md-7">
            <h2 className="heading_dash mb-3">{messages["resume_info"]}</h2>
            <div className="prof_card ">
              <div className="prof_edit d-flex justify-content-end mb-3">
                <Link to="/publicProfile/publicEditResum">
                  <SVG src={`${path}edit.svg`} />
                </Link>
              </div>
              {/* <h3>resume</h3>
              <div className="input-group p-1">
                <input
                  className="form-control"
                  value={"Tarek mohamed resume.pdf"}
                />
                <button className="btn border-0">
                  <span className="input-group-text bg-transparent border-0 p-0">
                    <SVG src={`${path}down.svg`} />
                  </span>
                </button>

                <button className="btn border-0">
                  <span className="input-group-text bg-transparent border-0 p-0">
                    <SVG src={`${path}trash.svg`} />
                  </span>
                </button>
              </div> */}
              <label className="label-file">{messages["resume"]}</label>
              <label
                htmlFor="pdfInput"
                className="file-input-label"
                style={{ cursor: "default" }}
              >
                <BsPaperclip className="file-pdf" />
                {publicProfile?.resume_file ? (
                  <a
                    href={publicProfile?.resume_file}
                    target="_blank"
                    rel="noopener noreferrer"
                    download={true}
                  >
                    Resume.pdf
                  </a>
                ) : (
                  `${messages["no_resume"]}`
                )}
              </label>
              <input
                type="file"
                id="pdfInput"
                accept=".pdf"
                className="file-input"
                disabled={true}
              />
              <div className="mt-4">
                <h3>{messages["personla_introduction"]}</h3>
                {publicProfile?.personal_introduction}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)} className="unFav_modal">
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body className="text-center">
          <SVG src={`${path}warning.svg`} />
          <h3>{messages["are.you.sure"]}</h3>
          <p>{messages["suspend.account"]}</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="btn btn-cancel"
            onClick={() => {
              setShow(false);
            }}
          >
            {messages["no"]}
          </Button>
          <Button
            className="btn btn-yes"
            onClick={() => {
              toggleONOff();
              setIsChecked(!isChecked);
              setShow(false);
            }}
          >
            {messages["yes"]}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
