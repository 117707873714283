import { useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavbarMentor } from "../components/sharedComponents/NavbarMentor";
import { SideBar } from "../components/structure/SideBar";
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
export const LayoutMentor = ({ collapse = false, setCollapse, children }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const smScreen = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    setCollapse(false);
  }, []);

  return (
    <Container fluid className="mentor-design ">
      <Row className="mentor-design__row-holder">
        {smScreen ? (
          // <Col className="d-none">
          //   <SideBar collapse={collapse} setCollapse={setCollapse} />
          // </Col>
          <div className="btn_mobile_box">
         <button className=" btn btn_mobile " onClick={handleShow}>
         <img src="/imgs/menu.png" alt="logo" className="websit" />

     </button>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <SideBar collapse={collapse} setCollapse={setCollapse} />

        </Offcanvas.Body>
      </Offcanvas>
          </div>
       
        ) : (
          <Col
            xs={collapse ? 1 : 2}
            className="mentor-design__row-holder--sidebar"
          >
            <SideBar collapse={collapse} setCollapse={setCollapse} />
          </Col>
        )}

        <Col>
          <Col xs={12} className="mentor-design__row-holder--content__navbar">
            <NavbarMentor />
          </Col>
          <Col xs={12} className="mentor-design__row-holder--content__pages">
            {children}
          </Col>
        </Col>
      </Row>
    </Container>
  );
};
