import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useIntl, FormattedMessage } from "react-intl";
import { iconPath } from "../../../constants/constants";
import { CallAPI } from "../../../config/api/ApiConfig";

export const WalletHeader = () => {
  const path = iconPath();
  const { messages } = useIntl();
  const { data: wallet } = CallAPI({
    name: "wallet",
    url: "/mentor/v1/wallets-amount",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  return (
    <div className="profile_avatar">
      <div className="container-fluid">
        <Row>
          <Col xs={12}>
            <div className="head_box">
              <h2 className="heading_dash mb-1">{messages["wallet"]}</h2>
              <p className="heading_dis">{messages["wallet.sub.title"]}</p>
            </div>
            {/* <div className="avatar_banner d-block">
              <div className="d-flex h-100 justify-content-between align-items-center px-4 mx-4 text-white overflow-hidden">
                <div>
                  <h4>{messages["wallet.balance"]}</h4>
                  <h4>
                    <FormattedMessage
                      id="wallet.balance.num"
                      values={{
                        num: wallet?.amount,
                      }}
                    />
                  </h4>
                </div>
                <div className="position-relative top-class">
                  <SVG src={`${path}wallet1.svg`} />
                </div>
              </div>
            </div> */}
          </Col>
        </Row>
      </div>
    </div>
  );
};
