import React from "react";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { ButtonCustom } from "../../../../components/sharedComponents/ButtonCustom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { CallAPI } from "../../../../config/api/ApiConfig";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

export const DetailsHeading = ({ clientReservationRate }) => {
  const { messages } = useIntl();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const rateValues = [1, 2, 3, 4, 5];

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  CallAPI({
    name: "finishBooking",
    url: "/mentor/v1/bookings/" + id + "/end-booking",
    refetchOnWindowFocus: false,
    method: "put",
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      rate: "",
      comment: "",
      booking_id: id,
    },
  });

  const { refetch } = CallAPI({
    name: "rateMyBooking",
    url: "/client/v1/reviews",
    refetchOnWindowFocus: false,
    method: "post",
    body: {
      rate: watch("rate"),
      booking_id: id,
      comment: watch("comment"),
    },
    enabled: false,
    onSuccess: (res) => {
      toast.success(messages["send_rate_successfully"]);
      handleClose();
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const onSubmit = (data) => {
    refetch();
    handleClose();
  };

  return (
    <div className="container-fluid mb-4">
      <div className="row align-items-center">
        <div className="col-md-6">
          <h2 className="rating_head">
            {messages["reservations_details"]}{" "}
            <span className="green">{messages["completed"]}</span>
            {/* {clientReservationRate?.booking_status === "canceled" ? (
              <span className="endStatus">{messages["canceled"]}</span>
            ) : (
              <span>{messages["scheduled"]}</span>
            )} */}
          </h2>
        </div>
        <div className="col-md-6 d-md-flex justify-content-end align-items-center">
          <div>
            <button
              className="btn  buttonCustomDefault"
              variant="primary"
              onClick={handleShow}
            >
              {messages["rate"]}
            </button>
          </div>
        </div>
      </div>
      {/* cancel modal */}
      <Modal className="reserv_modal" show={show} onHide={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton className="border-0 ">
            <Modal.Title className="cancelTitle">
              {messages["rate"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="row">
            <div className="col-md-12">
              <div className="position-relative">
                <label className="textAreaLabelDefault">
                  * {messages["select_rate"]}
                </label>

                <select
                  className="form-select bg-transparent border-0"
                  //   className="sign-select selectInputDefault"
                  aria-label="Select Rate"
                  {...register("rate", {
                    required: messages["rate_required"],
                  })}
                  name="rate"
                >
                  <option value="">{messages["choose"]}</option>
                  {rateValues?.map((rateValue, index) => (
                    <option value={rateValue}>{rateValue}</option>
                  ))}
                </select>
                {errors.payment_type && (
                  <span className="mentor-input__field-error">
                    {messages["choose_payment_method"]}
                  </span>
                )}
              </div>
            </div>

            <div className="col-12">
              <div className="position-relative">
                <textarea
                  className="textAreaDefault"
                  name="cancellation_reason"
                  placeholder={messages["leave_comment"]}
                  {...register("comment", {
                    required: messages["comment_required"],
                    minLength: {
                      value: 3,
                      message: [messages["comment_at_lease_3char"]],
                    },
                  })}
                ></textarea>
                {errors.comment && (
                  <span className="mentor-input__field-error-text-box">
                    {errors.comment?.message}
                  </span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <ButtonCustom className="w-100" text="send" type="submit" />

            {/* <button
              className="btn  w-100 buttonCustomDefault"
              variant="primary"
            >
              {messages["send"]}
            </button> */}
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};
