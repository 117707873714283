import { MuiTelInput } from "mui-tel-input";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";

export const MobileLogin = ({
  phoneNum = "",
  setPhoneNumber = () => {},
  control,
  name = "phone",
  disabled = false,
}) => {
  const matchIsValidTel = (value) => {
    const SAPrefix = value?.slice(0, 5);
    if (!value) {
      return messages["mobile.required"];
    }

    // if (
    //   value &&
    //   SAPrefix?.trim() === "+966" &&
    //   value?.slice(5).startsWith("5") &&
    //   value?.slice(5)?.trim()?.length === 11
    // ) {
    //   return true;
    // } else if (value && SAPrefix?.trim() === "+966") {
    //   return messages["mobile.valid_saudi_number"];
    // } else if (value && SAPrefix?.trim() !== "+966") {
    //   return messages["mobile.valid_saudi_number"];
    // } else if (!value) {
    //   return messages["mobile.required"];
    // }
  };
  const { messages } = useIntl();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: matchIsValidTel,
      }}
      render={({ field, fieldState }) => {
        // Log the fieldState

        return (
          <MuiTelInput
            {...field}
            disabled={disabled}
            langOfCountryName="ar"
            label={messages["mobile"]}
            className="phone-input"
            defaultCountry="SA"
            // onlyCountries={["SA"]}
            continents={["AF", "AS"]}
            helperText={
              fieldState?.invalid ? (
                <span className="error-tel">{fieldState?.error?.message}</span>
              ) : (
                ""
              )
            }
            error={fieldState?.invalid}
          />
        );
      }}
    />
  );
};
