import React from "react";
import { ServicesData } from "./ServicesData";
import { ServicesHeader } from "./ServicesHeader";

export const Services = () => {
  return (
    <div className="profile mb-2">
      <ServicesHeader />
      <ServicesData />
    </div>
  );
};
