import React, { useContext } from "react";
import { useState } from "react";
import SVG from "react-inlinesvg";
import { toast } from "react-toastify";
import { iconPath } from "../../../../constants/constants";
import { ButtonCustom } from "../../../../components/sharedComponents/ButtonCustom";
import { CallAPI } from "../../../../config/api/ApiConfig";
import { useForm } from "react-hook-form";
import axios from "axios";
import { AxiosContext } from "../../../../config/api/AxiosConfig";
import { MobileLogin } from "../../../Authentications/Login/MobileLogin";
import { Modals } from "../../../../components/sharedComponents/Modals";
import { OtpConfirm } from "../../../Authentications/Otpconfirm/OtpConfirm";
import { AuthContext } from "../../../../auth/AuthProvider";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { PATH_PAGE } from "../../../../routes/Paths";
import AvatarEditor from "react-avatar-edit";
import DemoTest from "./DemoTest";
import ChangePass from "./ChangePass"
export const ProfileEdit = () => {
  const [croppedImageData, setCroppedImageData] = useState(null);

  const handleCroppedImage = (croppedImage) => {
    setCroppedImageData(croppedImage);
  };
  const { user, setUser } = useContext(AuthContext);
  const { setLoader } = useContext(AxiosContext);
  const { messages } = useIntl();
  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [editView, setEditView] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [userVerifyOtp, setUserVerifyOtp] = useState(true);

  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      country_id: "",
      city_id: "",
      region_id: "",
    },
  });

  const registerUser = async () => {
    const formData = new FormData();
    formData.append("photo", croppedImageData || watch("photo"));

    formData.append("first_name", watch("first_name"));
    formData.append("last_name", watch("last_name"));
    formData.append(
      "mobile",
      watch("mobile")?.slice(5)?.trim().replace(/\s/g, "")
    );
    formData.append("email", watch("email"));
    formData.append("country_id", watch("country_id"));
    formData.append("region_id", watch("region_id"));
    formData.append("city_id", watch("city_id"));
    formData.append("bank_name", watch("bank_name") || "");
    formData.append("account_number", watch("account_number") || "");
    formData.append("iban_number", watch("iban_number"));
    formData.append("code", otp);
    if (selectedImage) {
      formData.append("photo", selectedImage);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/mentor/v1/auth/edit-profile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      if (+response?.status === 200) {
        const oldCredential = JSON.parse(localStorage.getItem("user_data"));
        localStorage.setItem(
          "user_data",
          JSON.stringify({
            data: response?.data?.data,
            token: oldCredential?.token,
          })
        );
        setUser({ data: response?.data?.data, token: oldCredential?.token });
        navigate(PATH_PAGE.profile);
        toast.success("Changed successfully");
      } else {
        toast.error(response?.data?.message);
      }
      setLoader(false);
      setEditView(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoader(false);
    }
  };

  const { data } = CallAPI({
    name: "getProfile",
    url: "/mentor/v1/auth/profile",
    refetchOnWindowFocus: false,
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      console.log(res);

      setValue("first_name", res?.first_name);
      setValue("last_name", res?.last_name);
      setValue("email", res?.email);
      setValue("bank_name", res?.bank_name);
      setValue("account_number", res?.account_number);
      setValue("iban_number", res?.iban_number);
      //   enter mobile  with format for reusable input
      setValue(
        "mobile",
        `+966 ${
          res?.mobile?.slice(0, 2) +
          " " +
          res?.mobile?.slice(2, 3) +
          " " +
          res?.mobile?.slice(3)
        }`
      );
      setValue("country_id", res?.country?.id?.toString());
      setValue("city_id", res?.city?.id?.toString());
      setValue("region_id", res?.region?.id?.toString());
      setPreviewImage(res?.photo);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { isLoading: countryLoad, data: countries } = CallAPI({
    name: ["getAllCountrie", data?.country?.id?.toString()],
    url: "/countries",
    refetchOnWindowFocus: false,
    enabled: data?.country?.id?.toString()?.length ? true : false,
    select: (res) => res?.data?.data,
  });

  const { data: regions, isLoading: regionLoad } = CallAPI({
    name: ["callRegions", getValues("country_id")],
    url: `/countries/${getValues("country_id")}/regions`,
    refetchOnWindowFocus: false,
    enabled: getValues("country_id")?.length ? true : false,
    select: (res) => res?.data?.data,
    onError: (err) => toast.error(err?.response?.data),
  });

  const { data: cities, isLoading: citiesLoad } = CallAPI({
    name: ["callRegions", getValues("region_id")],
    url: `/regions/${getValues("region_id")}/cities`,
    refetchOnWindowFocus: false,
    enabled: getValues("region_id")?.length ? true : false,
    select: (res) => res?.data?.data,
    onError: (err) => toast.error(err?.response?.data),
  });

  const { refetch: sendOtpToEmail } = CallAPI({
    name: "sendTheOtpEmail",
    method: "post",
    url: "/send-email",
    refetchOnWindowFocus: false,
    body: {
      email: getValues("email"),
    },
    enabled: false,
    select: (res) => res?.data?.data,
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    onSuccess: (red) => {
      setOpenModal(true);
    },
  });

  const { refetch: verifyOtpSent } = CallAPI({
    name: "verifyOtpSent",
    method: "post",
    url: "/verify-code-that-send-to-email",
    refetchOnWindowFocus: false,
    body: {
      email: getValues("email"),
      otp: otp,
    },
    enabled: false,
    select: (res) => res?.data,
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    onSuccess: (err) => {
      if (err?.status) {
        setUserVerifyOtp(true);
        setOpenModal(false);
        toast.success(err?.message);
      } else {
        toast.error(err?.message);
      }
    },
  });

  const onSubmit = (data) => {
    if (userVerifyOtp) {
      setLoader(true);
      registerUser();
    }
  };

  return (
    <div>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="profile">
        <div className="container-fluid">
          <div className="head_box">
            <h2 className="heading_dash mb-1">{messages["profile"]}</h2>
            <p className="heading_dis">{messages["profile_sub_title"]}</p>
          </div>
          {/* <div className="image-upload-container">
            <ImageUpload
              setSelectedImage={setSelectedImage}
              setPreviewImage={setPreviewImage}
              previewImage={previewImage}
              data={data}
            />
          </div> */}
          {/* {data?.photo && (
            <div className="d-flex justify-content-center">
              <img
                className="profile_img"
                src={
                  "https://mentorlabs.fudex-tech.net/public/storage/photos/1714651778.jpg"
                }
                alt="profile"
                width="40%"
                height="40%"
              />
            </div>
          )} */}
          <DemoTest
            onCroppedImage={handleCroppedImage}
            photo={data?.photo}
            editView={editView}
          />

          <div className="row">
            <div className="col-md-7 row">
              <div className="col-md-6 mb-3">
                <div className="mentor-input">
                  <label className="mentor-input__label">
                    {messages["first.name"]}
                  </label>
                  <input
                    disabled={!editView}
                    className="mentor-input__field"
                    type="text"
                    name="firstName"
                    {...register("first_name", {
                      required: messages["first.name.required"],
                      minLength: {
                        value: 3,
                        message: messages["first.name.min.char"],
                      },
                      maxLength: {
                        value: 10,
                        message: messages["first.name.max.char"],
                      },
                    })}
                  />
                  {errors.first_name && (
                    <span className="mentor-input__field-error">
                      {errors.first_name?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="mentor-input">
                  <label className="mentor-input__label">
                    {messages["last.name"]}
                  </label>
                  <input
                    disabled={!editView}
                    className="mentor-input__field"
                    type="text"
                    name="firstName"
                    {...register("last_name", {
                      required: messages["last.name.required"],
                      minLength: {
                        value: 3,
                        message: messages["last.name.min.char"],
                      },
                      maxLength: {
                        value: 10,
                        message: messages["last.name.max.char"],
                      },
                    })}
                  />
                  {errors.last_name && (
                    <span className="mentor-input__field-error">
                      {errors.last_name?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="d-flex flex-column position-relative mb-10">
                  <label className="selectInputLabel" htmlFor="country_id">
                    {messages["country"]}
                  </label>
                  <select
                    {...register("country_id", {
                      required: messages["country_required"],
                    })}
                    control={control}
                    value={watch("country_id")}
                    name="country_id"
                    className="sign-select selectInputDefault"
                    onChange={(e) => {
                      setValue("country_id", e?.target?.value?.toString());
                      setValue("region_id", "");
                      setValue("city_id", "");
                      clearErrors("country_id");
                    }}
                    disabled={!countries?.length || countryLoad || !editView}
                  >
                    <option value=""></option>
                    {countries?.map((coun) => (
                      <option
                        key={coun?.id?.toString()}
                        value={coun?.id?.toString()}
                      >
                        {coun?.name}
                      </option>
                    ))}
                  </select>
                  {errors.country_id && (
                    <span className="mentor-input__field-error">
                      {errors.country_id?.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <div className="d-flex flex-column position-relative mb-10">
                  <label className="selectInputLabel" htmlFor="region_id">
                    {messages["region"]}
                  </label>
                  <select
                    {...register("region_id", {
                      required: messages["region_required"],
                    })}
                    name="region_id"
                    value={watch("region_id")}
                    className="sign-select selectInputDefault"
                    onChange={(e) => {
                      setValue("region_id", e?.target?.value);
                      setValue("city_id", "");
                      clearErrors("region_id");
                    }}
                    disabled={!regions?.length || regionLoad || !editView}
                  >
                    <option value=""></option>
                    {regions?.map((reg) => (
                      <option key={reg?.id} value={reg?.id}>
                        {reg?.name}
                      </option>
                    ))}
                  </select>
                  {errors.region_id && (
                    <span className="mentor-input__field-error">
                      {errors.region_id?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <MobileLogin
                  control={control}
                  name="mobile"
                  disabled={!editView}
                />
              </div>
              <div className="col-md-6 mb-3">
                <div className="d-flex flex-column position-relative mb-10">
                  <label className="selectInputLabel" htmlFor="city_id">
                    {messages["city"]}
                  </label>
                  <select
                    {...register("city_id", {
                      required: messages["city_required"],
                    })}
                    name="city_id"
                    value={watch("city_id")}
                    className="sign-select selectInputDefault"
                    onChange={(e) => {
                      setValue("city_id", e?.target?.value);
                      clearErrors("city_id");
                    }}
                    disabled={!cities?.length || citiesLoad || !editView}
                  >
                    <option value=""></option>
                    {cities?.map((cit) => (
                      <option key={cit?.id} value={cit?.id}>
                        {cit?.name}
                      </option>
                    ))}
                  </select>
                  {errors.city_id && (
                    <span className="mentor-input__field-error">
                      {errors.city_id?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="email-container col-md-9 mb-3">
                <div className="mentor-input">
                  <label className="mentor-input__label">
                    {messages["e_mail"]}
                  </label>
                  <input
                    disabled={!editView}
                    className="mentor-input__field"
                    type="text"
                    name="email"
                    {...register("email", {
                      required: messages["email_required"],
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: messages["email_invalid"],
                      },
                    })}
                    onChange={(e) => {
                      if (e?.target?.value !== data?.email) {
                        setUserVerifyOtp(false);
                      } else {
                        setUserVerifyOtp(true);
                      }
                    }}
                  />
                  {errors.email && (
                    <span className="mentor-input__field-error">
                      {errors.email?.message}
                    </span>
                  )}
                </div>{" "}
                {editView && (
                  <button
                    type="button"
                    disabled={!getValues("email") || errors?.email}
                    onClick={() => sendOtpToEmail()}
                  >
                    {messages["send_otp"]}
                  </button>
                )}
              </div>

              <div className="row">
                {editView && (
                  <div className="col-md-4">
                    <ButtonCustom
                      className="w-100"
                      text="save_changes"
                      type="submit"
                    />
                    {!userVerifyOtp && (
                      <small className="pt-1 pb-3">
                        {messages["need.enter.otp"]}
                      </small>
                    )}
                  </div>
                )}
                {!editView && (
                  <div className="col-md-4">
                    <ButtonCustom
                      className="w-100"
                      text="edit"
                      onClick={() => {
                        setEditView(true);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-1 borderLeft"></div>

            <div className="col-md-4 row">
              <div className="col-12">
                <p className="heading_dis">{messages["bank_account_info"]}</p>
              </div>

              <div className="col-md-12 mb-3">
                <div className="mentor-input">
                  <label className="mentor-input__label">
                    {messages["bank_name"]}
                  </label>
                  <input
                    disabled={true}
                    className="mentor-input__field"
                    type="text"
                    name="bank_name"
                    value={data?.bank_name || "empty"}
                  />
                  {errors.bank_name && (
                    <span className="mentor-input__field-error">
                      {errors.bank_name?.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-12 mb-3">
                <div className="mentor-input">
                  <label className="mentor-input__label">
                    {messages["iban_number"]}
                  </label>
                  <input
                    disabled={true}
                    className="mentor-input__field"
                    type="text"
                    name="iban_number"
                    value={data?.iban_number || "empty"}
                  />
                  {errors.iban_number && (
                    <span className="mentor-input__field-error">
                      {errors.iban_number?.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modals
        title="otp_confirm"
        body={
          <OtpConfirm
            otp={otp}
            setOtp={setOtp}
            onClickSend={() => sendOtpToEmail()}
          />
        }
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          //   setOtp("");
        }}
        onClickSend={() => otp?.length === 4 && verifyOtpSent()}
      />
    </form>
  
        <div className="border-top pt-3 mt-3 p-3">
        <ChangePass />
        </div>
    </div>
  );
};
