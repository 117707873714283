import React from "react";
import SVG from "react-inlinesvg";
import { useIntl } from "react-intl";
import { PATH_PAGE } from "../../../routes/Paths";
import { Container, Row, Col } from "react-bootstrap";
import { iconPath } from "../../../constants/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonCustom } from "../../../components/sharedComponents/ButtonCustom";

export const RegisterCompleted = () => {
  const path = iconPath();
  const { messages } = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get("name");
  const classFlex = "justify-content-center align-items-center d-flex";

  return (
    <Container className="mt-5 px-5">
      <Row className={`${classFlex} mb-5 text-center`}>
        <Col md={12} lg={6}>
          <SVG
          className="logo_size"
            src={`${path}logo.svg`}
            onClick={() => navigate(PATH_PAGE.clHome)}
          />{" "}
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={6}>
          <SVG
            src={`${path}complete.svg`}
            onClick={() => navigate(PATH_PAGE.clHome)}
            style={{ maxWidth: "100%" }}
          />{" "}
        </Col>
        <Col
          md={12}
          lg={6}
          className="p-5 align-items-end text-dark-blue d-flex"
        >
          <div className="complete-message">
            <div>
              {messages["thank.you"]}
              {name},
              <br />
              {messages["complete.register"]}
              <span>{messages["mentor.lab"]}</span>
              {messages["review.text"]}
              <br />
              {messages["excited"]}
            </div>
            <ButtonCustom
              text="done"
              onClick={() => navigate(PATH_PAGE.login)}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
