import React, { useContext, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { AuthContext } from "../../../auth/AuthProvider";
import { CallAPI } from "../../../config/api/ApiConfig";
import { iconPath } from "../../../constants/constants";

export const NotificationSettings = () => {
  const { messages } = useIntl();
  const { user } = useContext(AuthContext);
  const [payload, setPayload] = useState(false);
  const path = iconPath();

  const { data } = CallAPI({
    name: "showNotifications",
    url: `/${
      user?.data?.type === "client" ? "client" : "mentor"
    }/v1/notifications`,
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
    onError: (err) => {
      console.log(err);
      toast.error(err?.response?.data?.message);
    },
  });

  const { data: toggle, refetch: callSettings } = CallAPI({
    name: "notificationsToggle",
    url: `/${
      user?.data?.type === "client" ? "client" : "mentor"
    }/v1/notification-settings`,
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { refetch: callUpdate } = CallAPI({
    name: "updateSettings",
    url: "/mentor/v1/notification-settings",
    refetchOnWindowFocus: false,
    enabled: false,
    method: "PUT",
    body: {
      ...payload,
    },
    select: (res) => res?.data,
    onError: (err) => {
      setPayload(false);
      toast.error(err?.response?.data?.message);
    },
    onSuccess: (res) => {
      setPayload(false);
      callSettings();
      toast.success(res?.message);
    },
  });

  const handleToggle = (e) => {
    const key = e?.target?.value;
    setPayload({
      [key]: e?.target?.checked ? 1 : 0,
    });
  };

  useEffect(() => {
    if (payload) {
      callUpdate();
    }
    return () => {
      setPayload(false);
    };
  }, [payload]);

  return (
    <div className="profile">
      <div className="container-fluid">
        <div className="head_box">
          <h2 className="heading_dash mb-1">{messages["notifications"]}</h2>
        </div>

        <div className="row mt-5">
          <div className="col-lg-7 px-5">
            <div className="row">
              <div className="col-12 notify-container">
                {data?.map((notify, index) => (
                  <div key={notify?.created_at + index} className="notify-body">
                    <div className="d-flex align-items-center gap-3">
                      <div>
                        {notify?.sender?.photo ? (
                          <img
                            src={`${path}logo.svg`}
                            className="notify-image"
                            alt="logo-notify"
                          />
                        ) : (
                          <div className="rounded-div"></div>
                        )}
                      </div>
                      <div>
                        <p className="mb-0">{notify?.title}</p>
                        <p className="mb-0">{notify?.body}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-5 px-5 toggle-container">
            <div className="head_box mb-4">
              <h2 className="heading_dash mb-1">
                {messages["notifications_settings"]}
              </h2>
            </div>
            <div className="d-flex">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={toggle?.email_enabled}
                  onChange={(e) => handleToggle(e)}
                  value="email_enabled"
                />
                <span className="slider round"></span>
              </label>
              <span className="cheac_lab">{messages["notify_email"]}</span>
            </div>

            <div className="div-border">
              <div className="d-flex mb-3">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={toggle?.email_new_booking}
                    onChange={(e) => handleToggle(e)}
                    value="email_new_booking"
                  />
                  <span className="slider round"></span>
                </label>
                <span className="cheac_lab">{messages["email_new"]}</span>
              </div>
              <div className="d-flex mb-3">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={toggle?.email_canceled_booking}
                    onChange={(e) => handleToggle(e)}
                    value="email_canceled_booking"
                  />
                  <span className="slider round"></span>
                </label>
                <span className="cheac_lab">{messages["email_cancel"]}</span>
              </div>
              <div className="d-flex mb-3">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={toggle?.email_rescheduled_booking}
                    onChange={(e) => handleToggle(e)}
                    value="email_rescheduled_booking"
                  />
                  <span className="slider round"></span>
                </label>
                <span className="cheac_lab">{messages["email_schedule"]}</span>
              </div>
            </div>

            <div className="d-flex">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={toggle?.sms_enabled}
                  onChange={(e) => handleToggle(e)}
                  value="sms_enabled"
                />
                <span className="slider round"></span>
              </label>
              <span className="cheac_lab">{messages["notify_sms"]}</span>
            </div>

            <div className="div-border">
              <div className="d-flex mb-3">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={toggle?.sms_new_booking}
                    onChange={(e) => handleToggle(e)}
                    value="sms_new_booking"
                  />
                  <span className="slider round"></span>
                </label>
                <span className="cheac_lab">{messages["email_new"]}</span>
              </div>
              <div className="d-flex mb-3">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={toggle?.sms_canceled_booking}
                    onChange={(e) => handleToggle(e)}
                    value="sms_canceled_booking"
                  />
                  <span className="slider round"></span>
                </label>
                <span className="cheac_lab">{messages["email_cancel"]}</span>
              </div>
              <div className="d-flex mb-3">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={toggle?.sms_rescheduled_booking}
                    onChange={(e) => handleToggle(e)}
                    value="sms_rescheduled_booking"
                  />
                  <span className="slider round"></span>
                </label>
                <span className="cheac_lab">{messages["email_schedule"]}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
