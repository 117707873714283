import { useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavbarMentor } from "../components/sharedComponents/NavbarMentor";
import { SideBarClient } from "../components/structure/SideBarClient";

export const DashboardClient = ({
  collapse = false,
  setCollapse,
  children,
}) => {
  const smScreen = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    setCollapse(false);
  }, []);

  return (
    <Container fluid className="mentor-design">
      <Row className="mentor-design__row-holder">
        {smScreen ? (
          <Col
            xs="2"
            style={{
              width: `${collapse ? "23%" : "85%"}`,
              position: "relative",
            }}
            className="mentor-design__row-holder--sidebar-sm"
          >
            <SideBarClient collapse={collapse} setCollapse={setCollapse} />
          </Col>
        ) : (
          <Col
            xs={collapse ? 1 : 2}
            className="mentor-design__row-holder--sidebar"
          >
            <SideBarClient collapse={collapse} setCollapse={setCollapse} />
          </Col>
        )}
        <Col
          xs={smScreen ? 10 : collapse ? 11 : 10}
          className={`mentor-design__row-holder--content ${
            smScreen ? "mentor-design__row-holder--content__sm" : ""
          }`}
          style={{ opacity: smScreen && !collapse ? "0" : "1" }}
        >
          <Col xs={12} className="mentor-design__row-holder--content__navbar">
            <NavbarMentor />
          </Col>
          <Col xs={12} className="mentor-design__row-holder--content__pages">
            {children}
          </Col>
        </Col>
      </Row>
    </Container>
  );
};
