import React from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TransferDesign } from "./TransferDesign";
import { ActionMenu } from "./ActionMenu";

import { calculateTop } from "../../../constants/constants";
import { useIntl } from "react-intl";
import { CallAPI } from "../../../config/api/ApiConfig";
import { useNavigate } from "react-router-dom";
import { PATH_PAGE } from "../../../routes/Paths";
import { Else, If, Then } from "react-if";

export const ReservTable = () => {
  const navigate = useNavigate();
  const { messages } = useIntl();
  const { data: tableData } = CallAPI({
    name: "allReviewsTable	",
    url: "/client/v1/bookings_all",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const tableHeader = [
    messages["session_id"],
    messages["session_name"],
    messages["session_date"],
    messages["categories"],
    messages["booking_statue"],
    messages["attendance_status"],
    messages["actions"],
  ];

  const handleNavigation = (param) => {
    const queryParams = { id: param };
    const searchParams = new URLSearchParams(queryParams).toString();
    navigate(`${PATH_PAGE.detailsInfo}?${searchParams}`);
  };


  return (
    <div className="mt-5 wallet-table">
      <div className=" container-fluid">
        <Row>
          {/* <Col className="wallet-table--btn" xs={12}>
            <button className="btn btn_filter">
              <FontAwesomeIcon icon={faFilter} />
              Filter
            </button>
          </Col> */}
          <Col xs={12}>
            <TableContainer className="parent-table">
              <Table className="table">
                <TableHead className="table_header">
                  <TableRow className="table_header-row">
                    {tableHeader?.map((d) => (
                      <TableCell
                        key={d}
                        align="center"
                        className="table_header-row_heading"
                      >
                        {d}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.map((data, index) => (
                    <TableRow
                      key={data?.id}
                      className="table_body-row"
                      style={{
                        top: calculateTop(index, tableData?.length),
                      }}
                    >
                      <TableCell align="center" className="table_body-row_data">
                        {data?.id}
                      </TableCell>
                      <TableCell align="center" className="table_body-row_data">
                        {data?.service.name}
                      </TableCell>
                      <TableCell align="center" className="table_body-row_data">
                        {data?.date}
                      </TableCell>
                      <TableCell align="center" className="table_body-row_data">
                        {data?.mentor?.category?.name || "-"}
                      </TableCell>
                      <TableCell align="center" className="table_body-row_data">
                      <If
                          condition={
                            data?.booking_status == "completed"
                          }
                        >
                          <Then>
                            <TransferDesign
                              className="green"
                              text={messages[data?.booking_status]}
                            />
                          </Then>
                          <Else>
                            <If
                              condition={
                                data?.booking_status == "canceled"
                              }
                            >
                              <Then>
                                <TransferDesign
                                  className="red"
                                  text={messages[data?.booking_status]}
                                />
                              </Then>
                            </If>
                            <Else>
                              <If
                                condition={
                                  data?.booking_status == "scheduled"
                                }
                              >
                                <Then>
                                  <TransferDesign
                                    className="orange"
                                    text={messages[data?.booking_status]}
                                  />
                                </Then>
                              </If>
                            </Else>
                          </Else>
                        </If>

                        {/* <TransferDesign
                          className={
                            data?.booking_status === 'canceled'
                              ? 'red'
                              : 'green'
                          }
                          text={messages[data?.booking_status]}
                        /> */}
                      </TableCell>
                      <TableCell align="center" className="table_body-row_data">
                        {messages[data?.attendance_status]}
                      </TableCell>
                      <TableCell align="center" className="table_body-row_data">
                        <ActionMenu
                          onClick={() => handleNavigation(data?.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Col>
        </Row>
      </div>
    </div>
  );
};
