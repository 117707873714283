import React from "react";
import SVG from "react-inlinesvg";
import { Link, useNavigate } from "react-router-dom";
import { PATH_PAGE } from "../../routes/Paths";
import { iconPath } from "../../constants/constants";
import { useIntl } from "react-intl";

export const NotFound = () => {
  const path = iconPath();
  const navigate = useNavigate();
  const { messages } = useIntl();

  return (
    <div className="mt-5 pt-5 text-center">
      <SVG src={`${path}logo.svg`} onClick={() => navigate(PATH_PAGE.clHome)} />{" "}
      <h1 className="mt-3">{messages["not_found"]}</h1>
      <p>{messages["the_page_you_are_looking_for_doesn"]}</p>
      <Link to={PATH_PAGE.clHome}>{messages['go_back_to_the_home_page']}</Link>
    </div>
  );
};
