import { ReservTable } from "./ReservTable";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";

export const Reserv = () => {
  const { messages } = useIntl();

  return (
    <div className="container-fluid">
      <div className="head_box">
        <h2 className="heading_dash mb-1">{messages["reservations"]}</h2>
        <p className="heading_dis">
          {messages["you_can_view_all_your_bookings_here"]}
        </p>
      </div>
      <ReservTable />
    </div>
  );
};
