import React, { useContext } from "react";
import SVG from "react-inlinesvg";
import { iconPath } from "../../../constants/constants";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { CallAPI } from "../../../config/api/ApiConfig";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";

const Favourites = () => {
  const path = iconPath();
  const { messages } = useIntl();
  const [show, setShow] = useState(false);
  const [selectedFavourite, setSelectedFavourite] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [mentorId, setMentorId] = useState(null);

  const { data: favourites, refetch: getAllFavourites } = CallAPI({
    name: "favourites",
    url: "/client/v1/favourites",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const { refetch } = CallAPI({
    name: ["toggleFavouriteMentors", mentorId],
    url: "/client/v1/toggle-favourites",
    refetchOnWindowFocus: false,
    enabled: false,
    method: "post",
    body: {
      mentor_id: mentorId,
    },
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      getAllFavourites();
      setMentorId(null);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const handleSecondButtonClick = (favourite) => {
    setSelectedFavourite(favourite);
    setMentorId(favourite?.id);
    handleShow();
  };

  return (
    <div className="favourite_wrapper">
      <div className="container-fluid">
        <div className="head_box">
          <h2 className="heading_dash mb-1">{messages["Favourites"]}</h2>
          <p className="heading_dis">{messages["Favourites.hint"]}</p>
        </div>

        <div className="row">
          {favourites?.map((favourite) => (
            <div className="col-lg-4 col-md-6 col-12" key={favourite.id}>
              <div className="fav_card">
                <div className="d-flex justify-content-between fav_header">
                  <img
                    src={favourite?.photo}
                    className="avtar_sm"
                    alt="profileImage"
                  />

                  <button
                    className="btn fav_btn"
                    onClick={() => {
                      handleSecondButtonClick(favourite);
                    }}
                  >
                    <SVG src={`${path}favGreen.svg`} />
                  </button>
                </div>
                <div className="fav_body">
                  <h2>
                    {favourite.first_name} {favourite.last_name}
                  </h2>
                  <h3>{favourite.job_title}</h3>
                  <p>{favourite.personal_introduction}</p>
                  <div className="reviews">
                    <div>{favourite.rate}</div>
                    <span>
                      <span>
                        {favourite.reviews_count} {messages["reviews"]}
                      </span>{" "}
                      /{" "}
                      <span>
                        {favourite.sessions_count} {messages["sessions"]}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="fav_foot">
                  {favourite.experiences?.map((experience) => (
                    <span key={experience.id}>{experience.name}</span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* modal of remove fav */}
      <Modal show={show} onHide={handleClose} className="unFav_modal">
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body className="text-center">
          <SVG src={`${path}warning.svg`} />
          <h3>{messages["are.you.sure"]}</h3>
          <p>
            {messages["you.are.remove"]} {selectedFavourite?.first_name}{" "}
            {selectedFavourite?.last_name} {messages["from.favourite.list"]}
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="btn btn-cancel"
            onClick={() => {
              handleClose();
            }}
          >
            {messages["no"]}
          </Button>
          <Button
            className="btn btn-yes"
            onClick={() => {
              setMentorId(selectedFavourite?.id);
              setSelectedFavourite(null);
              handleClose();
              refetch();
            }}
          >
            {messages["yes"]}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Favourites;
