// import React, { useContext } from "react";
import React, { useContext, useEffect, useState } from "react";

import {
  Col,
  Container,
  Dropdown,
  Form,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { BsBoxArrowInRight } from "react-icons/bs";
import SVG from "react-inlinesvg";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { BsSearch, BsGlobe, BsPerson } from "react-icons/bs";
import { ButtonCustom } from "./ButtonCustom";
import { LocaleContext } from "../../config/localization/LanguageProvider";
import { AuthContext } from "../../auth/AuthProvider";
import { PATH_PAGE } from "../../routes/Paths";
import { useIntl } from "react-intl";
import { CallAPI } from "../../config/api/ApiConfig";
import { useRef } from "react";

export const NavbarApp = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenTwo, setDropdownOpenTwo] = useState(false);

  const handleDropdownMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleDropdownMouseLeave = () => {
    setDropdownOpen(false);
  };

  const handleDropdownMouseEnterTwo = () => {
    setDropdownOpenTwo(true);
  };

  const handleDropdownMouseLeaveTwo = () => {
    setDropdownOpenTwo(false);
  };

  const handleDropdownClick = () => {
    // Scroll to the target component
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const targetRef = useRef(null);

  const [showNavbar, setShowNavbar] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const shouldShowNavbar = scrollTop === 0;
      setShowNavbar(shouldShowNavbar);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const { locale, setLocale } = useContext(LocaleContext);
  const { user, setUser } = useContext(AuthContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const expand = "lg";
  const iconPath = process.env.PUBLIC_URL + "/imgs/";
  const largeScreen = useMediaQuery("(max-width:992px)");
  const smScreen = useMediaQuery("(max-width:767px)");
  const { messages } = useIntl();

  const { data: categories } = CallAPI({
    name: "categoriesForNavbar",
    url: "/categories",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const { refetch } = CallAPI({
    name: "logoutSignedUpUser",
    url: `${
      user?.data?.type === "client"
        ? "/client/v1/logout"
        : "/mentor/v1/auth/logout"
    }`,
    refetchOnWindowFocus: false,
    method: "post",
    enabled: false,
    onSuccess: (res) => {
      localStorage?.removeItem("user_data");
      setUser(null);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  return (
    <div className="bg-light">
      <Navbar
        key={expand}
        expand={expand}
        className={`nav fixed-top shadow-sm ${showNavbar ? "" : "hide-navbar"}`}
      >
        <Container fluid>
          <Row className="w-100 ms-auto">
            <Col xs={{ span: 6, order: 2 }} lg={{ span: 3, order: 1 }}>
              <Navbar.Brand>
                <Link to="/">
                  <SVG
                    src={`${iconPath}logo.svg`}
                    // onClick={() => navigate(PATH_PAGE.clHome)}
                  />
                </Link>
           
              </Navbar.Brand>
            </Col>
            <Col
              xs={{ span: 6, order: 2 }}
              className={`${
                !largeScreen
                  ? "d-none"
                  : "d-flex align-items-center justify-content-end"
              }`}
            >
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              lg={{ span: 9, order: 2 }}
              className="d-flex align-items-center justify-content-end"
            >
              <Row>
                <Col
                  xs="12"
                  className={`d-flex ${
                    largeScreen ? "justify-content-end" : "justify-content-end"
                  }`}
                >
                  <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end"
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title
                        id={`offcanvasNavbarLabel-expand-${expand}`}
                      >
                        <SVG
                          width={100}
                          src={`${iconPath}logo.svg`}
                          onClick={() => navigate(PATH_PAGE.clHome)}
                        />
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Nav
                        className={`justify-content-end flex-grow-1 ${
                          !largeScreen && "align-items-end"
                        } gap-2 pe-3`}
                      >
                        {user && (
                          <>
                            <Link
                              className="nav-link"
                              onClick={() => refetch()}
                            >
                              <BsBoxArrowInRight className="log_cust" />
                            </Link>
                          </>
                        )}
                        {/* <Link className="nav-link" to={PATH_PAGE.mentors}>
                        {messages["explore_mentor"]}
                      </Link> */}

                        {/* <NavDropdown
                        className="navbar-dropDown"
                        title={messages["explore_mentor"]}
                        id={`offcanvasNavbarDropdown-expand-${expand}`}
                      >
                        <Container>
                          <Row>
                            {categories?.map((cat) => (
                              <Col key={cat?.id} xs={12} md={4}>
                                <button
                                  className={`${
                                    location?.pathname ===
                                      PATH_PAGE?.CategoriesDetails &&
                                    +id === cat?.id
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    navigate(
                                      `${PATH_PAGE.CategoriesDetails}?id=${cat?.id}`
                                    )
                                  }
                                >
                                  {cat?.name}
                                </button>
                              </Col>
                            ))}

                
                          </Row>
                        </Container>
                      </NavDropdown> */}

                        <NavDropdown
                          title={messages["explore_mentor"]}
                          id="offcanvasNavbarDropdown"
                          onMouseEnter={handleDropdownMouseEnter}
                          onMouseLeave={handleDropdownMouseLeave}
                          show={dropdownOpen}
                          className="navbar-dropDown"
                        >
                                   {/* className={`dropdown-menu  px-2  ${
                              dropdownOpen ? "show" : ""
                            }`} */}
                          <div
                                             className="px-2"

                            aria-labelledby="offcanvasNavbarDropdown"
                          >
                            <Row>
                              {categories?.map((cat) => (
                                <Col key={cat?.id} xs={12} md={4}>
                                  <button
                                    className={`${
                                      location?.pathname ===
                                        PATH_PAGE?.CategoriesDetails &&
                                      +id === cat?.id
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      navigate(
                                        `${PATH_PAGE.CategoriesDetails}?id=${cat?.id}`
                                      )
                                    }
                                  >
                                    {cat?.name}
                                  </button>
                                </Col>
                              ))}
                              <Col key="all" xs={12} md={4}>
                                <button
                                  onClick={() =>
                                    navigate(`${PATH_PAGE.CategoriesDetails}`)
                                  }
                                >
                                  {messages["all"]}
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </NavDropdown>

                        <NavDropdown
                          id="offcanvasNavbarDropdown2"
                          onMouseEnter={handleDropdownMouseEnterTwo}
                          onMouseLeave={handleDropdownMouseLeaveTwo}
                          show={dropdownOpenTwo}
                          title={messages["Digetal_content"]}
                          className="navbar-dropDown navbar-dropDown-2"
                        >
                          <div
                          className="px-2"
                            aria-labelledby="offcanvasNavbarDropdown2"
                          >
                            <NavDropdown.Item href="#action/3.1">
                              {messages["Digetal_content1"]}
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">
                              {messages["Digetal_content2"]}
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">
                              {messages["Digetal_content3"]}
                            </NavDropdown.Item>
                          </div>
                        </NavDropdown>

                        <Link className="nav-link" to={PATH_PAGE.UserAbout}>
                          {messages["common.about_us"]}
                        </Link>

                        {!user && (
                          <>
                            <Link className="nav-link" to={PATH_PAGE.UserLogin}>
                              {messages["sign in"]}
                            </Link>
                            <ButtonCustom
                              className="w-auto btn-mentor"
                              hasIcon={<BsPerson className="btn-icon" />}
                              text="become_mentor"
                              onClick={() => navigate(PATH_PAGE.signUp)}
                            />
                          </>
                        )}

                        {user && (
                          <>
                            <Link
                              className="nav-link"
                              to={
                                user?.data?.type === "client"
                                  ? PATH_PAGE.userDashboard
                                  : PATH_PAGE.dashboard
                              }
                            >
                              {messages["dashboard"]}
                            </Link>
                          </>
                        )}

                        <div className="language-selection">
                          <BsGlobe />
                          <Form.Select
                            onChange={(e) => {
                              setLocale(e.target.value);
                            }}
                            value={locale}
                            className="no-arabic"
                          >
                            <option value="en">{messages["english"]}</option>
                            <option value="ar">{messages["arabic"]}</option>
                          </Form.Select>
                        </div>
                      </Nav>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </div>
  );
};
// {user && (
//   <button
//     className="buttonCustomDefault w-auto btn-mentor px-5"
//     onClick={() => navigate(PATH_PAGE.mentors)}
//   >
//     {messages["book_session"]}
//   </button>
// )}