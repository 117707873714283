import React, { useContext } from "react";
import { useState } from "react";
import { Modals } from "../../../components/sharedComponents/Modals";

import SVG from "react-inlinesvg";
import { iconPath } from "../../../constants/constants";
import Tooltip from "@mui/material/Tooltip";
import { CallAPI } from "../../../config/api/ApiConfig";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { LocaleContext } from "../../../config/localization/LanguageProvider";
import { PATH_PAGE } from "../../../routes/Paths";
import { toast } from "react-toastify";
import { SharedPlatform } from "./SharedPlatform";

export const CategoriesDetailsCard = () => {
  // const [isFavourite, setIsFavourite] = useState(false);

  // const handleFavouriteClick = () => {
  //   setIsFavourite(!isFavourite);
  // };

  const [mentorId, setMentorId] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const path = iconPath();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const { locale } = useContext(LocaleContext);
  const { messages } = useIntl();
  const { refetch } = CallAPI({
    name: ["toggleFavourite", id],
    url: "/client/v1/toggle-favourites",
    refetchOnWindowFocus: false,
    enabled: false,
    method: "post",
    body: {
      mentor_id: id,
    },
    select: (res) => res?.data?.data,
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const [isFavourite, setIsFavourite] = useState(false);

  const renderMentorDetails = () => {
    const { data: mentor } = CallAPI({
      name: "mentorDetailsCategory",
      url: "/mentors/" + id,
      refetchOnWindowFocus: false,
      enabled: true,
      select: (res) => res?.data?.data,
      onSuccess: (res) => {
        setIsFavourite(res?.is_favourite || false);
      },
    });

    const handleShareNavigation = (param) => {
      setOpenModal(true);
    };

    return (
      <div className="col-md-12 d-lg-flex justify-content-between border rounded p-3 mb-3">
        <div className="public_info  mb-3 d-flex cat_details_box">
          <img src={mentor?.mentor?.photo} className="avatar" alt="avatar" />

          <div>
            <div className="d-lg-flex justify-content-between mb-3">
              <div>
                <h3>
                  {mentor?.mentor?.first_name} {mentor?.mentor?.last_name}{" "}
                </h3>
                <p>{mentor?.mentor?.job_title}</p>
                <ul>
                  <li>
                    <SVG src={`${path}l1.svg`} />
                    {mentor?.mentor?.industry?.name}
                  </li>
                  <li>
                    <SVG src={`${path}l2.svg`} />
                    {mentor?.mentor?.city?.name}
                  </li>
                  <li>
                    <SVG src={`${path}l3.svg`} />
                    {mentor?.mentor?.langs.map((lang, index) => (
                      <span className="badg_cust badg_def">
                        {lang?.name}
                        {/* {locale === "ar"
                          ? lang?.name_ar + " "
                          : lang?.name_en + " "} */}
                      </span>
                    ))}
                  </li>
                </ul>
              </div>

              <div></div>
            </div>

            <div className="badg_wrapper">
              {mentor?.mentor?.tools.map((tool, index) => (
                <span className="badg_cust badg_def">
                  {tool?.name}
                  {/* {locale === "ar" ? tool?.name_ar + " " : tool?.name_en + " "} */}
                </span>
              ))}
            </div>
            <div className="public_link row mt-3">
              {mentor?.mentor?.experiences.map((experience, index) => (
                <div key={index} className="col-auto">
                  <Tooltip placement="top" title={`${experience?.name}`} arrow>
                    <h4>{experience?.name}</h4>
                  </Tooltip>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-between public_info fav_cat mb-3">
            <div>
              <h3>{mentor?.mentor?.rate}</h3>
              <p>
                {mentor?.mentor?.reviews_count} {messages["reviews"]} /{" "}
                {mentor?.mentor?.sessions_count} {messages["sessions"]}
              </p>
            </div>
            <div>
              <button
                className="btn fav"
                onClick={() => {
                  setIsFavourite(!isFavourite);

                  refetch();
                }}
              >
                <SVG
                  src={`${path}${!isFavourite ? "fav.svg" : "favGreen.svg"}`}
                />
              </button>
              <button
                className="btn"
                onClick={() => handleShareNavigation(mentor?.id)}
              >
                {/* <FontAwesomeIcon icon={faShare} /> */}
                <SVG src={`${path}share.svg`} />
              </button>
            </div>
          </div>
          <div className="border rounded p-2 d-flex  cal_cat public_info">
            {/* <FontAwesomeIcon icon={faCalendarWeek} /> */}
            {/* <SVG src={`${path}calender.svg`} /> */}
            <div className="day-wrap">
              <h5>
                {new Date(mentor?.next_availability)?.toLocaleDateString("en-US", {
                  weekday: "short",
                })}
              </h5>{" "}
              <h6>
                {new Date(mentor?.next_availability)?.toLocaleDateString("en-US", {
                  day: "numeric",
                })}
              </h6>
            </div>{" "}
            <div>
              <h3 className="mb-0"> {messages["next_availability"]} </h3>
              <p>{mentor?.next_availability}</p>
            </div>
          </div>
        </div>
        <Modals
          title="share.via"
          body={<SharedPlatform setOpenModal={setOpenModal} id={id} />}
          open={openModal}
          handleClose={() => {
            setOpenModal(false);
          }}
          showfooter={false}
        />
      </div>
    );
  };
  // Add this line at the beginning of your component
  // const [isFavourite, setIsFavourite] = useState(mentor?.is_favourite || false);

  return <div className="CategoriesDetailsCard">{renderMentorDetails()}</div>;
};
