import React from "react";
import SVG from "react-inlinesvg";
import { iconPath } from "../../constants/constants";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { PATH_PAGE } from "../../routes/Paths";
import { CallAPI } from "../../config/api/ApiConfig";
import { toast } from "react-toastify";
import ScrollAnimation from 'react-animate-on-scroll';

export const UserFooter = () => {
  const path = iconPath();
  const { messages } = useIntl();

  const { data } = CallAPI({
    name: "linksFooter",
    url: "/setting",
    refetchOnWindowFocus: false,
    select: (res) => res?.data?.data,
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  return (
    <footer>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"

      />
     
      <div className="container">
        {/* <div className="row">
          <div className="col-md-6">
            <h3> {messages["Newsletter"]}</h3>
            <p>{messages["NewsletterDis"]}</p>
          </div>
          <div className="col-md-6">
            <div className="input-group mb-3 p-2">
              <span className="input-group-text border-0 bg-transparent p-0 mx-2">
                <SVG src={`${path}news.svg`} />
              </span>
              <input
                className="form-control border-0 bg-transparent"
                placeholder={messages["enter.email"]}
              />
              <span className="input-group-text border-0 bg-transparent p-0">
                <button className="btn btn_news">{messages["send"]}</button>
              </span>
            </div>
          </div>
        </div> */}
        <div className="row border-top pt-5">
          <div className="col-lg-6 col-md-12">
          <ScrollAnimation animateIn='fadeInDown' duration={2}>

            <div className="foot ">

              <SVG src={`${path}logo.svg`} className="logo_foot" />

              <p>
                {/* We ara a lorem ipsum dolor sit amet */}
              </p>

              <ul className="d-flex social_foot mt-4">
                <li>
                  <Link
                    to={data?.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SVG src={`${path}social1.svg`} />
                  </Link>
                </li>
                <li>
                  <Link
                    to={data?.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SVG src={`${path}social2.svg`} />
                  </Link>
                </li>
                <li>
                  <Link
                    to={data?.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SVG src={`${path}social3.svg`} />
                  </Link>
                </li>
                <li>
                  <Link
                    to={data?.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SVG src={`${path}social4.svg`} />
                  </Link>
                </li>
                <li>
                  <Link
                    to={data?.youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SVG src={`${path}social5.svg`} />
                  </Link>
                </li>
              </ul>
            

            </div>
            </ScrollAnimation>

          </div>
      
          <div className="col-lg-2 col-md-4">
          <ScrollAnimation animateIn='fadeInUp' duration={2}>
          <div className="foot">
              <h5> {messages["About"]}</h5>
              <ul>
                <li>
                  <Link to={PATH_PAGE.UserAbout}>{messages["AboutUs"]} </Link>
                </li>
                {/* <li>
                  <Link to="/">{messages["Learn"]}</Link>
                </li> */}
                {/* <li>
                  <Link to="/">{messages["Careers"]}</Link>
                </li> */}
                <li>
                  <Link to={PATH_PAGE.UsertermsHome}>{messages["Terms"]} </Link>
                </li>
                <li>
                  <Link to={PATH_PAGE.UserPrivacyPolicy}>
                    {messages["Privacy"]}
                  </Link>
                </li>
              </ul>
            </div>
      </ScrollAnimation>
     
          </div>
          <div className="col-lg-2 col-md-4">
          <ScrollAnimation animateIn='fadeInUp' duration={2} >

            <div className="foot">
              <h5> {messages["Support"]}</h5>
              <ul>
                <li>
                  <Link to={PATH_PAGE.UserContactUs}>
                    {messages["ContactUs"]}
                  </Link>
                </li>
                {/* <li>
                  <Link to={PATH_PAGE.chatUser}>{messages["OnlineChat"]}</Link>
                </li> */}
                {/* <li>
                  <Link to="/">{messages["Telegram"]} </Link>
                </li> */}
              </ul>
            </div>
            </ScrollAnimation>

          </div>
          <div className="col-lg-2 col-md-4">
          <ScrollAnimation animateIn='fadeInUp' duration={2} >

            <div className="foot">
              <h5> {messages["FAQq"]}</h5>
              <ul>
                <li>
                  <Link to={PATH_PAGE.UserFaqhome}> {messages["Account"]}</Link>
                </li>
                <li>
                  <Link to={PATH_PAGE.UserFaqhome}>
                    {messages["ManageDeliveries"]}
                  </Link>
                </li>
                <li>
                  <Link to={PATH_PAGE.UserFaqhome}> {messages["Orders"]}</Link>
                </li>
                <li>
                  <Link to={PATH_PAGE.UserFaqhome}>{messages["Payments"]}</Link>
                </li>
                <li>
                  <Link to={PATH_PAGE.UserFaqhome}>{messages["Returns"]} </Link>
                </li>
              </ul>
            </div>
            </ScrollAnimation>

          </div>
        </div>
      </div>
    </footer>
  );
};
