import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

export const ActionMenu = ({ onClick = () => {} }) => {  
  // const path = iconPath();

  return (
    <div className="container">
      <button onClick={onClick} className="action-menu">
        <FontAwesomeIcon icon={faEllipsisV} />
      </button>
      {/* <Link className="blue_text" to={PATH_PAGE.reservDetails}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </Link> */}
    </div>
  );
};
