import React from "react";
import { Row, Col } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TransferDesign } from "./TransferDesign";
import { ActionMenu } from "./ActionMenu";

import { calculateTop } from "../../../../constants/constants";
import { useIntl, FormattedMessage } from "react-intl";
import { CallAPI } from "../../../../config/api/ApiConfig";
import { useNavigate } from "react-router-dom";
import { PATH_PAGE } from "../../../../routes/Paths";
import { Else, If, Then } from "react-if";

export const ReservTable = () => {
  const { messages } = useIntl();
  const navigate = useNavigate();

  const { data: tableData } = CallAPI({
    name: "reviews",
    url: "/mentor/v1/bookings",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const tableHeader = [
    messages["reservation_id"],
    // messages["session_name"],
    messages["reservation_date"],
    messages["booking_statue"],
    messages["attendance_status"],
    messages["actions"],
  ];

  const handleNavigation = (param) => {
    const queryParams = { id: param };
    const searchParams = new URLSearchParams(queryParams).toString();
    navigate(`${PATH_PAGE.reservDetails}?${searchParams}`);
  };

  return (
    <div className="mt-5 wallet-table">
      <div className=" container-fluid">
        <div className="head_box">
          <h2 className="heading_dash mb-1">
            {messages["other_reservations"]}
          </h2>
          <p className="heading_dis">{messages["see_also"]}</p>
        </div>
        <Row>
          <Col xs={12}>
            <TableContainer className="parent-table">
              <Table className="table">
                <TableHead className="table_header">
                  <TableRow className="table_header-row">
                    {tableHeader?.map((d) => (
                      <TableCell
                        key={d}
                        align="center"
                        className="table_header-row_heading"
                      >
                        {d}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.map((data, index) => (
                    <TableRow
                      key={data?.id}
                      className="table_body-row"
                      style={{
                        top: calculateTop(index, tableData?.length),
                      }}
                    >
                      <TableCell align="center" className="table_body-row_data">
                        {data?.id}
                      </TableCell>
                      {/* <TableCell align="center" className="table_body-row_data">
                        {data?.session?.title}
                      </TableCell> */}
                      <TableCell align="center" className="table_body-row_data">
                        {data?.start_session}
                      </TableCell>
                      <TableCell align="center" className="table_body-row_data">
                        <If condition={data?.status == "complete"}>
                          <Then>
                            <TransferDesign
                              className="green"
                              text={messages["completed"]}
                            />
                          </Then>
                          <Else>
                            <If condition={data?.status == "canceled"}>
                              <Then>
                                <TransferDesign
                                  className="red"
                                  text={messages[data?.status]}
                                />
                              </Then>
                            </If>
                            <Else>
                              <If condition={data?.status == "scheduled"}>
                                <Then>
                                  <TransferDesign
                                    className="orange"
                                    text={messages[data?.status]}
                                  />
                                </Then>
                              </If>
                            </Else>
                          </Else>
                        </If>

                        {/* <TransferDesign
                          className="green"
                          text={messages[data?.booking_status]}
                        /> */}
                      </TableCell>
                      <TableCell align="center" className="table_body-row_data">
                        {data?.status == "complete"
                          ? messages["attended"]
                          : "-"}
                      </TableCell>
                      <TableCell align="center" className="table_body-row_data">
                        <ActionMenu
                          onClick={() => handleNavigation(data?.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Col>
        </Row>
      </div>
    </div>
  );
};
