import React from "react";
import { useIntl } from "react-intl";
import { NavbarApp } from "../components/sharedComponents/NavbarApp";
import { UserFooter } from "../components/sharedComponents/UserFooter";

export const LayoutClient = ({ children }) => {
  const { messages } = useIntl();

  const currentYear = new Date().getFullYear();

  return (
    <>
      <NavbarApp />
      <div className="padding-wrapper">
        <div className="page-content">{children}</div>
        <UserFooter />
      </div>
      <footer className="footer">
        <div>
          &copy; {currentYear} {messages["footer_copy_right"]}
        </div>
      </footer>
    </>
  );
};
