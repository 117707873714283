import React, { useContext } from "react";
import { FirstHeader } from "../../components/sharedComponents/FirstHeader";
import { CallAPI } from "../../config/api/ApiConfig";
import { LocaleContext } from "../../config/localization/LanguageProvider";

export const About = () => {
  const { locale } = useContext(LocaleContext);

  const { data: about } = CallAPI({
    name: "about",
    url: "/mentor/v1/page/about",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  return (
    <div className="terms">
      <>
        <FirstHeader
          className="terms__header"
          text={locale === "ar" ? about?.name_ar : about?.name_en}
        />
      </>

      <div
        className="terms__content"
        dangerouslySetInnerHTML={{
          __html: locale === "ar" ? about?.body_ar : about?.body_en,
        }}
      />
    </div>
  );
};
