import { FirstHeader } from "../../components/sharedComponents/FirstHeader";
import React, { useContext } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Accordion from "react-bootstrap/Accordion";
import { useIntl } from "react-intl";
import { CallAPI } from "../../config/api/ApiConfig";
import { LocaleContext } from "../../config/localization/LanguageProvider";

export const Faq = () => {
  const { locale } = useContext(LocaleContext);
  const { messages } = useIntl();
  const { data: faqCategories } = CallAPI({
    name: "review",
    url: "/mentor/v1/faq_categories",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  return (
    <div className="container faq_wrapper">
      <div className="text-center mb-5">
        <FirstHeader text={messages["FAQ"]} />
      </div>
      <div className="row">
        <div className="col-12">
          <Tabs
            defaultActiveKey="Experiences"
            id="fill-tab-example"
            fill
            className="mb-3"
          >
            {faqCategories?.map((data, index) => (
              <Tab
                eventKey={data?.name_en}
                title={locale === "ar" ? data?.name_ar : data?.name_en}
              >
                <Accordion defaultActiveKey={["0"]} alwaysOpen>
                  {data?.faqs?.map((faq, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>
                        {locale === "ar" ? faq?.question_ar : faq?.question_en}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          {locale === "ar" ? faq?.answer_ar : faq?.answer_en}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  );
};
