import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import Slider from "@mui/material/Slider";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ButtonCustom } from "../sharedComponents/ButtonCustom";
import { useIntl } from "react-intl";

export const Filter = ({
  callWithFilters,
  review,
  selectedReview,
  setSelectedReview,
  selectedStatus,
  setSelectedStatus,
  setPrice,
  price,
  langs,
  language,
  setLanguage,
  industries,
  industry,
  setIndustry,
  tools,
  tool,
  setTool,
  experiences,
  experience,
  setExperience,
  date,
  setDate,
  setValue,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const selectRef = useRef(null);
  const { messages } = useIntl();
  const [tempSelectedReview, setTempSelectedReview] = useState(selectedReview);
  const [tempSelectedStatus, setTempSelectedStatus] = useState(selectedStatus);
  const [TempPrice, setTempPrice] = useState(price);
  const [tempLanguage, setTempLanguage] = useState(language);
  const [tempIndustry, setTempIndustry] = useState(industry);
  const [tempTool, setTempTool] = useState(setTool);
  const [tempExperience, setTempExperience] = useState(experience);
  const [tempDate, setTempDate] = useState(date);

  const handleRangeChange = (event, newValue) => {
    setTempPrice({
      min_price: newValue[0],
      max_price: newValue[1],
    });
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setShowDropdown(!showDropdown);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectClose = () => {
    setShowDropdown(false);
  };

  const ChangeData = () => {
    setSelectedReview(tempSelectedReview);
    setSelectedStatus(tempSelectedStatus);
    setPrice(TempPrice);
    setLanguage(tempLanguage);
    setIndustry(tempIndustry);
    setExperience(tempExperience);
    setDate(tempDate);
  };

  const applyfilters = () => {
    const fetchData = async () => {
      await ChangeData();
      callWithFilters();
    };

    fetchData().then(handleSelectClose);
  };

  function clearFilter() {
    setTempSelectedReview("");
    setTempPrice("");
    setTempLanguage("");
    setTempIndustry("");
    setTempTool("");
    setTempExperience("");
    setTempDate("");
  }

  return (
    <div className="filter-container">
      <button className="btn btn_filter" onClick={toggleDropdown}>
        <FontAwesomeIcon icon={faFilter} />
        {messages["filter.mentor"]}
      </button>
      {showDropdown && (
        <ClickAwayListener onClickAway={handleSelectClose}>
          <div className="filter-dropdown row" ref={selectRef}>
            <div className="filter-header col-12">
              <h3>{messages["filter.mentor"]}</h3>
              <button className="btn-close" onClick={handleSelectClose}>
                &times;
              </button>
            </div>
            {/* <div className="mb-3 col-md-6 ">
              <h5>{messages["status"]}</h5>
              <select
                onChange={(e) => setTempSelectedStatus(e?.target?.value)}
                class="form-select"
                aria-label="Default select example"
                value={tempSelectedStatus}
              >
                <option selected value=""></option>
                {review?.map((rev) => (
                  <option key={rev?.id} value={rev?.id}>
                    {messages[rev?.text]}
                  </option>
                ))}
              </select>
            </div> */}

            <div className="mb-3 col-md-6">
              <h5>{messages["by_rate"]}</h5>
              <select
                onChange={(e) => setTempSelectedReview(e?.target?.value)}
                class="form-select"
                aria-label="Default select example"
                value={tempSelectedReview}
              >
                <option selected value=""></option>
                {review?.map((rev) => (
                  <option key={rev?.id} value={rev?.id}>
                    {messages[rev?.text]}
                  </option>
                ))}
              </select>
            </div>

            <div className="filter-section border-bottom border-top col-12 mb-2">
              <h5>{messages["price.range"]}</h5>
              <div className="d-flex justify-content-between">
                <label className="">
                  {TempPrice?.min_price} {messages["sar"]}
                </label>
                <label className="">
                  {TempPrice?.max_price} {messages["sar"]}
                </label>
              </div>

              <Slider
                min={0}
                max={10000}
                value={[TempPrice?.min_price, TempPrice?.max_price]}
                onChange={handleRangeChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
              />
            </div>

            <div className="mb-3 col-md-6 ">
              <h5>{messages["languages"]}</h5>
              <select
                onChange={(e) => setTempLanguage(e?.target?.value)}
                class="form-select"
                aria-label="Default select example"
                value={tempLanguage}
              >
                <option value=""></option>
                {langs?.map((lang) => (
                  <option key={lang?.id} value={lang?.id}>
                    {lang?.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-3 col-md-6">
              <h5>{messages["industry"]}</h5>
              <select
                onChange={(e) => setTempIndustry(e?.target?.value)}
                class="form-select"
                aria-label="Default select example"
                value={tempIndustry}
              >
                <option value=""></option>
                {industries?.map((industry) => (
                  <option key={industry?.id} value={industry?.id}>
                    {industry?.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-3 col-md-6 ">
              <h5>{messages["tools"]}</h5>
              <select
                onChange={(e) => setTempTool(e?.target?.value)}
                class="form-select"
                aria-label="Default select example"
                value={tempTool}
              >
                <option value=""></option>
                {tools?.map((to) => (
                  <option key={to?.id} value={to?.id}>
                    {to?.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-3 col-md-6">
              <h5>{messages["experiences"]}</h5>
              <select
                onChange={(e) => setTempExperience(e?.target?.value)}
                class="form-select"
                aria-label="Default select example"
                value={tempExperience}
              >
                <option value=""></option>
                {experiences?.map((ex) => (
                  <option key={ex?.id} value={ex?.id}>
                    {ex?.label}
                  </option>
                ))}
              </select>
            </div>

            {/* <div className="mb-3 col-md-12">
              <h5>Tools</h5>
              <select class="form-select" aria-label="Default select example">
                <option selected disabled>
                  Choose
                </option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div> */}

            <div className="mb-3 col-md-12 pt-3 border-top ">
              <h5>{messages["availabe.time"]}</h5>
              <input
                onChange={(e) => {
                  setTempDate(e?.target?.value);
                }}
                value={tempDate}
                type="date"
                className="timeDateInput"
                onKeyDown={(e) => e.preventDefault()}
              />
            </div>
            <div className="border-top col-12 py-3">
              <ButtonCustom
                className="w-50"
                text="apply.filters"
                onClick={() => applyfilters()}
              />

              <ButtonCustom
                className="w-50"
                text="apply.delete"
                onClick={clearFilter}
              />
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};
