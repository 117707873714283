import React from "react";
import Chart from "./Chart";
import Statistics from "./Statistics";
// import { DashboardLayout } from "./layouts/DashboardLayout";

export const HomeDashboard = () => {
  console.log("s");
  return (
    <>
      <Chart />
      {/* <Statistics/> */}
    </>
  );
};

// export function HomeDashboard() {
//   return (
//     <DashboardLayout>
//   <Chart />
//      <Statistics/>
//     </DashboardLayout>
//   );
// }
