import React from "react";
import { WalletHeader } from "../WalletHeader";
import { WalletWithdrawData } from "../withdraw/WalletWithdrawData";

export const WalletWithdraw = () => {
  return (
    <div className="profile mb-2">
      <WalletHeader />
      <WalletWithdrawData />
    </div>
  );
};
