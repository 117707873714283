import React, { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PATH_PAGE } from "../../../routes/Paths";
import { OtpConfirm } from "../Otpconfirm/OtpConfirm";
import { iconPath } from "../../../constants/constants";
import { SignUpStepOne } from "./SignUpStepOne";

import { toast } from "react-toastify";
import { CallAPI } from "../../../config/api/ApiConfig";
import axios from "axios";
import { AxiosContext } from "../../../config/api/AxiosConfig";

export const UserSignUp = () => {
  const navigate = useNavigate();
  const { setLoader } = useContext(AxiosContext);

  const [chekTerms, setCheckTerms] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [otp, setOtp] = useState("");
  const [signUpSteps, setSignUpSteps] = useState(1);
  const [passwordType, setPasswordType] = useState(true);
  const [userVerifyOtp, setUserVerifyOtp] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState(true);
  const classFlex = "justify-content-center align-items-center d-flex";
  const path = iconPath();

  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      phone: "",
      country_id: "",
      region_id: "",
      city_id: "",
    },
  });

  const registerUser = async () => {
    const formData = new FormData();
    formData.append("first_name", watch("first_name"));
    formData.append("last_name", watch("last_name"));
    formData.append(
      "mobile",
      watch("phone")?.slice(5)?.trim().replace(/\s/g, "")
    );
    formData.append("email", watch("email"));
    formData.append("password", watch("password"));
    formData.append("password_confirmation", watch("password_confirmation"));
    formData.append("country_id", watch("country_id"));
    formData.append("region_id", watch("region_id"));
    formData.append("city_id", watch("city_id"));
    formData.append("code", otp);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/client/v1/auth/register`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response?.data?.success) {
        toast.success("Register successfully");
        navigate(PATH_PAGE.UserLogin);
      } else {
        toast.error(response?.data?.message);
      }
      setLoader(false);
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message);
      setLoader(false);
    }
  };

  const onSubmit = (data) => {
    registerUser();
    setLoader(true);
  };

  const goBack = () => {
    if (signUpSteps !== 1) {
      clearErrors();
      setSignUpSteps(signUpSteps - 1);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container className="mt-5">
        <Row className={`${classFlex} mb-5 text-center`}>
          <Col xs={6}>
            <SVG
            className="logo_size"
              src={`${path}logo.svg`}
              onClick={() => navigate(PATH_PAGE.clHome)}
            />{" "}
          </Col>
        </Row>
        <SignUpStepOne
          passwordType={passwordType}
          setPasswordType={setPasswordType}
          confirmPasswordType={confirmPasswordType}
          setConfirmPasswordType={setConfirmPasswordType}
          setSignUpSteps={setSignUpSteps}
          signUpSteps={signUpSteps}
          register={register}
          errors={errors}
          control={control}
          getValues={getValues}
          setValue={setValue}
          clearErrors={clearErrors}
          setUserVerifyOtp={setUserVerifyOtp}
          userVerifyOtp={userVerifyOtp}
          otp={otp}
          setOtp={setOtp}
          goBack={goBack}
          setCheckTerms={setCheckTerms}
          chekTerms={chekTerms}
        />
      </Container>
    </form>
  );
};
