import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import {
  BsGeoAlt,
  BsSuitHeart,
  BsTag,
  BsWallet,
  BsParagraph,
} from "react-icons/bs";
import { FaIndustry } from "react-icons/fa";
import { MdLanguage } from "react-icons/md";

import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { PaginationBar } from "../../components/sharedComponents/PaginationBar";
import { CallAPI } from "../../config/api/ApiConfig";
import { PATH_PAGE } from "../../routes/Paths";
import { Filter } from "../../components/sharedComponents/Filter";
import { useLocation } from "react-router-dom";

export const MentorsAll = () => {
  const { messages, locale } = useIntl();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsShow = 18;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [paginationHelper, setPaginationHelper] = useState({
    totalItems: 1,
    itemsPerPage: itemsShow,
    totalPages: Math.ceil(1 / itemsShow),
  });
  const [showDataHelper, setShowDataHelper] = useState({
    startIndex: (currentPage - 1) * paginationHelper?.itemsPerPage,
    endIndex: Math.min(
      (currentPage - 1) * paginationHelper?.itemsPerPage +
        paginationHelper?.itemsPerPage,
      paginationHelper.totalPages
    ),
  });

  const review = [
    {
      id: "asc",
      text: "least_rated",
    },
    {
      id: "desc",
      text: "top_rated",
    },
  ];
  const [selectedReview, setSelectedReview] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [price, setPrice] = useState({
    min_price: 1,
    max_price: 10000,
  });
  const [language, setLanguage] = useState("");
  const [industry, setIndustry] = useState("");
  const [tool, setTool] = useState("");
  const [experience, setExperience] = useState("");
  const [date, setDate] = useState("");

  const { data: category } = CallAPI({
    name: ["categories", id],
    url: "/categories/" + id,
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const { data: langs } = CallAPI({
    name: "languages",
    url: "/languages",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) =>
      res?.data?.data?.map((d) => ({
        id: d?.id,
        label: locale === "ar" ? d?.name_ar : d?.name_en,
      })),
  });

  const { data: industries } = CallAPI({
    name: "industries",
    url: "/industries",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const { data: tools } = CallAPI({
    name: "alltools",
    url: "/tools",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) =>
      res?.data?.data?.map((d) => ({
        id: d?.id,
        label: locale === "ar" ? d?.name_ar : d?.name_en,
      })),
  });

  const { data: experiences } = CallAPI({
    name: "experiences",
    url: "/mentor/v1/experiences",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) =>
      res?.data?.data?.map((d) => ({ id: d?.id, label: d?.name })),
  });

  const {
    data: tableData,
    refetch: callWithFilters,
    isLoading,
  } = CallAPI({
    name: "getAllMentors",
    url: "/mentors",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
    body: {
      review: selectedReview,
      orderBy: selectedStatus,
      ...price,
      language: language,
      industry: industry,
      tool: tool,
      experience: experience,
      date: date,
    },
    onSuccess: (res) => {
      setPaginationHelper({
        totalItems: res?.length,
        itemsPerPage: itemsShow,
        totalPages: Math.ceil(res?.length / itemsShow),
      });
      setShowDataHelper({
        startIndex: (currentPage - 1) * itemsShow,
        endIndex: Math.min(
          (currentPage - 1) * itemsShow + itemsShow,
          res?.length
        ),
      });
    },
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (currentPage) {
      setShowDataHelper({
        startIndex: (currentPage - 1) * itemsShow,
        endIndex: Math.min(
          (currentPage - 1) * itemsShow + itemsShow,
          tableData?.length
        ),
      });
    }
  }, [currentPage]);

  return (
    <div className="container  mentors-section" style={{ marginTop: "0px" }}>
      <div className="head_box text-center mb-5">
        <h2 className="FirstHeaderDefault"> {messages["all.mentors"]} </h2>
        <p className="heading_dis">{messages["mentors.info"]}</p>
      </div>
      <div className="row">
        <div className="col-md-9"></div>
        <div className="col-md-3">
          <Filter
            callWithFilters={callWithFilters}
            review={review}
            selectedReview={selectedReview}
            setSelectedReview={setSelectedReview}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            price={price}
            setPrice={setPrice}
            langs={langs}
            language={language}
            setLanguage={setLanguage}
            industries={industries}
            setIndustry={setIndustry}
            industry={industry}
            tools={tools}
            tool={tool}
            setTool={setTool}
            experiences={experiences}
            experience={experience}
            setExperience={setExperience}
            date={date}
            setDate={setDate}
          />
        </div>

        {tableData
          ?.slice(showDataHelper?.startIndex, showDataHelper?.endIndex)
          ?.map((men, index) => (
            <div className="border-style  col-12" key={men?.id}>
              <div className="card-holder">
                {/* <div className="scroller-mentor">
                  <div className="px-2">
                    <Col xs={12} className="card-header">
                      {men?.photo ? (
                        <img
                          src={men?.photo}
                          className="img"
                          alt="profileImage"
                        />
                      ) : (
                        <div className="round"></div>
                      )}
                      <div className="card-header__data">
                        <div className="card-header__data--price">
                          <BsWallet />
                          <b>{men?.session_price}</b> {messages["sar"]}
                        </div>
                        <div>
                          <BsSuitHeart /> {men?.rate}
                        </div>
                        <div className="card-header__data--reviews">
                          {men?.reviews_count} {messages["reviews"]} /{" "}
                          {men?.sessions_count} {messages["sessions"]}
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} className="card-data">
                      <h5
                        className="text-dark-blue"
                        onClick={() =>
                          navigate(`${PATH_PAGE.MentorDetails}?id=${men?.id}`)
                        }
                      >
                        {men?.first_name} {men?.last_name}
                      </h5>
                      <div>{men?.job_title}</div>
                      {men?.country?.name ? (
                        <div>
                          <BsGeoAlt /> {men?.country?.name}, {men?.city?.name}.
                        </div>
                      ) : null}

                      {men?.experiences?.length ? (
                        <div className="experiences-div">
                          <div className="rotated-tag">
                            <BsTag />
                          </div>
                          <div>
                            {men?.experiences?.map((d) => d?.name)?.join(", ")}
                          </div>
                        </div>
                      ) : null}

                      {men?.industry?.name ? (
                        <div className="experiences-div">
                          <div className="rotated-tag">
                            <FaIndustry />
                          </div>
                          <div>{men?.industry?.name}</div>
                        </div>
                      ) : null}

                      {men?.category?.name ? (
                        <div className="experiences-div">
                          <div className="rotated-tag">
                            <BsParagraph />
                          </div>
                          <div>{men?.category?.name}</div>
                        </div>
                      ) : null}

                      {men?.langs?.length ? (
                        <div className="experiences-div">
                          <div className="rotated-tag">
                            <MdLanguage />
                          </div>
                          <div>
                            {men?.langs
                              ?.map((d) =>
                                locale === "ar" ? d?.name_ar : d?.name_en
                              )
                              ?.join(", ")}
                          </div>
                        </div>
                      ) : null}

                      {men?.bio && <div className="bio">{men?.bio}</div>}
                    </Col>
                    <Col xs={12} className="card-info">
                      <div className="card-info__scroll">
                        {men?.tools?.map((tool) => (
                          <div className="card-info__scroll--skills">
                            {locale === "ar" ? tool?.name_ar : tool?.name_en}
                          </div>
                        ))}
                      </div>
                    </Col>
                  </div>
                </div>
                <Col xs={12} className="card-footer mt-3">
                  <div className="date-wrap">
                    <div className="flex-div2">
                      <div className="day-wrap">
                        <h5>
                          {new Date(men?.next_availability)?.toLocaleDateString(
                            "en-US",
                            {
                              weekday: "short",
                            }
                          )}
                        </h5>{" "}
                        <h6>
                          {new Date(men?.next_availability)?.toLocaleDateString(
                            "en-US",
                            {
                              day: "numeric",
                            }
                          )}
                        </h6>
                      </div>{" "}
                      <div>
                        <h4 className="dark">{messages["next_available"]}</h4>{" "}
                        <p>{men?.next_availability}</p>
                      </div>
                    </div>
                  </div>
                  <button
                    className="card-footer__btn"
                    onClick={() =>
                      navigate(`${PATH_PAGE.MentorDetails}?id=${men?.id}`)
                    }
                  >
                    {messages["book_session"]}
                  </button>
                </Col> */}
                {/* newwwwwwwwwwwwwwww */}
                <div className="scroller-mentor d-lg-flex justify-content-between">
                  <div className="d-flex w-75">
                    <div className="card-header">
                      {men?.photo ? (
                        <img
                          src={men?.photo}
                          className="img"
                          alt="profileImage"
                          onClick={() =>
                            navigate(`${PATH_PAGE.MentorDetails}?id=${men?.id}`)
                          }
                        />
                      ) : (
                        <div className="round"></div>
                      )}
                    </div>
                    <div>
                      <div className="card-data">
                        <h5
                          className="text-dark-blue"
                          onClick={() =>
                            navigate(`${PATH_PAGE.MentorDetails}?id=${men?.id}`)
                          }
                        >
                          {men?.first_name} {men?.last_name}
                        </h5>
                        <div>{men?.job_title}</div>
                        {men?.country?.name ? (
                          <div>
                            <BsGeoAlt /> {men?.country?.name}, {men?.city?.name}
                            .
                          </div>
                        ) : null}

                        {men?.experiences?.length ? (
                          <div className="experiences-div">
                            <div className="rotated-tag">
                              <BsTag />
                            </div>
                            <div>
                              {men?.experiences
                                ?.map((d) => d?.name)
                                ?.join(", ")}
                            </div>
                          </div>
                        ) : null}

                        {men?.industry?.name ? (
                          <div className="experiences-div">
                            <div className="rotated-tag">
                              <FaIndustry />
                            </div>
                            <div>{men?.industry?.name}</div>
                          </div>
                        ) : null}

                        {men?.category?.name ? (
                          <div className="experiences-div">
                            <div className="rotated-tag">
                              <BsParagraph />
                            </div>
                            <div>{men?.category?.name}</div>
                          </div>
                        ) : null}

                        {men?.langs?.length ? (
                          <div className="experiences-div">
                            <div className="rotated-tag">
                              <MdLanguage />
                            </div>
                            <div>
                              {men?.langs
                                ?.map((d) =>
                                  locale === "ar" ? d?.name_ar : d?.name_en
                                )
                                ?.join(", ")}
                            </div>
                          </div>
                        ) : null}

                        {men?.bio && <div className="bio">{men?.bio}</div>}
                      </div>
                      <div className="card-info">
                        <div className="card-info__scroll">
                          {men?.tools?.map((tool) => (
                            <div className="card-info__scroll--skills">
                              {locale === "ar" ? tool?.name_ar : tool?.name_en}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="card-header__data">
                      <div className="card-header__data--price">
                        <BsWallet />
                        <b>{men?.session_price}</b> {messages["sar"]}
                      </div>
                      <div>
                        <BsSuitHeart /> {men?.rate}
                      </div>
                      <div className="card-header__data--reviews">
                        {men?.reviews_count} {messages["reviews"]} /{" "}
                        {men?.sessions_count} {messages["sessions"]}
                      </div>
                    </div>
                    <div className="card-footer mt-2">
                      <div className="date-wrap">
                        <div className="flex-div2">
                          <div className="day-wrap">
                            <h5>
                              {new Date(
                                men?.next_availability
                              )?.toLocaleDateString("en-US", {
                                weekday: "short",
                              })}
                            </h5>{" "}
                            <h6>
                              {new Date(
                                men?.next_availability
                              )?.toLocaleDateString("en-US", {
                                day: "numeric",
                              })}
                            </h6>
                          </div>{" "}
                          <div>
                            <h4 className="dark">
                              {messages["next_availability"]}
                            </h4>{" "}
                            <p>{men?.next_availability}</p>
                          </div>
                        </div>
                      </div>
                      <button
                        className="card-footer__btn"
                        onClick={() =>
                          navigate(`${PATH_PAGE.MentorDetails}?id=${men?.id}`)
                        }
                      >
                        {messages["book_session"]}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center mt-3">
          <PaginationBar
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            totalPages={paginationHelper?.totalPages}
          />
        </div>
      </div>
    </div>
  );
};
