import React from "react";
import { TransferDesign } from "./TransferDesign";
import { ButtonCustom } from "../../../../components/sharedComponents/ButtonCustom";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { CallAPI } from "../../../../config/api/ApiConfig";
import { useLocation } from "react-router-dom";
import { Else, If, Then } from "react-if";

export const DetailsHeader = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { messages } = useIntl();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      cancellation_reason: "",
      booking_status: "canceled",
    },
  });

  const { refetch } = CallAPI({
    name: "cancelReservation",
    url: "/mentor/v1/bookings/" + id + "/update-booking-status",
    refetchOnWindowFocus: false,
    method: "put",
    body: {
      ...watch(),
    },
    enabled: false,
    onSuccess: (res) => {
      toast.success(messages["send_message_successfully"]);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { refetch: endBooking } = CallAPI({
    name: "linkedin",
    url: "/mentor/v1/bookings/" + id + "/end-booking",
    refetchOnWindowFocus: false,
    method: "put",
    enabled: false,
    onSuccess: (res) => {
      toast.success(messages["end_booking"]);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { data: singleBooking } = CallAPI({
    name: "singleBooking",
    url: "/mentor/v1/bookings/" + id,
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const onSubmit = (data) => {
    refetch();
  };

  const { data: cancellationReasonsMentor } = CallAPI({
    name: "cancellationReasonsMentor",
    url: "/cancellation_reasons",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  return (
    <div className="d-md-flex align-items-center justify-content-between">
      <div className="head_box d-flex align-items-center">
        <h2 className="heading_dash mb-1">
          {messages["reservations_details"]}
        </h2>

        <If condition={singleBooking?.booking_status == "completed"}>
          <Then>
            <TransferDesign
              className="green mx-2"
              text={messages[singleBooking?.booking_status]}
            />
          </Then>
          <Else>
            <If condition={singleBooking?.booking_status == "canceled"}>
              <Then>
                <TransferDesign
                  className="red mx-2"
                  text={messages[singleBooking?.booking_status]}
                />
              </Then>
            </If>
            <Else>
              <If condition={singleBooking?.booking_status == "scheduled"}>
                <Then>
                  <TransferDesign
                    className="orange mx-2"
                    text={messages[singleBooking?.booking_status]}
                  />
                </Then>
              </If>
            </Else>
          </Else>
        </If>
      </div>

      <div>
        <If
          condition={
            singleBooking?.booking_status != "complete" &&
            singleBooking?.booking_status != "canceled"
          }
        >
          <Then>
            {/* <button
              className="btn outLine_btn_red"
              variant="primary"
              onClick={handleShow}
            >
              {messages["cancel_reservation"]}
            </button> */}

            <ButtonCustom
              className="w_unset"
              text="reservation_ended"
              type="text"
              onClick={() => endBooking()}
            />
          </Then>
        </If>

        <Modal show={show} onHide={handleClose} className="">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton className="border-0 ">
              <Modal.Title className="cancelTitle">
                {messages["cancelation_reason"]}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <select
                className="form-select bg-transparent border-0"
                aria-label="Select Cancellatio Reason"
                {...register("reason_id", {
                  required: messages["cancellation_reason_required"],
                })}
                name="reason_id"
              >
                <option value="">{messages["choose"]}</option>
                {cancellationReasonsMentor?.map((cancellationReason, index) => (
                  <option value={cancellationReason?.id}>
                    {cancellationReason?.reason}
                  </option>
                ))}
              </select>
            </Modal.Body>
            <Modal.Footer className="border-0">
              <div className="row w-100">
                <div className="col-6">
                  <ButtonCustom
                    onClick={handleClose}
                    className="w-100"
                    text="send"
                    type="submit"
                  />
                </div>
                <div className="col-6 ">
                  <ButtonCustom
                    onClick={handleClose}
                    className="w-100 btn_dark"
                    text="cancel"
                    type="button"
                  />
                </div>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </div>
  );
};
