export const mentorPaths = {
  dashboard: "/dashboard",
  profile: "/profile",
  profileEdit: "/profile/profileEdit",
  publicProfile: "/publicProfile",
  publicProfileEdit: "/publicProfile/publicProfileEdit",
  publicEditReserv: "/publicProfile/publicEditReserv",
  publicEditResum: "/publicProfile/publicEditResum",
  about: "/about",
  reviews: "/publicProfile/reviews",
  report: "/publicProfile/reviews/report",
  client: "/publicProfile/reviews/client",
  privacyPolicy: "/privacyPolicy",
  resetPassword: "/reset-password",
  faq: "/faq",
  contact: "/contact",
  login: "/login",
  signUp: "/sign-up",
  wallet: "/wallet",
  walletWithdraw: "/wallet/withdraw",
  services: "/services",
  servicesDetails: "/servicesDetails",
  addNewService: "/services/add",
  reserv: "/reserv",
  reservDetails: "/reserv/reservDetails",
  chat: "/chat",
  notfication: "/notfication/setting",
  termsConditions: "/terms",
  signUpComplete: "/complete-register",
};

export const clientPaths = {
  clDemo: "/demo-url",
  chatUser: "/chatUser",
  categories: "/categories",
  UsertermsConditions: "/UsertermsConditions",
  UsertermsHome: "/User/terms/home",
  UserPrivacyPolicy: "/UserPrivacyPolicy",
  UserPrivacyPolicyDashboard: "/UserPrivacyPolicy/dashboard",
  UserFaq: "/UserFaq",
  UserFaqhome: "/User/faq/home",
  UserContact: "/UserContact",
  UserContactUs: "/User/contact/us",
  UserAbout: "/UserAbout",
  UserAboutDashboard: "/UserAbout/dashboard",
  CategoriesDetails: "/CategoriesDetails",
  mentors: "/mentors",
  MentorDetails: "/MentorDetails",
  UserLogin: "/UserLogin",
  UserSignUp: "/UserSignUp",
  UserResetPassword: "/UserResetPassword",
  UserReserv: "/UserReserv",
  userDashboard: "/userDashboard",
  Userprofile: "/Userprofile",
  UserprofileEdit: "/Userprofile/UserprofileEdit",
  favourites: "/favourites",
  booking: "/booking",
  subscrip: "/subscrip",
  detailsRating: "/UserReserv/detailsRating",
  detailsInfo: "/UserReserv/detailsInfo",
  meeting: "/UserReserv/booking/meeting",
  notficationUser: "/notfication/user/setting",
  walletUser: "/wallet/user",
  walletWithdrawUser: "/wallet/user/withdraw",
  walletCharge: "/wallet/user/charge",
};

export const PATH_PAGE = {
  notFound: "/*",
  clHome: "/",
  ...clientPaths,
  ...mentorPaths,
};
