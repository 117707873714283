import React from "react";
import { ProfileInfo } from "./ProfileInfo";
import { ProfileAvatar } from "./ProfileAvatar";
import { ProfileEdit } from "./profileEdit/ProfileEdit";

export const Profile = () => {
  return (
    <div className="profile">
      {/* <ProfileAvatar />
      <ProfileInfo /> */}
      <ProfileEdit />
    </div>
  );
};
