import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from "recharts";
import { CallAPI } from "../../config/api/ApiConfig";

const ReservBars = () => {
  const { data } = CallAPI({
    name: "reservChartUser",
    url: "/client/v1/dashboard-booking-statistics?days=7",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) =>
      res?.data?.data?.days?.map((d) => ({
        day: d?.day?.split("-")[2],
        count: d?.count,
      })),
  });

  return (
    <div className="chart_reserv">
      <BarChart width={500} height={300} data={data}>
        <CartesianGrid stroke="transparent" />
        <XAxis dataKey="day" stroke="#3A4E7B" />
        <YAxis
          stroke="#3A4E7B"
          domain={[0, 1]}
          ticks={[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]}
        />
        <XAxis dataKey="day" stroke="#3A4E7B"></XAxis>
        <YAxis stroke="#3A4E7B"></YAxis>
        <Bar
          dataKey="count"
          fill="url(#colorGradient)"
          barSize={20}
          radius={[15, 15, 0, 0]}
          label={false}
        />
        <defs>
          <linearGradient id="colorGradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#004F58" />
            <stop offset="100%" stopColor="#004F58" />
          </linearGradient>
        </defs>
      </BarChart>
    </div>
  );
};

export default ReservBars;
