import React from "react";
import SVG from "react-inlinesvg";
import {
  convertMinutesToHours,
  iconPath,
} from "../../../../constants/constants";
import { PaginationBar } from "../../../../components/sharedComponents/PaginationBar";
import { useIntl } from "react-intl";

export const Indvidual = ({
  mentorData,
  itemServices,
  handlePageChange,
  handleNavigation,
}) => {
  const path = iconPath();
  const { messages } = useIntl();

  return (
    <div className="row">
      {itemServices?.data.map((service, index) => (
        <div className="col-lg-4 col-md-6" key={index}>
          <div className="book_ment_view">
            <h3 onClick={() => handleNavigation(service?.id)}>
              {service?.title}
            </h3>
            <p className="service-data--info">{service?.description}</p>
            <ul>
              <li>
                <SVG src={`${path}p1.svg`} />
                {service?.priceWTax} {messages["SAR"]}
                <span>
                  {mentorData?.vat_included
                    ? "(" + messages["vat_included"] + ")"
                    : ""}
                </span>
              </li>
              <li>
                <SVG src={`${path}p2.svg`} />{" "}
                {convertMinutesToHours(service?.period)} {messages["hour"]}
              </li>
            </ul>
            <button
              className="btn btn_booking w-100"
              onClick={() => handleNavigation(service?.id)}
            >
              {messages["book_session"]}
            </button>
          </div>
        </div>
      ))}

      <div className="col-12 d-flex justify-content-center mt-3">
        <PaginationBar
          totalPages={itemServices?.last_page}
          currentPage={itemServices?.current_page}
          handlePageChange={handlePageChange}
        />{" "}
      </div>
    </div>
  );
};
