import { FirstHeader } from "../../components/sharedComponents/FirstHeader";
import React from "react";
import { ButtonCustom } from "../../components/sharedComponents/ButtonCustom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { CallAPI } from "../../config/api/ApiConfig";
import { useIntl } from "react-intl";

export const Contact = () => {
  const { messages } = useIntl();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      email: "",
      name: "",
      message: "",
    },
  });

  const { refetch } = CallAPI({
    name: "contactUs",
    url: "/mentor/v1/contact-us",
    refetchOnWindowFocus: false,
    method: "post",
    body: {
      ...watch(),
    },
    enabled: false,
    onSuccess: (res) => {
      toast.success(messages["send_message_successfully"]);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const onSubmit = (data) => {
    refetch();
  };

  return (
    <div className="container">
      <div className="text-center mb-5">
        <>
          <FirstHeader text={messages["contact_us"]} />
        </>

        <p className="heading_dis">{messages["send_your_message_now"]}</p>
      </div>
      <form className="row" onSubmit={handleSubmit(onSubmit)}>
        <div className="mentor-input col-md-6 mb-3">
          <label className="mentor-input__label">*{messages["name"]}</label>
          <input
            className="mentor-input__field"
            type="text"
            name="name"
            {...register("name", {
              required: messages["name_required"],
              minLength: {
                value: 3,
                message: `Name at least 3 characters`,
              },
            })}
          />
          {errors.name && (
            <span className="mentor-input__field-error">
              {errors.name?.message}
            </span>
          )}
        </div>

        <div className="mentor-input col-md-6 mb-3">
          <label className="mentor-input__label">*{messages["email"]}</label>
          <input
            className="mentor-input__field"
            type="text"
            name="email"
            {...register("email", {
              required: messages["email_required"],
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: messages["invalid_email_address"],
              },
            })}
          />
          {errors.email && (
            <span className="mentor-input__field-error">
              {errors.email?.message}
            </span>
          )}
        </div>

        <div className="col-md-12 mb-4 mt-2">
          <div className="position-relative">
            <label className="textAreaLabelDefault">
              *{messages["message"]}
            </label>
            <textarea
              className="textAreaDefault"
              name="message"
              {...register("message", {
                required: messages["message_required"],
                minLength: {
                  value: 10,
                  message: messages["message.min.char"],
                },
              })}
            ></textarea>

            {errors.message && (
              <span className="mentor-input__field-error-text-box">
                {errors.message?.message}
              </span>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <ButtonCustom
            className="w-100"
            text="send"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};
