import React, { useState } from "react";
import SVG from "react-inlinesvg";

import { Container, Row, Col } from "react-bootstrap";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FirstHeader } from "../../../components/sharedComponents/FirstHeader";
import { ButtonCustom } from "../../../components/sharedComponents/ButtonCustom";
import { MobileLogin } from "../Login/MobileLogin";
import { OtpConfirm } from "../Otpconfirm/OtpConfirm";
import { iconPath } from "../../../constants/constants";
import { PATH_PAGE } from "../../../routes/Paths";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CallAPI } from "../../../config/api/ApiConfig";
import { toast } from "react-toastify";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useIntl } from "react-intl";

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { messages } = useIntl();

  const [checkbox, setCheckbox] = useState("email");
  const [otp, setOtp] = useState("");
  const [phoneNum, setPhoneNumber] = useState("");
  const [nextScreen, setNextScreen] = useState(false);
  const [goOtpConfirm, setGoOtpConfirm] = useState(false);
  const [lastStepConfirmPass, setLastStepConfirmPass] = useState(false);
  const [passwordType, setPasswordType] = useState(true);
  const [confirmPasswordType, setConfirmPasswordType] = useState(true);

  const path = iconPath();
  const isTablet = useMediaQuery("(max-width:1024px)");
  const classFlex = "justify-content-center align-items-center d-flex";

  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      email: "",
      phone: "",
      password: "",
      password_confirmation: "",
    },
  });

  const { refetch: sendOtp } = CallAPI({
    name: "sendOtp",
    url: "/mentor/v1/auth/send-otp",
    refetchOnWindowFocus: false,
    method: "post",
    body: {
      username:
        checkbox === "mobile"
          ? watch("phone")?.slice(5)?.trim().replace(/\s/g, "")
          : watch("email"),
    },
    enabled: false,
    onSuccess: (res) => {
      if (res?.data?.success) {
        setGoOtpConfirm(true);
      } else {
        toast.error(res?.data?.message);
      }
      if (goOtpConfirm && !lastStepConfirmPass) {
        toast.success("The OTP has been sent again");
      }
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { refetch: verifyOtp, data } = CallAPI({
    name: "verifyOtp",
    url: "/mentor/v1/auth/verify-otp",
    refetchOnWindowFocus: false,
    method: "post",
    body: {
      otp: otp,
      username:
        checkbox === "mobile"
          ? watch("phone")?.slice(5)?.trim().replace(/\s/g, "")
          : watch("email"),
    },
    enabled: false,
    select: (res) => res?.data,
    onSuccess: (res) => {
      if (res?.status) {
        setLastStepConfirmPass(true);
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { refetch: restPassword } = CallAPI({
    name: "resetPassword",
    url: "/mentor/v1/auth/reset-password",
    refetchOnWindowFocus: false,
    method: "post",
    body: {
      otp_token: data?.data?.token,
      password: watch("password"),
      password_confirmation: watch("password_confirmation"),
    },
    enabled: false,
    select: (res) => res?.data,
    onSuccess: (res) => {
      if (res?.status) {
        setGoOtpConfirm(false);
        setLastStepConfirmPass(false);
        setNextScreen(false);
        navigate(PATH_PAGE?.login);
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const onSubmit = (data) => {
    restPassword();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container className="mt-5">
      <Row className={` mb-5 text-center justify-content-center`}>
          <Col xs={6}>
            <img src={`${path}logo.png`} alt="logo" className="logo_size" />
          </Col>
        </Row>
        {/* <Row className={`${classFlex} mb-5 text-center`}>
          <Col xs={6}>
            <SVG className="logo_size"
              src={`${path}logo.svg`}
              onClick={() => navigate(PATH_PAGE.clHome)}
            />{" "}
          </Col>
        </Row> */}
        {/* screens  before confirm OTP */}
        {!goOtpConfirm && !lastStepConfirmPass && (
          <Row className={`border w-${isTablet ? 100 : 50} mx-auto p-4`}>
            <Col>
              {!nextScreen ? (
                <>
                  <Row className="mb-4">
                    <Col xs={12} className={classFlex}>
                      <FirstHeader text="Reset Password" />
                    </Col>
                    <Col
                      xs={12}
                      className={`${classFlex} gap-5 justify-content-between my-3`}
                    >
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="email"
                          checked={checkbox === "email"}
                          onChange={(e) => setCheckbox(e?.target?.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          {messages["login.via.email"]}
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="mobile"
                          checked={checkbox === "mobile"}
                          onChange={(e) => setCheckbox(e?.target?.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          {messages["login.via.mobile"]}
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className={classFlex}>
                      <ButtonCustom
                        className="w-75"
                        text="next"
                        type="button"
                        onClick={() => setNextScreen(true)}
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row className="mb-4">
                    <Col xs={12} className={classFlex}>
                      <FirstHeader text="Reset Password" />
                    </Col>
                    <Col
                      xs={12}
                      className={`${classFlex} gap-5 justify-content-between`}
                    >
                      <div className="w-75 mx-auto text-center text-dark-blue ">
                        {messages["please.enter"]}{" "}
                        {messages[checkbox === "email" ? "email" : "mobile"]}{" "}
                        {messages["and.you.will"]} <b>{messages["OTP"]}</b>{" "}
                        {messages["restore.account"]}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col>
                      {checkbox === "mobile" ? (
                        <MobileLogin
                          phoneNum={phoneNum}
                          setPhoneNumber={setPhoneNumber}
                          control={control}
                        />
                      ) : (
                        <>
                          <div className="mentor-input">
                            <label className="mentor-input__label">
                              {messages["e_mail"]}
                            </label>
                            <input
                              className="mentor-input__field"
                              type="text"
                              name="email"
                              {...register("email", {
                                required: messages["email_required"],
                                pattern: {
                                  value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                  message: messages["email_invalid"],
                                },
                              })}
                            />
                            {errors.email && (
                              <span className="mentor-input__field-error">
                                {errors.email?.message}
                              </span>
                            )}
                          </div>{" "}
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className={classFlex}>
                      <ButtonCustom
                        className="w-100"
                        text="send.code"
                        type="button"
                        onClick={() =>
                          !errors?.email && !errors?.mobile && sendOtp()
                        }
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        )}
        {/* screen of OTP to confirm process */}
        {goOtpConfirm && !lastStepConfirmPass && (
          <>
            <OtpConfirm
              setOtp={setOtp}
              otp={otp}
              autoTrigger={true}
              callApi={verifyOtp}
              onClickSend={sendOtp}
            />
          </>
        )}

        {lastStepConfirmPass && (
          <Row className={`border w-${isTablet ? 100 : 50} mx-auto p-4`}>
            <Col xs={12} className={classFlex}>
              <FirstHeader text="Reset Password" />
            </Col>
            <Col
              xs={12}
              className={`${classFlex} gap-5 justify-content-between`}
            >
              <div className="w-75 mx-auto text-center text-dark-blue ">
                {messages['set.new.password']}
              </div>
            </Col>
            <Col md={12} className="mb-3 position-relative">
              <div className="mentor-input">
                <label className="mentor-input__label">
                  {messages["password"]}
                </label>
                <input
                  className="mentor-input__field"
                  type={passwordType ? "password" : "text"}
                  name="password"
                  {...register("password", {
                    required: messages["mobile_required"],
                    minLength: {
                      value: 6,
                      message: messages["mobile_min"],
                    },
                    // validate: (value) => isPasswordValid(value),
                  })}
                />
                {errors.password && (
                  <span className="mentor-input__field-error">
                    {errors.password?.message}
                  </span>
                )}
              </div>
              {passwordType ? (
                <BsEyeSlash
                  className="eye-password"
                  onClick={() => {
                    setPasswordType(!passwordType);
                  }}
                />
              ) : (
                <BsEye
                  className="eye-password"
                  onClick={() => {
                    setPasswordType(!passwordType);
                  }}
                />
              )}
            </Col>
            <Col md={12} className="mb-3 position-relative">
              <div className="mentor-input">
                <label className="mentor-input__label">
                  {messages["confirm_password"]}
                </label>
                <input
                  className="mentor-input__field"
                  type={confirmPasswordType ? "password" : "text"}
                  name="password_confirmation"
                  {...register("password_confirmation", {
                    required: messages["password_match"],
                    validate: (value) =>
                      value === getValues("password") ||
                      messages["password_not_match"],
                  })}
                />
                {errors.password_confirmation && (
                  <span className="mentor-input__field-error">
                    {errors.password_confirmation?.message}
                  </span>
                )}
              </div>

              {confirmPasswordType ? (
                <BsEyeSlash
                  className="eye-password"
                  onClick={() => {
                    setConfirmPasswordType(!confirmPasswordType);
                  }}
                />
              ) : (
                <BsEye
                  className="eye-password"
                  onClick={() => {
                    setConfirmPasswordType(!confirmPasswordType);
                  }}
                />
              )}
            </Col>
            <Col className={`${classFlex} mt-3`}>
              <ButtonCustom className="w-100" text="Save" type="submit" />
              {/* multiple setter is useless also */}
            </Col>
          </Row>
        )}
      </Container>
    </form>
  );
};
