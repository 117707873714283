import React from "react";
import { useIntl } from "react-intl";
import { CustomTime } from "./CustomTime";
import { RegularTime } from "./RegularTime";

export const PublicReserv = () => {
  const { messages } = useIntl();
  return (
    <div className="profile">
      <div className="container-fluid">
        <div className="head_box mb-4">
          <h2 className="heading_dash mb-1">
            {messages["edit_available_reservations"]}
          </h2>
          <p className="heading_dis">{messages["edit_available_info"]}</p>
        </div>

        <div className="row">
          <div className="col-md-7">
            <RegularTime />
          </div>
          {/* <div className="col-md-5">
            <CustomTime />
          </div> */}
        </div>
      </div>
    </div>
  );
};
