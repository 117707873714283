import React, { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PATH_PAGE } from "../../../routes/Paths";
import { OtpConfirm } from "../Otpconfirm/OtpConfirm";
import { iconPath } from "../../../constants/constants";
import { SignUpStepOne } from "./SignUpStepOne";
import { SignUpStepTwo } from "./SignUpStepTwo";
import { SignUpStepThree } from "./SignUpStepThree";
import { toast } from "react-toastify";
import axios from "axios";
import { AxiosContext } from "../../../config/api/AxiosConfig";
import { CallAPI } from "../../../config/api/ApiConfig";

export const SignUp = () => {
  const navigate = useNavigate();
  const { setLoader } = useContext(AxiosContext);

  const [hideOtp, setHideOtp] = useState(true);
  const [chekTerms, setCheckTerms] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [otp, setOtp] = useState("");
  const [signUpSteps, setSignUpSteps] = useState(1);
  const [passwordType, setPasswordType] = useState(true);
  const [userVerifyOtp, setUserVerifyOtp] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState(true);
  const classFlex = "justify-content-center align-items-center d-flex";
  const path = iconPath();
  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      phone: "",
      country_id: "",
      region_id: "",
      city_id: "",
    },
  });

  const { refetch } = CallAPI({
    name: "checkUnique",
    url: "/check-unique-fields",
    refetchOnWindowFocus: false,
    method: "post",
    body: {
      email: watch("email"),
      mobile: watch("phone")?.slice(5)?.trim().replace(/\s/g, ""),
    },
    enabled: false,
    onSuccess: (res) => {
      setSignUpSteps(2);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const registerUser = async () => {
    const formData = new FormData();
    const experiences = watch("experiences")?.map((d) => d?.id) || [];
    const tools = watch("tools")?.map((d) => d?.id) || [];
    const langs = watch("langs")?.map((d) => d?.id) || [];
    experiences?.forEach((experienceId, index) => {
      formData.append(`experiences[${index}]`, experienceId);
    });

    tools?.forEach((toolId, index) => {
      formData.append(`tools[${index}]`, toolId);
    });

    langs?.forEach((langId, index) => {
      formData.append(`langs[${index}]`, langId);
    });

    formData.append("first_name", watch("first_name"));
    formData.append("last_name", watch("last_name"));
    formData.append(
      "mobile",
      watch("phone")?.slice(5)?.trim().replace(/\s/g, "")
    );
    formData.append("email", watch("email"));
    formData.append("password", watch("password"));
    formData.append("password_confirmation", watch("password_confirmation"));
    formData.append("country_id", watch("country_id"));
    formData.append("region_id", watch("region_id"));
    formData.append("city_id", watch("city_id"));
    formData.append("job_title", watch("job_title"));
    formData.append("category_id", watch("category_id"));
    formData.append("personal_introduction", watch("personal_introduction"));
    formData.append("industry_id", watch("industry_id"));
    formData.append("linkedIn", watch("linkedIn"));
    if (watch("pdf")) {
      formData.append("resume_file", Object?.values(watch("pdf"))[0] || "");
    }
    formData.append("company_name", watch("company_name"));
    formData.append("code", otp);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/mentor/v1/auth/register`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.data?.status) {
        toast.success("Register successfully");
        const queryParams = {
          name: response?.data?.data?.first_name?.split('"')?.join(""),
        };
        const searchParams = new URLSearchParams(queryParams).toString();
        navigate(`${PATH_PAGE.signUpComplete}?${searchParams}`);
      } else {
        toast.error(response?.data?.message);
      }
      setLoader(false);
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message);
      setLoader(false);
    }
  };

  const onSubmit = (data) => {
    if (signUpSteps === 1 && userVerifyOtp) {
      refetch();
    }
    if (signUpSteps === 2) {
      setSignUpSteps(signUpSteps + 1);
    }
    if (signUpSteps === 3) {
      registerUser();
      setLoader(true);
    }
  };

  const goBack = () => {
    if (signUpSteps !== 1) {
      clearErrors();
      setSignUpSteps(signUpSteps - 1);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container className="mt-5">
        <Row className={`${classFlex} mb-5 text-center`}>
          <Col xs={6}>
            <SVG
            className="logo_size"
              src={`${path}logo.svg`}
              onClick={() => navigate(PATH_PAGE.clHome)}
            />{" "}
          </Col>
        </Row>
        {hideOtp ? (
          <>
            {signUpSteps === 1 && (
              <SignUpStepOne
                passwordType={passwordType}
                setPasswordType={setPasswordType}
                confirmPasswordType={confirmPasswordType}
                setConfirmPasswordType={setConfirmPasswordType}
                setSignUpSteps={setSignUpSteps}
                signUpSteps={signUpSteps}
                register={register}
                errors={errors}
                control={control}
                getValues={getValues}
                setValue={setValue}
                clearErrors={clearErrors}
                setUserVerifyOtp={setUserVerifyOtp}
                userVerifyOtp={userVerifyOtp}
                otp={otp}
                setOtp={setOtp}
                goBack={goBack}
                watch={watch}
              />
            )}
            {signUpSteps === 2 && (
              <SignUpStepTwo
                setSignUpSteps={setSignUpSteps}
                signUpSteps={signUpSteps}
                register={register}
                errors={errors}
                control={control}
                setValue={setValue}
                clearErrors={clearErrors}
                getValues={getValues}
                goBack={goBack}
              />
            )}
            {signUpSteps === 3 && (
              <SignUpStepThree
                setSignUpSteps={setSignUpSteps}
                signUpSteps={signUpSteps}
                register={register}
                errors={errors}
                setPdf={setPdf}
                pdf={pdf}
                chekTerms={chekTerms}
                setCheckTerms={setCheckTerms}
                goBack={goBack}
                setValue={setValue}
                watch={watch}
              />
            )}
          </>
        ) : (
          <OtpConfirm />
        )}
      </Container>
    </form>
  );
};
