import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Label } from "recharts";
import { CallAPI } from "../../config/api/ApiConfig";

const ReservBars = () => {
  const { data } = CallAPI({
    name: "reservChart",
    url: "/mentor/v1/dashboard-booking-statistics?days=7",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  // const data = [
  //   { days: "01", value: 20 },
  //   { days: "02", value: 30 },
  //   { days: "03", value: 40 },
  //   { days: "04", value: 40 },
  //   { days: "05", value: 50 },
  //   { days: "06", value: 60 },
  //   { days: "07", value: 60 },
  // ];

  return (
    <div className="chart_reserv">
      <BarChart width={500} height={300} data={data?.days}>
        <CartesianGrid stroke="transparent" />
        <XAxis dataKey="day" stroke="#3A4E7B" />
        <YAxis
          stroke="#3A4E7B"
          domain={[0, 1]}
          ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
        />
        <XAxis dataKey="day" stroke="#3A4E7B">
          {/* <Label value="X Axis Label" position="insideTop" offset={-10} /> */}
        </XAxis>
        <YAxis stroke="#3A4E7B">
          {/* <Label value="Y Axis Label" position="insideRight" angle={-90} offset={10} /> */}
        </YAxis>
        {/* <Tooltip />
        <Legend /> */}
        <Bar
          dataKey="count"
          fill="url(#colorGradient)"
          barSize={20}
          radius={[15, 15, 0, 0]}
          label={false}
        />
        <defs>
          <linearGradient id="colorGradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#004F58" />
            <stop offset="100%" stopColor="#004F58" />
          </linearGradient>
        </defs>
      </BarChart>
    </div>
  );
};

export default ReservBars;
