import React, { useContext, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { iconPath } from "../../../constants/constants";
import Tooltip from "@mui/material/Tooltip";
import { CallAPI } from "../../../config/api/ApiConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { LocaleContext } from "../../../config/localization/LanguageProvider";
import { PATH_PAGE } from "../../../routes/Paths";
import { PaginationBar } from "../../../components/sharedComponents/PaginationBar";
import {
  BsGeoAlt,
  BsSuitHeart,
  BsTag,
  BsWallet,
  BsParagraph,
} from "react-icons/bs";

export const CategoriesDetailsCard = ({
  mentors,
  setCurrentPage,
  currentPage,
  setShowDataHelper,
  itemsShow,
  showDataHelper,
  paginationHelper,
}) => {
  const path = iconPath();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { messages } = useIntl();
  const { locale } = useContext(LocaleContext);
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNavigation = (param) => {
    const queryParams = { id: param };
    const searchParams = new URLSearchParams(queryParams).toString();
    navigate(`${PATH_PAGE.MentorDetails}?${searchParams}`);
  };

  useEffect(() => {
    if (currentPage) {
      setShowDataHelper({
        startIndex: (currentPage - 1) * itemsShow,
        endIndex: Math.min(
          (currentPage - 1) * itemsShow + itemsShow,
          mentors?.length
        ),
      });
    }
  }, [currentPage]);

  const handleShareNavigation = (param) => {
    window.open(
      "https://twitter.com/share?url=" +
        `${PATH_PAGE.MentorDetails}?${searchParams}`,
      "_blank"
    );
  };

  return (
    <div className="CategoriesDetailsCard">
      {mentors
        ?.slice(showDataHelper?.startIndex, showDataHelper?.endIndex)
        ?.map((mentor, index) => (
          <div
            key={mentor?.id}
            className="col-md-12 d-lg-flex justify-content-between border rounded p-3 mb-3"
          >
            <div className="public_info  mb-3 d-flex cat_details_box">
              {/* <SVG src={`${path}avatar.svg`} className="avatar" /> */}
              <img src={mentor?.photo} className="avatar" alt="details" />

              <div>
                <div className="d-lg-flex justify-content-between mb-3">
                  <div>
                    <h3>
                      <button
                        className="link_cat"
                        onClick={() => handleNavigation(mentor?.id)}
                      >
                        {mentor?.first_name} {mentor?.last_name}
                      </button>
                    </h3>
                    <p>{mentor?.job_title}</p>
                    <ul>
                      <li>
                        <SVG src={`${path}l1.svg`} />
                        {mentor?.industry.name}
                      </li>
                      <li>
                        <SVG src={`${path}l2.svg`} />
                        {mentor?.city?.name}
                      </li>
                      {mentor?.langs.length > 0 && (
                        <li>
                          <SVG src={`${path}l3.svg`} />
                          {mentor?.langs.map((lang, index) => (
                            <span className="badg_cust badg_def">
                              {lang?.name}
                              {/* {locale === "ar"
                                ? lang?.name_ar + " "
                                : lang?.name_en + " "} */}
                            </span>
                          ))}
                        </li>
                      )}
                    </ul>
                  </div>

                  <div></div>
                </div>

                <div className="badg_wrapper">
                  {mentor?.tools.map((tool, index) => (
                    <span className="badg_cust badg_def" key={tool?.id}>
                      {tool?.name}
                      {/* {locale === "ar"
                        ? tool?.name_ar + " "
                        : tool?.name_en + " "} */}
                    </span>
                  ))}
                  {/* <span className="badg_cust badg_outline">Tools</span> */}
                </div>
                <div className="public_link row mt-3">
                  {mentor?.experiences.map((experience, index) => (
                    <div className="col-auto" key={experience?.id}>
                      <Tooltip
                        placement="top"
                        title={`${experience?.name}`}
                        arrow
                      >
                        <h4>{experience?.name}</h4>
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* SSSSSS
            <div>
              <div className="d-flex justify-content-between public_info fav_cat mb-3">
                <div>
                  <h3>{mentor?.rate}</h3>
                  <p>
                    {mentor?.reviews_count} {messages["reviews"]} /{" "}
                    {mentor?.sessions_count} {messages["sessions"]}
                  </p>
                </div>
                <div>
                  <button className="btn">
                    <SVG src={`${path}fav.svg`} />

                    {/* <FontAwesomeIcon icon={faHeart} /> */}
            {/* </button>

                  <button
                    className="btn"
                    onClick={() => handleShareNavigation(mentor?.id)}
                  > */}
            {/* <FontAwesomeIcon icon={faShare} /> */}
            {/* <SVG src={`${path}share.svg`} />
                  </button>
                </div>
              </div>
              <div className="border rounded p-2 d-flex  cal_cat public_info"> */}
            {/* <FontAwesomeIcon icon={faCalendarWeek} /> */}
            {/* <SVG src={`${path}calender.svg`} /> */}
            {/* <div className="day-wrap">
                  <h5>
                    {new Date(mentor?.next_availability)?.toLocaleDateString(
                      "en-US",
                      {
                        weekday: "short",
                      }
                    )}
                  </h5>{" "}
                  <h6>
                    {new Date(mentor?.next_availability)?.toLocaleDateString(
                      "en-US",
                      {
                        day: "numeric",
                      }
                    )}
                  </h6>
                </div>
                <div>
                  <h3 className="mb-0"> {messages["next_availability"]} </h3> */}
            {/* <p>sun, 15 oct, 1:30 PM GMT+3</p> */}
            {/* <p>{mentor?.next_availability}</p>
                </div>
              </div> */}
            {/* sssss */}
            {/* </div> */}

            <div>
              <div className=" justify-content-between public_info fav_cat mb-3">
                <div className="card-header__data">
                  <div className="card-header__data--price">
                    <BsWallet />
                    <b>
                      {messages["start_from"]} {mentor?.session_price}
                    </b>{" "}
                    {messages["sar"]}
                  </div>
                  <div>
                    <BsSuitHeart /> {mentor?.rate}
                  </div>
                  <div className="card-header__data--reviews">
                    {mentor?.reviews_count} {messages["reviews"]} /{" "}
                    {mentor?.sessions_count} {messages["sessions"]}
                  </div>
                </div>
                <div className="card-footer mt-2">
                  <div className="date-wrap">
                    <div className="flex-div2">
                      <div className="day-wrap">
                        <h5>
                          {new Date(
                            mentor?.next_availability
                          )?.toLocaleDateString("en-US", {
                            weekday: "short",
                          })}
                        </h5>{" "}
                        <h6>
                          {new Date(
                            mentor?.next_availability
                          )?.toLocaleDateString("en-US", {
                            day: "numeric",
                          })}
                        </h6>
                      </div>{" "}
                      <div>
                        <h4 className="dark">
                          {messages["next_availability"]}
                        </h4>{" "}
                        <p>{mentor?.next_availability}</p>
                      </div>
                    </div>
                  </div>
                  <button
                    className="card-footer__btn"
                    onClick={() =>
                      navigate(`${PATH_PAGE.MentorDetails}?id=${mentor?.id}`)
                    }
                  >
                    {messages["book_session"]}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      <div className="col-12 d-flex justify-content-center mt-3">
        <PaginationBar
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          totalPages={paginationHelper?.totalPages}
        />
      </div>
    </div>
  );
};
