import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const AxiosContext = createContext();
export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_DOMAIN}`,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Accept-Language": localStorage.getItem("locale"),
  },
});

axiosInstance.interceptors.response.use(
  function (response) {
    if (response.data.message && response.config.method !== "get") {
    }
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      localStorage.removeItem("user_data");
      window.location.href = "/login";
    } else if (error.response?.status === 500) {
    } else {
    }
    return Promise.reject(error.response);
  }
);

const AxiosProvider = ({ children }) => {
  const [loader, setLoader] = useState(false);
  const [requestCounter, setRequestCounter] = useState(0);

  const incrementRequestCounter = () => {
    setRequestCounter((prevCounter) => prevCounter + 1);
    setLoader(true);
  };

  const decrementRequestCounter = () => {
    setRequestCounter((prevCounter) => prevCounter - 1);
    if (requestCounter <= 1) {
      setLoader(false);
    }
  };

  const history = useNavigate();
  useEffect(() => {
    incrementRequestCounter();
    console.log("axiosRef");

    const axiosRef = axiosInstance.interceptors.request.use((config) => {
      if (
        !config.url?.includes("countries") &&
        !config.url?.includes("regions")
      ) {
        incrementRequestCounter();
      }
      return config;
    });
    return () => axiosInstance.interceptors.request.eject(axiosRef);
  }, [axiosInstance]);

  useEffect(() => {
    setTimeout(() => {
      decrementRequestCounter();
    }, 2000);

    const axiosRef = axiosInstance.interceptors.response.use(
      (res) => {
        if (
          !res?.config.url?.includes("countries") &&
          !res?.config.url?.includes("regions")
        ) {
          decrementRequestCounter();
        }
        return res;
      },
      (err) => {
        // logout when unauthreized
        if (+err?.response?.status === 401) {
          console.log(err?.response?.status);
          localStorage.removeItem("user_data");
          window.location.href = "/login";
        }
        if (
          !err?.config.url?.includes("countries") &&
          !err?.config.url?.includes("regions")
        ) {
          decrementRequestCounter();
        }
        return Promise.reject(err);
      }
    );
    return () => axiosInstance.interceptors.response.eject(axiosRef);
  }, [axiosInstance]);

  return (
    <AxiosContext.Provider value={{ loader, setLoader }}>
      {children}
    </AxiosContext.Provider>
  );
};

export default AxiosProvider;
