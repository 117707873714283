import React, { useState } from "react";
import SVG from "react-inlinesvg";
import {
  convertMinutesToHours,
  iconPath,
  yearMonthDay,
} from "../../../constants/constants";
import { Container } from "react-bootstrap";
import { ButtonCustom } from "../../../components/sharedComponents/ButtonCustom";
import DateTimePickerValue from "../../../components/sharedComponents/DateTimePickerValue";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { CallAPI } from "../../../config/api/ApiConfig";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { PATH_PAGE } from "../../../routes/Paths";
import { Else, If, Then } from "react-if";

const BookingUser = () => {
  const path = iconPath();
  const navigate = useNavigate();
  const { messages } = useIntl();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [timeValue, setTimeValue] = useState(null);
  const [timeSelected, setTimeSelected] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);

  const id = searchParams.get("id");
  /* -------------------------------------------------------------------------- */
  /*          used when redirect from reservation page inside dashbaord         */
  /* -------------------------------------------------------------------------- */
  const type = searchParams.get("type");
  /* -------------------------------------------------------------------------- */
  /*        used when enter from home page  to reserve package first time       */
  /* -------------------------------------------------------------------------- */
  const disable = searchParams.get("disable");

  const { data: bookServiceDetails } = CallAPI({
    name: "allReviews",
    url: "/mentor/v1/services/" + id,
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      // console.log(res);
    },
  });

  // const [dateValue, setDateValue] = useState(dayjs(new Date()));
  // if (bookServiceDetails?.service == "group_session") {
  const [dateValue, setDateValue] = useState(
    bookServiceDetails?.service == "group_session"
      ? dayjs(bookServiceDetails?.group_session_date)
      : dayjs(bookServiceDetails?.mentor?.next_availability)
  );
  // } else {
  //   const [dateValue, setDateValue] = useState(
  //     dayjs(bookServiceDetails?.mentor?.next_availability)
  //   );
  // }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      payment_type: type ? "package" : "",
      service_id: id,
    },
  });

  const { refetch } = CallAPI({
    name: "bookAservice",
    url: "/client/v1/bookings",
    refetchOnWindowFocus: false,
    method: "post",
    body: {
      service_id: watch("service_id"),
      date: yearMonthDay(dateValue),
      time: timeValue,
      payment_type: watch("payment_type"),
    },
    enabled: false,
    onSuccess: (res) => {
      toast.success(messages["sent_booking_successfully"]);
      if (bookServiceDetails?.service == "private_sessions_package")
        navigate(PATH_PAGE.subscrip);
      else navigate(PATH_PAGE.UserReserv);
    },
    onError: (err) => {
      if (err?.response?.status === 405) {
        toast.error(err?.response?.data?.message);
        window.location.replace(err?.response?.data?.data);
      } else {
        toast.error(err?.response?.data?.message);
      }
    },
  });

  const onSubmit = (data) => {
    refetch();
  };

  const { isLoading } = CallAPI({
    name: ["timeSlotsBooking", dateValue],
    url: `/client/v1/available-time/${id}?day=${yearMonthDay(dateValue)}`,
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.time_slots,
    onSuccess: (res) => {
      setTimeValue(null);
      setTimeSelected(null);
      setTimeSlots(res);
    },
    onError: (err) => {
      setTimeSlots([]);
      setTimeSelected(null);
      setTimeValue(null);
    },
  });


  return (
    <Container className="booking_info mentors-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="book_card d-md-flex border p-3 rounded-4 justify-content-between">
              <div>
                <h2>{bookServiceDetails?.name}</h2>
                <h3>
                  {bookServiceDetails?.mentor?.photo ? (
                    <img
                      src={bookServiceDetails?.mentor?.photo}
                      className="avtar"
                      alt="ava"
                    />
                  ) : (
                    <div className="avatar"></div>
                  )}
                  {bookServiceDetails?.mentor?.first_name}{" "}
                  {bookServiceDetails?.mentor?.last_name}
                </h3>
                <ul>
                  <li>
                    {" "}
                    <SVG src={`${path}b1.svg`} />
                    {bookServiceDetails?.mentor?.category?.name}
                  </li>
                  <li>
                    {" "}
                    <SVG src={`${path}b2.svg`} />
                    {/* {bookServiceDetails?.date} */}
                    {bookServiceDetails?.mentor?.next_availability}
                  </li>
                  <li>
                    {" "}
                    <SVG src={`${path}p2.svg`} />{" "}
                    {convertMinutesToHours(bookServiceDetails?.period)}{" "}
                    {messages["hour"]} {""}
                    <If
                      condition={
                        bookServiceDetails?.service ==
                        "private_sessions_package"
                      }
                    >
                      <Then>
                        - {messages["with_serivce_num"]} (
                        {bookServiceDetails?.session_number}){" "}
                        {messages["session"]}
                      </Then>
                    </If>
                  </li>
                  <li>
                    <If
                      condition={
                        bookServiceDetails?.service ==
                        "private_sessions_package"
                      }
                    >
                      <Then>
                        <SVG src={`${path}b3.svg`} />{" "}
                        {messages["package_validity"]} (
                        {bookServiceDetails?.package_validity}{" "}
                        {messages["month"]})
                      </Then>
                    </If>
                  </li>
                </ul>
              </div>
              <div>
                <p>{messages["session_price"]}</p>
                <h2>
                  {bookServiceDetails?.priceWTax} <span>{messages["SAR"]}</span>
                  <span>
                    {bookServiceDetails?.vat_included
                      ? "(" + messages["vat_included"] + ")"
                      : ""}
                  </span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="book_with  p-3">
              <div className="col-lg-12">
                {" "}
                <h2>
                  {messages["book_session"]} {bookServiceDetails?.name}{" "}
                  {messages["with"]} {bookServiceDetails?.mentor?.first_name}{" "}
                  {bookServiceDetails?.mentor?.last_name}
                </h2>
                {/* <label>{messages["session_time"]}</label>
                  <div className="input-group mb-3 ">
                    <TimePickerComponent
                      timeValue={timeValue}
                      setTimeValue={setTimeValue}
                    /> */}
                {/* <input
                      type="text"
                      className="form-control bg-transparent border-0"
                      value={"1:30 PM (+3)KSA"}
                    />

                    <span className="input-group-text bg-transparent border-0">
                      <SVG src={`${path}b4.svg`} />
                    </span> */}
              </div>
              {/* mayada it is form not Form from react-bootstrap to make validation work correctly */}
              <form className="row" onSubmit={handleSubmit(onSubmit)}>
                <input
                  type="hidden"
                  name="service_id"
                  {...register("service_id")}
                />

                <If condition={bookServiceDetails?.service != "group_session"}>
                  <Then>
                    {!disable && (
                      <div className="col-3">
                        <label>{messages["session_date"]}</label>
                        <div className="input-group mb-3 date-picker">
                          <DateTimePickerValue                            
                            dateValue={dateValue}
                            setDateValue={setDateValue}                            
                          />
                        </div>
                      </div>
                    )}

                    {!disable && (
                      <div className="col-lg-12">
                        <label>{messages["session_time"]}</label>
                        <div className="scroller">
                          {!isLoading &&
                            (timeSlots?.length ? (
                              <div className="scroller">
                                {timeSlots?.map((timeSlot, index) => (
                                  <div
                                    className={`${"time"} ${
                                      timeSlot?.start_time === timeSelected &&
                                      "selected"
                                    }`}
                                    key={timeSlot?.start_time + index}
                                    onClick={() => {
                                      setTimeSelected(timeSlot?.start_time);
                                      const [hours, minutes] =
                                        timeSlot?.start_time
                                          .split(":")
                                          .slice(0, 2);
                                      const formattedTime = `${hours}:${minutes}`;
                                      setTimeValue(formattedTime);
                                    }}
                                  >
                                    {timeSlot?.start_time} -{" "}
                                    {timeSlot?.end_time}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <span className="text-dark-blue py-2">
                                {messages["mentor.has.no.time"]}
                              </span>
                            ))}
                        </div>
                      </div>
                    )}
                  </Then>
                </If>

                <div className="col-lg-4">
                  <label>{messages["payment_method"]}</label>

                  <div className="input-group mb-3">
                    <span className="input-group-text bg-transparent border-0">
                      <SVG src={`${path}b5.svg`} />
                    </span>

                    <select
                      className="form-select bg-transparent border-0"
                      //   className="sign-select selectInputDefault"
                      aria-label="Select Payment Method"
                      {...register("payment_type", {
                        required: messages["specialization_required"],
                      })}
                      name="payment_type"
                      disabled={type}
                    >
                      <option value="">{messages["choose"]}</option>
                      <option value="online_payment">
                        {messages["online_payment"]}
                      </option>
                      <option value="wallet">{messages["wallet"]}</option>
                      {type && (
                        <option value="package">
                          {messages["choose.package"]}
                        </option>
                      )}
                    </select>
                    {errors.payment_type && (
                      <span className="mentor-input__field-error">
                        {messages["choose_payment_method"]}
                      </span>
                    )}
                  </div>
                </div>

                <If condition={bookServiceDetails?.service == "group_session"}>
                  <Then>
                    <div>
                      <ButtonCustom
                        className="w-a mt-4"
                        text="go_to_check_out"
                        type="submit"
                      />
                    </div>
                  </Then>
                  <Else>
                    <Then>
                      <div>
                        <ButtonCustom
                          className="w-a mt-4"
                          text="go_to_check_out"
                          type="submit"
                          disabled={!disable && !timeValue}
                        />
                      </div>
                    </Then>
                  </Else>
                </If>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default BookingUser;
