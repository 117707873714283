import { Tab } from "bootstrap";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { CallAPI } from "../../../config/api/ApiConfig";
import { PATH_PAGE } from "../../../routes/Paths";
import { toast } from "react-toastify";
import { iconPath, calculateTop } from "../../../constants/constants";
import SVG from "react-inlinesvg";
import { Row, Col } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export const ServicesDetails = () => {
  const path = iconPath();

  const { messages, locale } = useIntl();

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const { data: data, refetch: getServices } = CallAPI({
    name: "serviesData",
    url: `/mentor/v1/packages/${id}`,
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });
  console.log(data);

  const handleNavigation = (param) => {
    const queryParams = { id: param };
    const searchParams = new URLSearchParams(queryParams).toString();
    navigate(`${PATH_PAGE.reservDetails}?${searchParams}`);
  };

  const tableHeader = [
    messages["company_name"],
    messages["company_active"],
    messages["company_completed"],
  ];

  return (
    <>
      <div className="faq_wrapper mt-5 services_wrapper container-fluid">
        <div className="row">
          <div className="col-12 mb-3">
            <div className="prof_card ">
              <div className="row">
                <div className="col-md-2">
                  <div className="public_avatar">
                    {data?.package?.image && (
                      <img src={data?.package?.image} alt="profile-img" />
                    )}
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="public_info">
                    <h3>{data?.package?.name}</h3>
                    <p>{data?.package?.about}</p>
                    <div className="d-lg-flex">
                      <ul>
                        <li>
                          <SVG src={`${path}l1.svg`} />
                          {data?.package?.category?.name}
                        </li>
                        <li>
                          <SVG src={`${path}l1.svg`} />
                          {data?.package?.expiry_date}
                        </li>
                        <li>
                          {messages["employees"]} :
                          {data?.package?.number_of_employees}
                        </li>
                        <li>
                          {messages["sessions"]} :
                          {data?.package?.number_of_sessions}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
