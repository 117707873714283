import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col, Form, Dropdown, Badge } from "react-bootstrap";
import { BsGlobe, BsSearch, BsBell, BsGear } from "react-icons/bs";
import { AuthContext } from "../../auth/AuthProvider";
import SVG from "react-inlinesvg";
import { toast } from "react-toastify";
import { ButtonCustom } from "../sharedComponents/ButtonCustom";

import { LocaleContext } from "../../config/localization/LanguageProvider";
import { iconPath } from "../../constants/constants";
import { useIntl } from "react-intl";
import { CallAPI } from "../../config/api/ApiConfig";
import { useNavigate } from "react-router-dom";
import { PATH_PAGE } from "../../routes/Paths";
import { useMediaQuery } from "@mui/material";
import { useQueryClient } from "react-query";
import Pusher from "pusher-js";

export const NavbarMentor = () => {
  const isSmallScreen = useMediaQuery("(max-width:700px)");
  const { user } = useContext(AuthContext);
  const { locale, setLocale } = useContext(LocaleContext);
  const navigate = useNavigate();
  const { messages } = useIntl();
  const queryClient = useQueryClient();

  const path = iconPath();

  const [notifications, setNotifications] = useState([]);

  CallAPI({
    name: "getNotifications",
    url: `/${
      user?.data?.type === "client" ? "client" : "mentor"
    }/v1/notifications`,
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data || res,
    onSuccess: (res) => {
      console.log(res);
      setNotifications(res?.notifications);
    },
    onError: (err) => {
      console.log(err);
      toast.error(err?.response?.data?.message);
    },
  });

  useEffect(() => {
    const pusher = new Pusher("87639c06bf9f7a1e1934", {
      cluster: "eu",
    });
    const user = JSON.parse(localStorage.getItem("user_data")).data;
    const userId = user && user.id;

    const channel = pusher.subscribe(`notification.${userId}`);

    const handleNotificationEvent = (data) => {
      console.log("handleNotificationEvent", data);

      if (data.notification) {
        playNotificationSound();
        queryClient.setQueryData("getNotifications", (prevData) => {
          console.log("prevData", prevData);
          const oldData = prevData?.data?.data || prevData;
          return {
            count: oldData?.count + 1,
            notifications: [data.notification, ...oldData?.notifications],
          };
        });
      }
    };

    channel.bind("NotificationEvent", handleNotificationEvent);

    return () => {
      channel.unbind("NotificationEvent", handleNotificationEvent);
      pusher.disconnect();
    };
  }, [queryClient]);

  const playNotificationSound = () => {
    const audio = new Audio("/notification.mp3");
    audio.play();
  };

  return (
    <Container fluid className="mentor-navbar">
      <Row className="mentor-navbar__row">
        <Col className="mentor-navbar__row-info">
          <p className="mentor-navbar__row-info__name">
            {messages["hello"]} {user?.data?.first_name}
          </p>
          <span className="mentor-navbar__row-info__path">
            {messages["start_new_pathway_to_new_experience"]}
          </span>
        </Col>
        <Col
          className="mentor-navbar__row-actions"
          style={{ flexDirection: isSmallScreen ? "column" : "row" }}
        >
          {user?.data?.type === "client" && (
            <ButtonCustom
              text="book_session"
              className="w-auto"
              onClick={() => navigate(PATH_PAGE.mentors)}
            />
          )}
          <div className="d-flex justify-content-center">
            {/* {user && (
  <button
    className="buttonCustomDefault w-auto btn-mentor py-1 px-3 h-auto"
    onClick={() => navigate(PATH_PAGE.mentors)}
  >
    {messages["book_session"]}
  </button>
)} */}
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                className="mentor-navbar__row-actions__notifications-dropdown"
                style={{ border: "none", boxShadow: "none" }}
              >
                <BsBell size={22} />
                {/* {notifications.length > 0 && (
                <Badge pill variant="danger">
                  {notifications.length}
                </Badge>
              )} */}
              </Dropdown.Toggle>

              <Dropdown.Menu align="right" className="notification-dropdown">
                <div className="header-dropdown">
                  <div>{messages["notifications"]}</div>
                  <div>
                    <BsGear
                      onClick={() =>
                        navigate(
                          `${
                            user?.data?.type === "client"
                              ? PATH_PAGE.notficationUser
                              : PATH_PAGE.notfication
                          }`
                        )
                      }
                    />
                  </div>
                </div>
                {notifications?.length === 0 && (
                  <Dropdown.Item disabled className="disabled">
                    {messages["no.notifications"]}
                  </Dropdown.Item>
                )}
                {notifications?.map((notification, index) => (
                  <Dropdown.Item key={index}>
                    <div className="data-dropdown">
                      <div>
                        {notification?.sender?.photo ? (
                          <img
                            src={`${path}logo.svg`}
                            className="notify-image"
                            alt="logo-notify"
                          />
                        ) : (
                          <div className="rounded-div"></div>
                        )}
                      </div>
                      <div>
                        <p>{notification?.title}</p>
                      </div>
                    </div>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            {/* <div className="search-container">
              <input
                type="text"
                className="search-input"
                placeholder={messages["Search_for_new_skill"]}
              />
              <BsSearch className="search-icon" />
            </div> */}
          </div>
          <div className="language-selection">
            <BsGlobe />
            <Form.Select
              onChange={(e) => {
                setLocale(e.target.value);
              }}
              value={locale}
            >
              <option value="en">{messages["english"]}</option>
              <option value="ar">{messages["arabic"]}</option>
            </Form.Select>
          </div>
        </Col>
        <hr className="hr-header" />
      </Row>
    </Container>
  );
};
