import React, { useContext } from "react";
import { FirstHeader } from "../../components/sharedComponents/FirstHeader";
import { CallAPI } from "../../config/api/ApiConfig";
import { LocaleContext } from "../../config/localization/LanguageProvider";

export const TermsConidiotns = () => {
  const { locale } = useContext(LocaleContext);

  const { data: termsConditions } = CallAPI({
    name: "termsConditions",
    url: "/client/v1/page/terms_conditions",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  return (
    <div className="terms p-0">
      <div className="container">
        <>
          <FirstHeader
            className="terms__header"
            text={
              locale === "ar"
                ? termsConditions?.name_ar
                : termsConditions?.name_en
            }
          />
        </>

        <div
          className="terms__content"
          dangerouslySetInnerHTML={{
            __html:
              locale === "ar"
                ? termsConditions?.body_ar
                : termsConditions?.body_en,
          }}
        />
      </div>
    </div>
  );
};
