import { useIntl } from "react-intl";

export const FirstHeader = ({
  className = "FirstHeaderDefault",
  text = "",
  onClick = () => {},
  ...props
}) => {
  const { messages } = useIntl();
  return (
    <h1
      className={`FirstHeaderDefault ${className}`}
      {...props}
      onClick={onClick}
    >
      {messages[text]}
    </h1>
  );
};
