import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useIntl } from "react-intl";

function CalendarComponent({
  tileDisabled = () => false,
  showNavigation = true,
  selectedDate,
  setSelectedDate,
}) {
  const { locale } = useIntl();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div>
      <div>
        <Calendar
          locale={locale}
          //   showDoubleView={true}
          value={selectedDate}
          onChange={handleDateChange}
          //   showWeekNumbers
          showNeighboringMonth={false}
          //   minDate={new Date()}
          tileDisabled={tileDisabled}
          showNavigation={showNavigation}
        />
      </div>
    </div>
  );
}

export default CalendarComponent;
