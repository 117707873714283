import React from "react";
import { ProfileInfo } from "./ProfileInfo";
import { ProfileAvatar } from "./ProfileAvatar";
import { InterestsUser } from "./InterestsUser";

export const UserProfile = () => {
  return (
    <div className="profile">
      <ProfileAvatar />
      <ProfileInfo />
      <InterestsUser />
    </div>
  );
};
