import { Button } from "@mui/material";
import { useIntl } from "react-intl";

export const ButtonCustom = ({
  className = "buttonCustomDefault",
  type = "button",
  onClick = () => {},
  text = "button",
  style = {},
  variant = "success",
  fullWidth = "false",
  hasIcon = false,
  ...props
}) => {
  const { messages } = useIntl();
  
  return (
    <Button
      style={style}
      type={type}
      variant={variant}
      className={`buttonCustomDefault ${className}`}
      onClick={onClick}
      {...props}
    >
      {hasIcon && hasIcon}
      {messages[text]}
    </Button>
  );
};
