import React from "react";
import { DetailsHeading } from "./DetailsHeading";
import { DetailsBody } from "./DetailsBody";
import { CallAPI } from "../../../../config/api/ApiConfig";
import { useLocation } from "react-router-dom";

export const UserDetailsInfo = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const { data: clientReservation, refetch: callDetails } = CallAPI({
    name: "userdetailsInfo",
    url: "/mentor/v1/bookings/" + id,
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  return (
    <div className="Destails_rating">
      <DetailsHeading
        clientReservation={clientReservation}
        id={id}
        callDetails={callDetails}
      />
      <DetailsBody clientReservation={clientReservation} id={id} />
    </div>
  );
};
