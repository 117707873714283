import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { iconPath } from "../../../constants/constants";
import { Filter } from "../../../components/sharedComponents/Filter";
import { CategoriesDetailsCard } from "./CategoriesDetailsCard";
import { CallAPI } from "../../../config/api/ApiConfig";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

export const CategoriesDetails = () => {
  const path = iconPath();
  const { messages, locale } = useIntl();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsShow = 5;
  const [paginationHelper, setPaginationHelper] = useState({
    totalItems: 1,
    itemsPerPage: itemsShow,
    totalPages: Math.ceil(1 / itemsShow),
  });
  const [showDataHelper, setShowDataHelper] = useState({
    startIndex: (currentPage - 1) * paginationHelper?.itemsPerPage,
    endIndex: Math.min(
      (currentPage - 1) * paginationHelper?.itemsPerPage +
        paginationHelper?.itemsPerPage,
      paginationHelper.totalPages
    ),
  });

  const review = [
    {
      id: "asc",
      text: "least_rated",
    },
    {
      id: "desc",
      text: "top_rated",
    },
  ];
  const [selectedReview, setSelectedReview] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [price, setPrice] = useState({
    min_price: 1,
    max_price: 10000,
  });
  const [language, setLanguage] = useState("");
  const [industry, setIndustry] = useState("");
  const [tool, setTool] = useState("");
  const [experience, setExperience] = useState("");
  const [date, setDate] = useState("");

  const { data: category } = CallAPI({
    name: ["categories", id],
    url: "/categories/" + id,
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const { data: langs } = CallAPI({
    name: "languages",
    url: "/languages",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) =>
      res?.data?.data?.map((d) => ({
        id: d?.id,
        label: d?.name,
        // label: locale === "ar" ? d?.name_ar : d?.name_en,
      })),
  });

  const { data: industries } = CallAPI({
    name: "industries",
    url: "/industries",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const { data: tools } = CallAPI({
    name: "alltools",
    url: "/tools",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) =>
      res?.data?.data?.map((d) => ({
        id: d?.id,
        label: d?.name,
        // label: locale === "ar" ? d?.name_ar : d?.name_en,
      })),
  });

  const { data: experiences } = CallAPI({
    name: "experiences",
    url: "/mentor/v1/experiences",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) =>
      res?.data?.data?.map((d) => ({ id: d?.id, label: d?.name })),
  });

  const {
    data: mentors,
    refetch: callWithFilters,
    isLoading,
  } = CallAPI({
    name: ["categoriesDetails", id],
    url: "/categories/" + id + "/mentors",
    refetchOnWindowFocus: false,
    enabled: true,
    method: "post",
    select: (res) => res?.data?.data,
    body: {
      review: selectedReview,
      orderBy: selectedStatus,
      ...price,
      language: language,
      industry: industry,
      tool: tool,
      experience: experience,
      date: date,
    },
    onSuccess: (res) => {
      setPaginationHelper({
        totalItems: res?.length,
        itemsPerPage: itemsShow,
        totalPages: Math.ceil(res?.length / itemsShow),
      });
      setShowDataHelper({
        startIndex: (currentPage - 1) * itemsShow,
        endIndex: Math.min(
          (currentPage - 1) * itemsShow + itemsShow,
          res?.length
        ),
      });
      setCurrentPage(1);
    },
  });

  return (
    <div className="categoris_details-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12 d-lg-flex justify-content-between align-items-center mb-4 card-holder">
            <div className="cat_head d-flex">
              {/* <SVG src={`${path}lang4.svg`} /> */}
              <img
                src={category ? category?.image : `${path}lang4.svg`}
                alt="cate"
                width={80}
              />
              <div>
                <h2>{category ? category?.name : messages["all"]}</h2>
                <p>
                  {category
                    ? category?.mentor_count + " " + messages["mentor"]
                    : ""}
                </p>
              </div>
            </div>

            <Filter
              callWithFilters={callWithFilters}
              review={review}
              selectedReview={selectedReview}
              setSelectedReview={setSelectedReview}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              price={price}
              setPrice={setPrice}
              langs={langs}
              language={language}
              setLanguage={setLanguage}
              industries={industries}
              setIndustry={setIndustry}
              industry={industry}
              tools={tools}
              tool={tool}
              setTool={setTool}
              experiences={experiences}
              experience={experience}
              setExperience={setExperience}
              date={date}
              setDate={setDate}
            />
          </div>

          {!isLoading && mentors?.length ? (
            <CategoriesDetailsCard
              mentors={mentors}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              setShowDataHelper={setShowDataHelper}
              itemsShow={itemsShow}
              showDataHelper={showDataHelper}
              paginationHelper={paginationHelper}
            />
          ) : null}
          {!isLoading && !mentors?.length && (
            <div className="no-data-found">{messages["no.data.found"]}</div>
          )}
        </div>
      </div>
    </div>
  );
};
