import React from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { useIntl } from "react-intl";
import { FirstHeader } from "../../../../components/sharedComponents/FirstHeader";
import { WithdrawBank } from "./WithdrawBank";
import { WithdrawStc } from "./WithdrawStc";

export const WalletWithdrawData = () => {
  const { messages } = useIntl();
  return (
    <div className="faq_wrapper wallet_wrapper mt-5 container-fluid">
      <Row>
        <Col xs={12} className="mb-2">
          <FirstHeader text="Withdraw form" className="heading_dash " />
        </Col>
        <Col xs={12}>
          <Tabs
            defaultActiveKey="bank"
            id="fill-tab-example"
            fill
            className="mb-3"
          >
            <Tab eventKey="bank" title={messages["wallet.bank.account"]}>
              <WithdrawBank />
            </Tab>
            <Tab eventKey="stc" title={messages["wallet.stc.account"]}>
              <WithdrawStc />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};
