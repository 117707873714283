import React from "react";
import Chart from "./Chart";
import Statistics from "./Statistics";
import { CallAPI } from "../../config/api/ApiConfig";
import { toast } from "react-toastify";

export const UserrDashboard = () => {
  // const { data: chkIfGoogleLoged } = CallAPI({
  //   name: "chkIfGoogleLoged",
  //   url: "/client/v1/if_google_auth",
  //   refetchOnWindowFocus: false,
  //   select: (res) => res?.data?.message,
  //   onSuccess: (res) => {
  //     if(res){
  //       toast.success(res);
  //     }
  //   }
  // });

  return (
    <>
      <div className="row">
        {/* <p className="red">{chkIfGoogleLoged}</p> */}
      </div>
      <Chart />
      <Statistics />
    </>
  );
};
