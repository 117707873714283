import React from "react";
import { Row, Col } from "react-bootstrap";
import { ButtonCustom } from "../../../components/sharedComponents/ButtonCustom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TransferDesign } from "./TransferDesign";
import { calculateTop } from "../../../constants/constants";
import { PATH_PAGE } from "../../../routes/Paths";
import { useNavigate } from "react-router-dom";
import { CallAPI } from "../../../config/api/ApiConfig";
import { useIntl } from "react-intl";

export const WalletTable = () => {
  const navigate = useNavigate();
  const { messages } = useIntl();

  const { data: walletHistory } = CallAPI({
    name: "walletHistory",
    url: "/mentor/v1/wallets/transactions",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const tableHeader = [
    // messages["wallet.transaction_name"],
    messages["date"],
    messages["wallet.amount"],
    messages["wallet.due_sessions"],
    messages["wallet.sessions_no"],
    messages["status"],
  ];
  // const tableData = [
  //   {
  //     id: 0,
  //     Code: "salaray",
  //     date: "muData",
  //     reason: "dasdsa",
  //     amount: "amomo",
  //     type: <TransferDesign className="green" text="Transfer In" />,
  //   },
  //   {
  //     id: 0,
  //     Code: "salaray",
  //     date: "muData",
  //     reason: "dasdsa",
  //     amount: "amomo",
  //     type: <TransferDesign className="red" text="Transfer In long" />,
  //   },
  // ];

  return (
    <div className="mt-5 wallet-table container-fluid">
      <Row>
        {/* <Col className="wallet-table--btn" xs={12}>
          <ButtonCustom
            text="wallet.withdrawal"
            onClick={() => navigate(PATH_PAGE?.walletWithdraw)}
          />
        </Col> */}
        <Col xs={12}>
          <TableContainer className="parent-table">
            <Table className="table">
              <TableHead className="table_header">
                <TableRow className="table_header-row">
                  {tableHeader?.map((d) => (
                    <TableCell
                      key={d}
                      align="center"
                      className="table_header-row_heading"
                    >
                      {d}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {walletHistory?.map((data, index) => (
                  <TableRow
                    key={data?.id}
                    className="table_body-row"
                    style={{
                      top: calculateTop(index, walletHistory?.length),
                    }}
                  >
                    {/* <TableCell align="center" className="table_body-row_data">
                      {data?.transaction_name}
                    </TableCell> */}
                    <TableCell align="center" className="table_body-row_data">
                      {data?.created_at}
                    </TableCell>
                    <TableCell align="center" className="table_body-row_data">
                      {data?.amount_due} {messages["SAR"]}
                    </TableCell>
                    <TableCell align="center" className="table_body-row_data">
                      {data?.due_sessions}
                    </TableCell>
                    <TableCell align="center" className="table_body-row_data">
                      {data?.sessions_no}
                    </TableCell>
                    <TableCell align="center" className="table_body-row_data">
                      {messages[data?.pay_status]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
      </Row>
    </div>
  );
};
