import React from "react";
import { DetailsHeading } from "./DetailsHeading";
import { DetailsBody } from "./DetailsBody";
import { useLocation } from "react-router-dom";
import { CallAPI } from "../../../../config/api/ApiConfig";

export const UserDetails = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const { data: clientReservationRate } = CallAPI({
    name: "review",
    url: "/mentor/v1/bookings/" + id,
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });
  return (
    <div className="Destails_rating">
      <DetailsHeading clientReservationRate={clientReservationRate} />
      <DetailsBody clientReservationRate={clientReservationRate} />
    </div>
  );
};
