import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ButtonCustom } from "../../../../components/sharedComponents/ButtonCustom";
import { CallAPI } from "../../../../config/api/ApiConfig";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PATH_PAGE } from "../../../../routes/Paths";
import { AuthContext } from "../../../../auth/AuthProvider";
import { AxiosContext } from "../../../../config/api/AxiosConfig";
import { LocaleContext } from "../../../../config/localization/LanguageProvider";

export const WithdrawBank = () => {
  const { messages } = useIntl();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const { user } = useContext(AuthContext);
  const { setLoader } = useContext(AxiosContext);
  const { locale } = useContext(LocaleContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      username: "",
      bank_name: "",
      iban_number: "",
      account_number: "",
      iban_image: "",
    },
  });

  const submitData = async () => {
    const formData = new FormData();

    // formData.append("transaction_name", watch("transaction_name"));
    formData.append("username", watch("username"));
    formData.append("bank_name", watch("bank_name"));
    formData.append("iban_number", watch("iban_number"));
    formData.append("account_number", watch("account_number"));

    if (selectedImage?.name) {
      formData.append("iban_image", selectedImage);
    }

    try {
      let url = `${process.env.REACT_APP_DOMAIN}/mentor/v1/wallets/withdrawal-bank`;
      const response = await axios.post(url, formData, {
        headers: {
          "Accept-Language": locale,
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user?.token}`,
        },
      });

      if (response?.data?.success) {
        navigate(PATH_PAGE.walletUser);
        toast.success(messages["send_request_successfully"]);
      } else {
        toast.error(response?.data?.message);
      }
      setLoader(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoader(false);
    }
  };

  const onSubmit = (data) => {
    setLoader(true);
    submitData();
  };

  // const { refetch } = CallAPI({
  //   name: "withDrawalBankUser",
  //   url: "/client/v1/wallets/withdrawal-bank",
  //   refetchOnWindowFocus: false,
  //   method: "post",
  //   body: {
  //     ...watch(),
  //   },
  //   enabled: false,
  //   onSuccess: (res) => {
  //     navigate(PATH_PAGE.walletUser);
  //     toast.success(messages["send_request_successfully"]);
  //   },
  //   onError: (err) => {
  //     toast.error(err?.response?.data?.message);
  //   },
  // });

  // const onSubmit = (data) => {
  //   refetch();
  // };

  useEffect(() => {
    if (watch("iban_image")) {
      const file = Object.values(watch("iban_image"))[0];
      setSelectedImage(file);
    }
  }, [watch, watch("iban_image")]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container-fluid">
        <Row>
          {/* <Col md={6} className="mb-3">
            <div className="mentor-input">
              <label className="mentor-input__label">
                {messages["wallet.account_holder"]}
              </label>
              <input
                className="mentor-input__field"
                type="text"
                name="username"
                {...register("username", {
                  required: messages["name_required"],
                  minLength: {
                    value: 3,
                    message: messages["name.min.char"],
                  },
                  maxLength: {
                    value: 20,
                    message: messages["name.max.char"],
                  },
                })}
              />
              {errors.username && (
                <span className="mentor-input__field-error">
                  {errors.username?.message}
                </span>
              )}
            </div>
          </Col> */}
          {/* <Col md={6} className="mb-3">
            <div className="mentor-input">
              <label className="mentor-input__label">
                {messages["wallet.bank_name"]}
              </label>
              <input
                className="mentor-input__field"
                type="text"
                name="bank_name"
                {...register("bank_name", {
                  required: messages["wallet.bank_name_required"],
                  minLength: {
                    value: 3,
                    message: messages["wallet.bank_name.min.char"],
                  },
                  maxLength: {
                    value: 20,
                    message: messages["wallet.bank_name.max.char"],
                  },
                })}
              />
              {errors.bank_name && (
                <span className="mentor-input__field-error">
                  {errors.bank_name?.message}
                </span>
              )}
            </div>
          </Col> */}

          {/* <Col md={6} className="mb-3">
            <div className="mentor-input">
              <label className="mentor-input__label">IBAN</label>
              <input
                className="mentor-input__field"
                type="text"
                name="iban_number"
                {...register("iban_number", {
                  required: messages["wallet.IBAN_required"],
                  minLength: {
                    value: 3,
                    message: messages["wallet.IBAN.min.char"],
                  },
                  maxLength: {
                    value: 20,
                    message: messages["wallet.IBAN.max.char"],
                  },
                })}
              />
              {errors.iban_number && (
                <span className="mentor-input__field-error">
                  {errors.iban_number?.message}
                </span>
              )}
            </div>
          </Col> */}

          {/* <Col md={6} className="mb-3">
            <div className="mentor-input">
              <label className="mentor-input__label">
                {messages["wallet.account_no"]}
              </label>
              <input
                className="mentor-input__field"
                type="text"
                name="account_number"
                {...register("account_number", {
                  required: messages["wallet.account_no.required"],
                  minLength: {
                    value: 3,
                    message: messages["wallet.account_no.min.char"],
                  },
                  maxLength: {
                    value: 20,
                    message: messages["wallet.account_no.max.char"],
                  },
                })}
              />
              {errors.account_number && (
                <span className="mentor-input__field-error">
                  {errors.account_number?.message}
                </span>
              )}
            </div>
          </Col> */}

          {/* <Col md={6} className="mb-3">
            <div className="mentor-input">
              <label className="mentor-input__label">
                {messages["wallet.transaction_name"]}
              </label>
              <input
                className="mentor-input__field"
                type="text"
                name="transaction_name"
                {...register("transaction_name", {
                  required: messages["wallet.transaction_name.required"],
                  minLength: {
                    value: 3,
                    message: messages["wallet.transaction_name.min.char"],
                  },
                  maxLength: {
                    value: 20,
                    message: messages["wallet.transaction_name.max.char"],
                  },
                })}
              />
              {errors.transaction_name && (
                <span className="mentor-input__field-error">
                  {errors.transaction_name?.message}
                </span>
              )}
            </div>
          </Col> */}
          {/* <Col xs={4} md={6}>
            <div className="mentor-input">
              <label className="label-file">{messages["iban_image"]}</label>
              <label htmlFor="imageInput" className="file-input-label">
                {watch("iban_image") && Object?.values(watch("iban_image"))[0]
                  ? Object.values(watch("iban_image"))[0]?.name
                  : "sss"
                  ? messages["upload_iban_image"]
                  : messages["iban_image_required"]}
              </label>
              <input
                type="file"
                id="imageInput"
                className="file-input"
                accept=".jpeg, .jpg, .png"
                {...register("iban_image", {
                  ...(null
                    ? {}
                    : { required: messages["iban_image_required"] }),
                })}
              />{" "}
              {errors.iban_image && (
                <span className="mentor-input__field-error">
                  {errors.iban_image?.message}
                </span>
              )}
            </div>
          </Col> */}
          <Col xs={4} md={12} className="text-center">
            <label>{messages["click_witdrawl_to_contact_us"]}</label>
          </Col>

          <Col xs={4} md={4}></Col>
            <Col xs={4} md={3} className="mt-4 text-center">
              <ButtonCustom
                className="w-100"
                text="request_withdrawal"
                type="submit"
              />
            </Col>
           
        </Row>
      </div>
    </form>
  );
};
