import React from "react";
import { ReservTable } from "./ReservTable";
import { DetailsHeader } from "./DetailsHeader";
import { DetailsCard} from "./DetailsCard";

export const ReservDetails = () => {
  return (
    <div className="container-fluid">
      <DetailsHeader />
      <DetailsCard/>
      <ReservTable />
    </div>
  );
};
