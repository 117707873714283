import React from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { CallAPI } from "../../../config/api/ApiConfig";

export const Meeting = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const { messages } = useIntl();

  const { data: getSingleBooking } = CallAPI({
    name: "getSingleBooking",
    url: "/mentor/v1/bookings/" + id,
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const { data: gapBetweenSession } = CallAPI({
    name: "gapBetweenSession",
    url: "/setting/gap_between_sessions",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  if (getSingleBooking?.meet_code) {
    let sessionTime = parseInt(getSingleBooking?.service?.period);
    let totalTime = sessionTime + parseInt(gapBetweenSession?.value);
    let timeToClose = totalTime * 60 * 1000;
 
    const meetLink = "https://meet.google.com/" + getSingleBooking?.meet_code;
    const meetWindow = window.open(meetLink, "_blank");
    setTimeout(() => {
      meetWindow.close();
    }, timeToClose);
    window.addEventListener("beforeunload", () => {});
  }

  return (
    <div className="text-center">
      {messages["meeting_just_start"]}
      {/* <a href="https://google.com" target="_blank">
        {messages["join_meeting"]}
      </a> */}
    </div>
  );
};
