import React, { useEffect, useState } from "react";
import { ButtonCustom } from "../../../../components/sharedComponents/ButtonCustom";
import { CallAPI } from "../../../../config/api/ApiConfig";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH_PAGE } from "../../../../routes/Paths";
import { Autocomplete, Box, Chip, TextField } from "@mui/material";

export const PublicEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const industry = searchParams.get("industry");

  const [calledFirstTime, setCalledFirstTime] = useState(false);
  const { messages } = useIntl();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      job_title: "",
      industry_id: "",
      category_id: "",
      tools:[],
      langs:[],
      experiences: [],
      company_name: "",
      linkedIn: "",
    },
  });

  const { refetch: editProfile } = CallAPI({
    name: "editData",
    url: "/mentor/v1/auth/edit-public-profile",
    refetchOnWindowFocus: false,
    select: (res) => res?.data,
    enabled: false,
    method: "post",
    body: {
      ...watch(),
      experiences: watch("experiences")?.map((d) => d?.id) || [],
      tools: watch("tools")?.map((d) => d?.id) || [],
      langs: watch("langs")?.map((d) => d?.id) || [],
    },
    onSuccess: (res) => {
      navigate(PATH_PAGE.publicProfile);
      toast.success(res?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { refetch } = CallAPI({
    name: "publicData",
    url: "/mentor/v1/auth/public-profile",
    refetchOnWindowFocus: false,
    enabled: false,
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      setCalledFirstTime(true);
      setValue("job_title", res?.job_tile);
      setValue("industry_id", res?.industry?.id?.toString());
      setValue("category_id", res?.category?.id?.toString());
      setValue(
        "experiences",
        res?.experiences?.map((ex) => ({
          id: ex?.id,
          label: ex?.name,
        }))
      );
      setValue(
        "tools",
        res?.tools?.map((ex) => ({
          id: ex?.id,
          label: ex?.name,
        }))
      );
      setValue(
        "langs",
        res?.langs?.map((ex) => ({
          id: ex?.id,
          label: ex?.name,
        }))
      );
      setValue("company_name", res?.company_name);
      setValue("linkedIn", res?.linkedIn);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { data: industries } = CallAPI({
    name: "getIndustry",
    url: "/industries",
    refetchOnWindowFocus: false,
    enabled: industry ? true : false,
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      if (res && calledFirstTime) {
        setValue("industry_id", res[0]?.id?.toString());
      }
    },
  });

  const { data: categories, refetch: callCategories } = CallAPI({
    name: ["getCategories", industry || getValues("industry_id")],
    url: `/industries/${getValues("industry_id") || industry}/categories`,
    refetchOnWindowFocus: false,
    enabled: industry || getValues("industry_id")?.length ? true : false,
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      if (!calledFirstTime) {
        refetch();
      }
      //   const found = res?.find((r) => +r?.id === +watch("category_id"));
      //   if (found?.id) {
      //     // setTimeout(() => {
      //     //   setValue("category_id", found?.id?.toString());
      //     // }, 500);
      //   }
    },
  });

  const { data: experiences } = CallAPI({
    name: "getExperiences",
    url: "/mentor/v1/experiences",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) =>
      res?.data?.data?.map((d) => ({ id: d?.id, label: d?.name })),     
  });

  const { data: tools } = CallAPI({
    name: "getTools",
    url: "/mentor/v1/tools",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) =>
      res?.data?.data?.map((d) => ({ id: d?.id, label: d?.name })),       
  });

  const { data: langs } = CallAPI({
    name: "getLangs",
    url: "/mentor/v1/langs",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) =>
      res?.data?.data?.map((d) => ({ id: d?.id, label: d?.name })),
      onSuccess: (res) => {
        console.log(res);
      }
  });

  //   useEffect(() => {
  //     if (experiences && industries && categories && !calledFirstTime) {
  //       refetch();
  //     }
  //   }, [industries, experiences, categories, calledFirstTime, refetch]);

  const onSubmit = (data) => {
    editProfile();
  };

  return (
    <div className="profile">
      <div class="container-fluid">
        <div className="head_box">
          <h2 className="heading_dash mb-1">
            {messages["edit_public_profile"]}
          </h2>
          <p className="heading_dis">{messages["edit_public_profile"]}</p>
        </div>

        <form className="row mt-5" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="heading_dash mb-3">{messages["work_info"]}</h2>

          <div className="mentor-input col-md-6 mb-3">
            <label className="mentor-input__label">
              {messages["job_title"]}
            </label>
            <input
              className="mentor-input__field"
              type="text"
              name="job_title"
              {...register("job_title", {
                required: messages["job_title_required"],
                minLength: {
                  value: 3,
                  message: messages["job_title_min"],
                },
                maxLength: {
                  value: 50,
                  message: messages["job_title_max"],
                },
              })}
            />
            {errors.job_title && (
              <span className="mentor-input__field-error">
                {errors.job_title?.message}
              </span>
            )}
          </div>
          <div className="col-md-6 mb-3 position-relative">
            <label className="autoComplete-label">
              {messages["experiences"]}
            </label>
            <Controller
              name="experiences"
              control={control}
              rules={{ required: messages["experiences.required"] }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  className="autoComplete"
                  disablePortal
                  disabled={false}
                  multiple
                  id="experiences"
                  options={experiences || []}
                  //   getoptionselected={(option, value) =>
                  //     option?.label === value?.label
                  //   }
                  onChange={(_, selectedOptions) => {
                    field.onChange(selectedOptions);
                  }}
                  renderTags={(tagValue, getTagProps) => {
                    return (
                      <Box className="dataChips">
                        {tagValue.map((option, index) => (
                          <Chip
                            key={option?.id}
                            {...getTagProps({ index })}
                            label={option.label}
                          />
                        ))}
                      </Box>
                    );
                  }}
                  renderInput={(params) => <TextField {...params} label="" />}
                />
              )}
            />
            {errors.experiences && (
              <span className="mentor-input__field-error-multiple">
                {errors.experiences?.message}
              </span>
            )}
          </div>

          <div className="col-md-6 mb-3 position-relative">
            <label className="autoComplete-label">
              {messages["tools"]}
            </label>
            <Controller
              name="tools"
              control={control}
              rules={{ required: messages["tools.required"] }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  className="autoComplete"
                  disablePortal
                  disabled={false}
                  multiple
                  id="tools"
                  options={tools || []}
                  //   getoptionselected={(option, value) =>
                  //     option?.label === value?.label
                  //   }
                  onChange={(_, selectedOptions) => {
                    field.onChange(selectedOptions);
                  }}
                  renderTags={(tagValue, getTagProps) => {
                    return (
                      <Box className="dataChips">
                        {tagValue.map((option, index) => (
                          <Chip
                            key={option?.id}
                            {...getTagProps({ index })}
                            label={option.label}
                          />
                        ))}
                      </Box>
                    );
                  }}
                  renderInput={(params) => <TextField {...params} label="" />}
                />
              )}
            />
            {errors.tools && (
              <span className="mentor-input__field-error-multiple">
                {errors.tools?.message}
              </span>
            )}
          </div>

          <div className="col-md-6 mb-3 position-relative">
            <label className="autoComplete-label">
              {messages["languages"]}
            </label>
            <Controller
              name="langs"
              control={control}
              rules={{ required: messages["langs.required"] }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  className="autoComplete"
                  disablePortal
                  disabled={false}
                  multiple
                  id="langs"
                  options={langs || []}
                  //   getoptionselected={(option, value) =>
                  //     option?.label === value?.label
                  //   }
                  onChange={(_, selectedOptions) => {
                    field.onChange(selectedOptions);
                  }}
                  renderTags={(tagValue, getTagProps) => {
                    return (
                      <Box className="dataChips">
                        {tagValue.map((option, index) => (
                          <Chip
                            key={option?.id}
                            {...getTagProps({ index })}
                            label={option.label}
                          />
                        ))}
                      </Box>
                    );
                  }}
                  renderInput={(params) => <TextField {...params} label="" />}
                />
              )}
            />
            {errors.langs && (
              <span className="mentor-input__field-error-multiple">
                {errors.langs?.message}
              </span>
            )}
          </div>


          <div className="mentor-input col-md-6 mb-3">
            <div className="d-flex flex-column position-relative mb-10">
              <label className="selectInputLabel" htmlFor="industry_id">
                {messages["industry"]}
              </label>
              <select
                {...register("industry_id", {
                  required: messages["industry_required"],
                })}
                control={control}
                rules={{ required: "Select at least one option" }}
                name="industry_id"
                className="sign-select selectInputDefault"
                onChange={(e) => {
                  setValue("industry_id", e?.target?.value);
                  setValue("category_id", "");
                  clearErrors("industry_id");
                  setTimeout(() => {
                    callCategories();
                  }, 200);
                }}
              >
                <option value=""></option>
                {industries?.map((industry) => (
                  <option key={industry?.id} value={industry?.id}>
                    {industry?.name}
                  </option>
                ))}
              </select>
              {errors.industry_id && (
                <span className="mentor-input__field-error">
                  {errors.industry_id?.message}
                </span>
              )}
            </div>
          </div>
          <div className="mentor-input col-md-6 mb-3">
            <label className="mentor-input__label">
              {messages["company_name"]}
            </label>
            <input
              className="mentor-input__field"
              type="text"
              name="company_name"
              {...register("company_name", {
                minLength: {
                  value: 3,
                  message: messages["company_name_min"],
                },
                maxLength: {
                  value: 10,
                  message: messages["company_name_max"],
                },
              })}
            />
            {errors.company_name && (
              <span className="mentor-input__field-error">
                {errors.company_name?.message}
              </span>
            )}
          </div>
          <div className="mentor-input col-md-6 mb-3">
            <div className="d-flex flex-column position-relative mb-10">
              <label className="selectInputLabel" htmlFor="category_id">
                {messages["specialization"]}
              </label>
              <select
                {...register("category_id", {
                  required: messages["specialization_required"],
                })}
                name="category_id"
                className="sign-select selectInputDefault"
                onChange={(e) => {
                  setValue("category_id", e?.target?.value);
                  clearErrors("category_id");
                }}
                // disabled={!regions?.length || regionLoad}
              >
                <option value=""></option>
                {categories?.map((cat) => (
                  <option key={cat?.id} value={cat?.id}>
                    {cat?.name}
                  </option>
                ))}
              </select>
              {errors.category_id && (
                <span className="mentor-input__field-error">
                  {errors.category_id?.message}
                </span>
              )}
            </div>
          </div>
          <div className="mentor-input col-md-6 mb-3">
            <label className="mentor-input__label">
              {messages["linkedin"]}
            </label>
            <input
              className="mentor-input__field"
              type="text"
              name="linkedIn"
              {...register("linkedIn", {
                pattern: {
                  value:
                    /^(https:\/\/)?(www\.)?linkedin\.com\/in\/[A-Za-z0-9_-]+\/?$/,
                  message: messages["linkedin_validate"],
                },
              })}
              // need  validation
            />
            {errors.linkedIn && (
              <span className="mentor-input__field-error">
                {errors.linkedIn?.message}
              </span>
            )}
          </div>

          <div className="row mt-3">
            <div className="col-md-2">
              <ButtonCustom className="w-100" text="Save" type="submit" />
            </div>
            <div className="col-md-2">
              <ButtonCustom
                className="w-100 btn_dark"
                text="Decline"
                onClick={() => navigate(PATH_PAGE.publicProfile)}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
