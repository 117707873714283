import React, { useContext, useEffect, useState } from "react";
import { formatDate } from "../../../constants/constants";

export const ChatList = ({
  chats,
  setSelectedMessageId,
  selectedMessageId,
}) => {
  const handleChatClick = (chatId) => {
    setSelectedMessageId(chatId);
  };
   const userDataString = localStorage.getItem("user_data");
   
   const userData = JSON.parse(userDataString) ;
  const userId = userData?.data.id;
  

  return (
    <div className="chat_side">
      {chats?.map((ch) => {
        const isSender = ch.sender?.id === userId;
        const otherUser = isSender ? ch.receiver : ch.sender;

        return (
          <div
            key={ch.chat_id} // Ensure a unique key for each chat item
            className={`chat_side-box d-flex align-items-center w-100 mb-3 ${selectedMessageId === ch.chat_id ? "green" : ""
              }`}
            onClick={() => handleChatClick(ch.chat_id)}
          >
            <div>
              <div className="img">
                {otherUser?.photo && (
                  <img src={otherUser.photo} alt="profile" />
                )}
              </div>
            </div>
            <div className="w-100">
              <div className="d-md-flex justify-content-between align-items-center w-100">
                <h4>
                  {otherUser?.first_name} {otherUser?.last_name}
                </h4>
                <span className="mb-2">{formatDate(ch.created_at)}</span>
              </div>
              <span className="mb-2">{ch.count_un_read_message}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
