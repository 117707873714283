import React from "react";
import { useIntl } from "react-intl";

export const ServicesHeader = () => {
  const { messages } = useIntl();
  return (
    <div className="container-fluid">
      <div className="head_box">
        <h2 className="heading_dash mb-1">{messages["packages"]}</h2>
        <p className="heading_dis">{messages["packages_info"]}</p>
      </div>
    </div>
  );
};
