import Modal from "react-bootstrap/Modal";
import { useIntl } from "react-intl";
import { ButtonCustom } from "./ButtonCustom";

export const Modals = ({
  open,
  handleClose,
  body,
  title,
  onClickSend,
  size = "lg",
  showfooter = true,
}) => {
  const { messages } = useIntl();
  return (
    <Modal
      show={open}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size={size}
    >
      <Modal.Header closeButton>
        <Modal.Title>{messages[title]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      {showfooter && (
        <Modal.Footer className="gap-2">
          <ButtonCustom text="send" className="w-25" onClick={onClickSend} />
          <ButtonCustom
            text="close"
            className="w-25 close-modal-btn"
            onClick={handleClose}
          />
        </Modal.Footer>
      )}
    </Modal>
  );
};
