import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Label,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { CallAPI } from "../../config/api/ApiConfig";

const SalesBars = () => {
  const { data: data } = CallAPI({
    name: "salesChart",
    url: "/mentor/v1/dashboard-booking-sessions?days=7",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => [
      {
        name: "completed",
        value: res?.data?.data?.complete?.length,
      },
      {
        name: "scheduled",
        value: res?.data?.data?.schedule,
      },
    ],
  });
  // {
  //   completed: res?.data?.data?.complete?.length,
  //   scheduled: res?.data?.data?.schedule,
  // }

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    value,
    name,
  }) => {
    console.log(value, name);
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN) + 15;
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return value !== 0 ? (
      <text
        x={200}
        y={y}
        fill="white"
        // textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >{`${name} : ${value}`}</text>
    ) : null;
  };

  const COLORS = ["#0088FE", "#00C49F"];

  return (
    <div className="chart_sales">
      <PieChart width={500} height={300}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={150}
          fill="#8884d8"
          dataKey="value"
        >
          {data?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
};

export default SalesBars;
// import React from "react";
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Label } from "recharts";
// import { CallAPI } from "../../config/api/ApiConfig";

// const SalesBars = () => {
//   const { data: salesData } = CallAPI({
//     name: "salesChart",
//     url: "/mentor/v1/dashboard-sales-statistics?weeks=7",
//     refetchOnWindowFocus: false,
//     enabled: true,
//     select: (res) => res?.data?.data,
//   });

//   const data = salesData?.map((weekData, index) => ({
//     week: `Week ${index + 1}`,
//     revenue: weekData.revenue,
//   }));

//   return (
//     <div className="chart_sales">
//       <BarChart width={500} height={300} data={data}>
//         <CartesianGrid stroke="transparent" />
//         <XAxis dataKey="week" stroke="#3A4E7B" />
//         <YAxis
//           stroke="#3A4E7B"
//           domain={[0, 10]}
//           ticks={[10, 50, 100, 150, 200, 250, 300]}
//         />
//         <Bar
//           dataKey="revenue"
//           fill="url(#colorGradient)"
//           barSize={20}
//           radius={[15, 15, 0, 0]}
//           label={false}
//         />
//         <defs>
//           <linearGradient id="colorGradient" gradientTransform="rotate(90)">
//             <stop offset="0%" stopColor="#8EC452" />
//             <stop offset="100%" stopColor="#F9FCF5" />
//           </linearGradient>
//         </defs>
//       </BarChart>
//     </div>
//   );
// };

// export default SalesBars;
