export const DashboaredHeader = ({
  className = "DashboaredHeaderDef",
  text = "header 1",
  ...props
}) => {
  return (
    <h2 className={className} {...props}>
      {text}
    </h2>
  );
};
