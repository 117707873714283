export const iconPath = () => {
  return process.env.PUBLIC_URL + "/imgs/";
};

export const isPasswordValid = (value) => {
  const containsNumber = /\d/.test(value);
  const containsCharacter = /[a-zA-Z]/.test(value);

  if (!(containsNumber && containsCharacter)) {
    return "Password does not meet the conditions";
  }

  return true;
};

export const isStringInsideObject = (obj, targetString) => {
  for (const value of Object.values(obj)) {
    if (typeof value === "string" && value === targetString) {
      return true;
    }
  }
  return false;
};

export const calculateTop = (index, dataLength) => {
  if (index === 0) {
    return 10;
  }

  return calculateTop(index - 1) + 20;
};

export const convertMinutesToHours = (minutes = 60) => {
  const hours = minutes / 60;
  return +hours.toFixed(2);
};

export const validateMaxThreeDigits = (value, messages) => {
  return /^\d{1,3}$/.test(value) || messages["service_duration_max"];
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};

export const dayTimeInMinutes = (messages) => {
  const timeArray = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 5) {
      const formattedHour = hour.toString().padStart(2, "0");
      const formattedMinute = minute.toString().padStart(2, "0");
      const time = `${formattedHour}:${formattedMinute}`;
      timeArray.push({ id: time, text: time });
    }
  }
  return timeArray;
};

export const getMinutesFromTime = (timeString) => {
  const [hours, minutes] = timeString.split(":").map(Number);
  return hours * 60 + minutes;
};

export const dateWithMonth = (inputDate) => {
  // Output: 03 December 2023
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [year, month, day] = inputDate?.split("-");
  const formattedDate = `${Number(day)} ${months[Number(month) - 1]} ${year}`;
  return formattedDate;
};

export const hoursMinutesSecond = (dateString) => {
  // Output: '13:30'
  const date = new Date(dateString);

  const hours = date.getUTCHours(); // Get hours (UTC)
  const minutes = date.getUTCMinutes(); // Get minutes (UTC)

  return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
};

export const convertTo12HourFormat = (timeString, locale) => {
  // Output: 5:00 PM
  const hours = timeString?.split(":")[0];
  const minutes = timeString?.split(":")[1];

  let period = locale === "ar" ? "ص" : "AM";
  let hour = parseInt(hours, 10);

  if (hour === 0) {
    hour = 12;
  } else if (hour >= 12) {
    period = locale === "ar" ? "م" : "PM";
    if (hour > 12) {
      hour -= 12;
    }
  }

  return `${hour}:${minutes} ${period}`;
};

export const yearMonthDay = (dateString) => {
  // Output: 2023-12-03
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Month starts from 0
  const year = date.getFullYear();

  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${year}-${formattedMonth}-${formattedDay}`;
};

export const dayDateMonth = (inputDate, locale) => {
  const date = new Date(inputDate);

  const localeData = {
    en: {
      daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      period: (hours) => (hours >= 12 ? "PM" : "AM"),
    },
    ar: {
      daysOfWeek: [
        "الاحد",
        "الاثنين",
        "الثلاثاء",
        "الاربعاء",
        "الخميس",
        "الجمعة",
        "السبت",
      ],
      months: [
        "ينا",
        "فبر",
        "مار",
        "ابر",
        "مايو",
        "يون",
        "يول",
        "اغس",
        "سبت",
        "اكت",
        "نوف",
        "ديس",
      ],
      period: (hours) => (hours >= 12 ? "م" : "ص"),
    },
  };

  const { daysOfWeek, months, period } = localeData[locale];

  const dayOfWeek = daysOfWeek[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = months[date.getMonth()];
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${dayOfWeek}, ${dayOfMonth} ${month} ${hours}:${
    (minutes < 10 ? "0" : "") + minutes
  } ${period(hours)}`;
};

export const dayWithTime = (inputDate, locale = "ar") => {
  // input "2023-12-12 13:12"
  // output "Tue, 12 Dec, 01:12 PM"
  const date = new Date(inputDate);
  const daysOfWeek =
    locale === "ar"
      ? [
          "الاحد",
          "الاثنين",
          "الثلاثاء",
          "الاربعاء",
          "الخميس",
          "الجمعة",
          "السبت",
        ]
      : ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months =
    locale === "ar"
      ? [
          "ينا",
          "فبر",
          "مار",
          "ابر",
          "مايو",
          "يون",
          "يول",
          "اغس",
          "سبت",
          "اكت",
          "نوف",
          "ديس",
        ]
      : [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

  const dayOfWeek = daysOfWeek[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = months[date.getMonth()];
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const period =
    hours >= 12 ? (locale === "ar" ? "م" : "PM") : locale === "ar" ? "ص" : "AM";
  const formattedHours = hours % 12 || 12;

  return `${dayOfWeek}, ${dayOfMonth} ${month}, ${formattedHours}:${minutes
    .toString()
    .padStart(2, "0")} ${period}`;
};

export const dateForEditService = (dateString) => {
  // input "Wed, Dec 20, 2023 3:31 PM"
  // output 2023-12-20
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const dateGroupService = (dateString, locale) => {
  // input "Wed, Dec 20, 2023 4:51 PM"
  // output "Dec 20, 2023, 4:51 PM"

  const date = new Date(dateString);

  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return date.toLocaleDateString(locale === "ar" ? "ar-EG" : "en-US", options);
};

// used for show and hide in layout
export const initialStructure = {
  isPrivate: false,
  hasFooter: true,
  hasNav: true,
  hasSideBar: true,
};
