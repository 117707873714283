import React from "react";
import { useIntl } from "react-intl";
import { formatDate } from "../../../constants/constants";

export const ChatList = ({
  chats,
  setSelectedMessageId,
  selectedMessageId,
}) => {
  const { messages } = useIntl();

  return (
    <div className="chat_side">
      {chats?.map((ch) => (
        <div
          key={ch?.created_at}
          className={`chat_side-box d-flex align-items-center w-100 mb-3 ${
            +selectedMessageId === +ch?.id && "green"
          }`}
          onClick={() => setSelectedMessageId(ch?.id)}
        >
          <div>
            <div className="img">
              {ch?.receiver?.photo && (
                <img src={ch?.receiver?.photo} alt="proifleSender" />
              )}
            </div>
          </div>
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center w-100">
              <h4>
                {ch?.receiver?.first_name}
                {ch?.receiver?.last_name}
              </h4>{" "}
              <span className="mb-2">{formatDate(ch?.created_at)}</span>
            </div>
            <span>
              {messages["session_id"]}: {ch?.id}
            </span>
            {/* <p>{ch?.message}</p> */}
          </div>
        </div>
      ))}
    </div>
  );
};
