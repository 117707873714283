import React from "react";
import SVG from "react-inlinesvg";
import { iconPath } from "../../../constants/constants";
import { PATH_PAGE } from "../../../routes/Paths";

export const SharedPlatform = ({ setOpenModal, id }) => {
  const path = iconPath();
  const baseUrl = "https://front.mentorlabs.fudex-tech.net";

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div className="shared-via">
      {" "}
      <ul className="d-flex social_foot">
        {/* twitter */}
        <li
          onClick={() => {
            window.open(
              "https://twitter.com/share?url=" +
                `${baseUrl}${PATH_PAGE.MentorDetails}?id=${id}`,
              "_blank"
            );
            handleClose();
          }}
        >
          <SVG src={`${path}social2.svg`} />
        </li>
        {/* linkedin */}
        <li
          onClick={() => {
            window.open(
              `https://www.linkedin.com/feed/?shareActive=true&shareUrl=${encodeURIComponent(
                `${baseUrl}${PATH_PAGE.MentorDetails}?id=${id}`
              )}&text=MentorLabs`,
              "_blank"
            );
            handleClose();
          }}
        >
          <SVG src={`${path}social3.svg`} />
        </li>{" "}
        {/* fb */}
        <li
          onClick={() => {
            window.open(
              `https://www.facebook.com/sharer/sharer.php?u=${`${baseUrl}${PATH_PAGE.MentorDetails}?id=${id}`}`,
              "_blank"
            );
            handleClose();
          }}
        >
          <SVG src={`${path}social4.svg`} />
        </li>
      </ul>
    </div>
  );
};
