import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { PATH_PAGE } from "../../../routes/Paths";
import { AddGroup } from "./groupService/AddGroup";
import { AddIndividual } from "./individualService/AddIndividual";
import { AddPackage } from "./packageService/AddPackage";

export const AddNewService = () => {
  const { messages } = useIntl();
  const [removeParams, setRemoveParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [checkbox, setCheckbox] = useState("service");
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const handleChangeCheckbox = (e) => {
    setCheckbox(e?.target?.value);
    setRemoveParams("");
    navigate(window.location.pathname);
  };

  useEffect(() => {
    if (id || type) {
      if (type === "private_session") {
        setCheckbox("service");
      }
      if (type === "group_session") {
        setCheckbox("group");
      }
      if (type === "private_sessions_package") {
        setCheckbox("package");
      }
    }
  }, [id, type]);

  const goBackToallServices = () => {
    navigate(PATH_PAGE.services);
  };

  return (
    <div className="container-fluid">
      <Row>
        <div className="head_box">
          <h2 className="heading_dash mb-1">
            {!id ? messages["Services.add.new"] : messages["Services.edit"]}
          </h2>
          <p className="heading_dis">{messages["Services_info"]}</p>
        </div>
      </Row>
      <Row>
        {!id && (
          <Col xs={12} className={`gap-5 justify-content-between`}>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="service"
                checked={checkbox === "service"}
                onChange={(e) => handleChangeCheckbox(e)}
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                {messages["services.individual"]}
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                value="group"
                checked={checkbox === "group"}
                onChange={(e) => handleChangeCheckbox(e)}
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                {messages["services.group"]}
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio3"
                value="package"
                checked={checkbox === "package"}
                onChange={(e) => handleChangeCheckbox(e)}
              />
              <label className="form-check-label" htmlFor="inlineRadio3">
                {messages["services.package"]}
              </label>
            </div>
          </Col>
        )}

        <Col xs={12} className="mt-4">
          {checkbox === "service" && (
            <AddIndividual goBackToallServices={goBackToallServices} />
          )}
          {checkbox === "group" && (
            <AddGroup goBackToallServices={goBackToallServices} />
          )}
          {checkbox === "package" && (
            <AddPackage goBackToallServices={goBackToallServices} />
          )}
        </Col>
      </Row>
    </div>
  );
};
