import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SVG from "react-inlinesvg";
import { ButtonCustom } from "../../../components/sharedComponents/ButtonCustom";
import { Form } from "react-bootstrap";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FirstHeader } from "../../../components/sharedComponents/FirstHeader";
import { UserMobileLogin } from "./UserMobileLogin";
import { RoundedIcon } from "../../../components/sharedComponents/RoundedIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PATH_PAGE } from "../../../routes/Paths";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { iconPath } from "../../../constants/constants";
import { CallAPI } from "../../../config/api/ApiConfig";
import { AuthContext } from "../../../auth/AuthProvider";
import { BsEye, BsEyeSlash, BsGlobe } from "react-icons/bs";
import { LocaleContext } from "../../../config/localization/LanguageProvider";
import { useIntl } from "react-intl";

export default function UserLogin() {
  const navigate = useNavigate();
  const path = iconPath();
  const { locale, setLocale } = useContext(LocaleContext);
  const { setUser } = useContext(AuthContext);
  const { messages } = useIntl();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const success = searchParams.get("success");
  const id = searchParams.get("id");
  const token = searchParams.get("token");

  const [checkbox, setCheckbox] = useState("mobile");
  const [phoneNum, setPhoneNumber] = useState("");
  const [passwordType, setPasswordType] = useState(true);

  const isTablet = useMediaQuery("(max-width:1024px)");
  const classFlex = "justify-content-center align-items-center d-flex";

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      email: "",
      phone: "",
      password: "",
    },
  });

  const { refetch } = CallAPI({
    name: "loginUser",
    url: "/client/v1/auth/login",
    refetchOnWindowFocus: false,
    method: "post",
    body: {
      password: watch("password"),
      username:
        checkbox === "mobile"
          ? watch("phone")?.slice(5)?.trim().replace(/\s/g, "")
          : watch("email"),
    },
    enabled: false,
    onSuccess: (res) => {
      localStorage.setItem("user_data", JSON.stringify(res?.data));
      setUser(res?.data);
      navigate(PATH_PAGE.userDashboard);
      toast.success(messages["login.success"]);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const onSubmit = (data) => {
    refetch();
  };

  const { refetch: callLoginSocial } = CallAPI({
    name: "loginSocialClient",
    url: `/client/v1/auth/user/${id}/${token}`,
    refetchOnWindowFocus: false,
    enabled: false,
    method: "post",
    onSuccess: (res) => {
      localStorage.setItem("user_data", JSON.stringify(res?.data));
      setUser(res?.data);
      navigate(PATH_PAGE.userDashboard);
      toast.success(messages["login.success"]);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { refetch: loginLinkedin } = CallAPI({
    name: "linkedinClient",
    url: "/client/linkedin-login",
    refetchOnWindowFocus: false,
    enabled: false,
    baseURL: process.env.REACT_APP_DOMAIN_SECOND,
    onSuccess: (res) => {
      if (res?.data?.data) {
        window.location.href = res?.data?.data;
      }
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  // login with google
  const { refetch: loginGoogle } = CallAPI({
    name: "userLoginGoogle",
    url: "/client/google-login",
    refetchOnWindowFocus: false,
    enabled: false,
    baseURL: process.env.REACT_APP_DOMAIN_SECOND,
    onSuccess: (res) => {
      if (res?.data?.data) {
        window.location.href = res?.data?.data;
      }
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  //   work with social login
  useEffect(() => {
    if (success && id) {
      callLoginSocial();
      navigate(location?.pathname);
    }
  }, [success, id]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container className="mt-5">
        <Row className={`${classFlex} mb-5 text-center`}>
          <Col xs={6}>
            <SVG
            className="logo_size"
              src={`${path}logo.svg`}
              onClick={() => navigate(PATH_PAGE.clHome)}
            />{" "}
          </Col>
        </Row>
        <Row className={`border w-${isTablet ? 100 : 50} mx-auto p-4`}>
          <Col>
            <Row className="mb-4">
              <Col className="d-flex justify-content-start">
                <div className="language-selection mb-3">
                  <BsGlobe />
                  <Form.Select
                    onChange={(e) => {
                      setLocale(e.target.value);
                    }}
                    value={locale}
                  >
                    <option value="en">{messages["english"]}</option>
                    <option value="ar">{messages["arabic"]}</option>
                  </Form.Select>
                </div>
              </Col>
              <Col xs={12} className={classFlex}>
                <FirstHeader text="sign in" />
              </Col>
              <Col
                xs={12}
                className={`d-md-flex gap-5 justify-content-between mt-3`}
              >
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="email"
                    checked={checkbox === "email"}
                    onChange={(e) => setCheckbox(e?.target?.value)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    {messages["login.via.email"]}
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="mobile"
                    checked={checkbox === "mobile"}
                    onChange={(e) => setCheckbox(e?.target?.value)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    {messages["login.via.mobile"]}
                  </label>
                </div>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                {checkbox === "mobile" ? (
                  <UserMobileLogin
                    phoneNum={phoneNum}
                    setPhoneNumber={setPhoneNumber}
                    control={control}
                  />
                ) : (
                  <>
                    <div className="mentor-input">
                      <label className="mentor-input__label">
                        {messages["e_mail"]}
                      </label>
                      <input
                        className="mentor-input__field"
                        type="text"
                        name="email"
                        {...register("email", {
                          required: messages["email_required"],
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: messages["email_invalid"],
                          },
                        })}
                      />
                      {errors.email && (
                        <span className="mentor-input__field-error">
                          {errors.email?.message}
                        </span>
                      )}
                    </div>{" "}
                  </>
                )}
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={12} className="mb-3 position-relative">
                <div className="mentor-input">
                  <label className="mentor-input__label">
                    {messages["password"]}
                  </label>
                  <input
                    className="mentor-input__field"
                    type={passwordType ? "password" : "text"}
                    name="password"
                    {...register("password", {
                      required: messages["mobile_required"],
                      minLength: {
                        value: 6,
                        message: messages["mobile_min"],
                      },
                    })}
                  />
                  {errors.password && (
                    <span className="mentor-input__field-error">
                      {errors.password?.message}
                    </span>
                  )}
                </div>

                {passwordType ? (
                  <BsEyeSlash
                    className="eye-password"
                    onClick={() => {
                      setPasswordType(!passwordType);
                    }}
                  />
                ) : (
                  <BsEye
                    className="eye-password"
                    onClick={() => {
                      setPasswordType(!passwordType);
                    }}
                  />
                )}
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={6}>
                <Form.Check
                  className="position-relative"
                  type="checkbox"
                  id={`default-checkbox`}
                  label={messages["Remember_Me"]}
                />
              </Col>
              <Col md={6} className="d-flex justify-content-end">
                <Link
                  className="forget-password"
                  to={PATH_PAGE.UserResetPassword}
                >
                  {messages["forget_password"]}
                </Link>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <ButtonCustom className="w-100" text="sign in" type="submit" />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col
                xs={12}
                className={`${classFlex}  text-center text-dark-blue mb-3`}
              >
                {messages["common.or"]} <br /> {messages["signin_with"]}
              </Col>
              <Col
                xs={12}
                className={`${classFlex}  text-center text-dark-blue gap-3`}
              >
                {/* <RoundedIcon src="fb.svg" /> */}
                <RoundedIcon
                  src="linkedin.svg"
                  onClick={() => loginLinkedin()}
                />
                <RoundedIcon
                  src="gmail.svg"
                  className="roundedLargeIcon"
                  onClick={() => loginGoogle()}
                />
                {/* <RoundedIcon src="apple.svg" className="roundedLargeIcon" /> */}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className={classFlex}>
            <Link to={PATH_PAGE.UserSignUp}>
              <p className="text-dark-blue">
                {messages["dont_have_account"]}{" "}
                <u className="text-green">{messages["sign up"]}</u>
              </p>
            </Link>
          </Col>
        </Row>
      </Container>
    </form>
  );
}
