import "./App.scss";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AxiosContext } from "./config/api/AxiosConfig";
import { NotFound } from "./pages/NotFound/NotFound";
import {
  AuthPublicRoutes,
  PrivateRouteClient,
  PrivateRouteMentor,
} from "./routes/routes";
import { PATH_PAGE } from "./routes/Paths";
import Login from "./pages/Authentications/Login/Login";
import { RegisterCompleted } from "./pages/Authentications/SignUp/RegisterCompleted";
import { useContext, useState, useEffect } from "react";
import Spinner from "./components/sharedComponents/Spinner";
import { LayoutMentor } from "./layouts/LayoutMentor";
import { SignUp } from "./pages/Authentications/SignUp/SignUp";
import { TermsAndConidiotns } from "./pages/TermsAndConditions/TermsAndConidiotns";
import { LayoutClient } from "./layouts/LayoutClient";
import { mentorStructure } from "./components/structure/mentorStructure";
import {
  clientDashboard,
  clientStructure,
} from "./components/structure/clientStructure";
import { Home } from "./pages/Home/Home";
import UserLogin from "./pages/UserAuth/UserLogin/UserLogin";
import { UserSignUp } from "./pages/UserAuth/UserSignUp/UserSignUp";
import { UserResetPassword } from "./pages/UserAuth/UserResetPassword/UserResetPassword";
import { TermsConidiotns } from "./pages/UsertermsConditions/TermsConidiotns";
import { UserPrivacyPolicy } from "./pages/UserPrivacyPolicy/UserPrivacyPolicy";
import { UserAbout } from "./pages/UserAbout/UserAbout";
import { ResetPassword } from "./pages/Authentications/ResetPassword/ResetPassword";
import { DashboardClient } from "./layouts/DashboardClient";
import ScrollToTop from "./constants/ScrollToTop";
import { UserContact } from "./pages/UserContact/UserContact";
import { UserFaq } from "./pages/UserFaq/UserFaq";
import { AuthContext } from "./auth/AuthProvider";
import { HomeDashboard } from "./pages/dashboard/HomeDashboard";

function App() {
  const { loader } = useContext(AxiosContext);
  const [collapse, setCollapse] = useState(false);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const isAuthenticated = (User) => User?.token || false;

  useEffect(() => {
    if (!isAuthenticated(user)) {
      // navigate("/login");

    } else {
    }
  }, [user, navigate]);
  return (
    <div className="App">
      <Spinner isLoading={loader} />
      <ScrollToTop />

      <Routes>
        {/* routes both client and mentor can see and without auth */}

        <Route
          exact
          path="/"
          element={
            <LayoutMentor collapse={collapse} setCollapse={setCollapse}>
              <HomeDashboard />
            </LayoutMentor>
          }
        />
        {/* <Route
          path={PATH_PAGE.clHome}
          element={
            <LayoutClient>
              <Home />
            </LayoutClient>
          }
        /> */}
        <Route
          path={PATH_PAGE.UserPrivacyPolicy}
          element={
            <LayoutClient>
              <UserPrivacyPolicy />
            </LayoutClient>
          }
        />
        <Route
          path={PATH_PAGE.UserAbout}
          element={
            <LayoutClient>
              <UserAbout />
            </LayoutClient>
          }
        />

        <Route
          path={PATH_PAGE.UsertermsHome}
          element={
            <LayoutClient>
              <TermsConidiotns />
            </LayoutClient>
          }
        />

        <Route
          path={PATH_PAGE.UserContactUs}
          element={
            <LayoutClient>
              <UserContact />
            </LayoutClient>
          }
        />

        <Route
          path={PATH_PAGE.UserFaqhome}
          element={
            <LayoutClient>
              <UserFaq />
            </LayoutClient>
          }
        />

        {/* routes for authentications here for client and mentor */}

        <Route element={<AuthPublicRoutes />}>
          <Route index path={PATH_PAGE.login} element={<Login />} />
          <Route path={PATH_PAGE.signUp} element={<SignUp />} />
          <Route path={PATH_PAGE.resetPassword} element={<ResetPassword />} />
          <Route
            path={PATH_PAGE.termsConditions}
            element={
              <LayoutClient>
                <TermsAndConidiotns />
              </LayoutClient>
            }
          />
          <Route
            path={PATH_PAGE.signUpComplete}
            element={<RegisterCompleted />}
          />
          <Route path={PATH_PAGE.UserLogin} element={<UserLogin />} />
          <Route path={PATH_PAGE.UserSignUp} element={<UserSignUp />} />
          <Route
            path={PATH_PAGE.UserResetPassword}
            element={<UserResetPassword />}
          />
          <Route
            path={PATH_PAGE.UsertermsConditions}
            element={<TermsConidiotns />}
          />
        </Route>

        {/* mentor routes */}

        <Route element={<PrivateRouteMentor />}>
          {mentorStructure?.map((mentorData) => (
            <Route
              path={mentorData?.path}
              element={
                <LayoutMentor collapse={collapse} setCollapse={setCollapse}>
                  {mentorData?.element}
                </LayoutMentor>
              }
            />
          ))}
        </Route>

        {/* clients routes */}

        <Route element={<PrivateRouteClient />}>
          {clientStructure?.map((mentorData) => (
            <Route
              path={mentorData?.path}
              element={<LayoutClient> {mentorData?.element}</LayoutClient>}
            />
          ))}
          {clientDashboard?.map((mentorData) => (
            <Route
              path={mentorData?.path}
              element={
                <DashboardClient collapse={collapse} setCollapse={setCollapse}>
                  {mentorData?.element}
                </DashboardClient>
              }
            />
          ))}
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
