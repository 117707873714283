import React, { useContext } from "react";
import SVG from "react-inlinesvg";

import { Nav } from "react-bootstrap";
import { NavLink, useMatch, useNavigate } from "react-router-dom";
import {
  BsLayoutWtf,
  BsBoxArrowInRight,
  BsFillFilePersonFill,
  BsWallet2,
  BsBodyText,
  BsMailbox,
  BsList,
  BsQuestionCircle,
  BsTelephoneOutbound,
  BsChatDots,
  BsFillHeartFill,
} from "react-icons/bs";
import { FaCrown } from "react-icons/fa6";
import { LuPhoneCall } from "react-icons/lu";

import { toast } from "react-toastify";

import { iconPath } from "../../constants/constants";
import { AuthContext } from "../../auth/AuthProvider";
import { DashboaredHeader } from "../sharedComponents/DashboaredHeader";
import { CallAPI } from "../../config/api/ApiConfig";
import { PATH_PAGE } from "../../routes/Paths";
import { Tooltip, Zoom } from "@mui/material";
import { useIntl } from "react-intl";

export const SideBarClient = ({ collapse = true, setCollapse = () => {} }) => {
  const path = iconPath();
  const navigate = useNavigate();
  const { messages } = useIntl();
  const { user, setUser } = useContext(AuthContext);

  const { refetch } = CallAPI({
    name: "logoutClientNav",
    url: "/client/v1/logout",
    refetchOnWindowFocus: false,
    method: "post",
    enabled: false,
    onSuccess: (res) => {
      localStorage?.removeItem("user_data");
      setUser(null);
      navigate(PATH_PAGE?.clHome);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  return (
    <div className={`sidebar ${!collapse ? "collapsed" : ""}`}>
      <div
        onClick={() => navigate(PATH_PAGE?.clHome)}
        className={`sidebar-heading ${collapse ? "collapsed" : ""}`}
      >
        <SVG src={`${path}logo.svg`} className="website-image" />
        {user?.data?.photo ? (
          <img src={user?.data?.photo} alt="profile" className="logo-image" />
        ) : (
          <div className="rounded-div"></div>
        )}

        {/* <h1>{user?.data?.first_name}</h1> */}
      </div>
      {!collapse && (
        <DashboaredHeader
          text={`${user?.data?.first_name} ${user?.data?.last_name}`}
          className="heading_dash  text-center"
        />
      )}

      <Nav
        defaultActiveKey="/home"
        className={`scroller ${
          collapse ? "align-items-center" : "px-4  justify-content-center"
        }`}
      >
        <Tooltip
          title={messages["dashboard"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.userDashboard}
            className={`nav-link ${
              useMatch(PATH_PAGE.userDashboard) ? "active-link" : ""
            } ${collapse ? "justify-content-center" : ""}`}
          >
            <BsLayoutWtf className={`${collapse && "increase-width"}`} />
            {!collapse && messages["dashboard"]}
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["profile"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.Userprofile}
            className={`nav-link ${
              useMatch(PATH_PAGE.Userprofile) ? "active-link" : ""
            } ${collapse ? "justify-content-center" : ""}`}
          >
            <BsFillFilePersonFill
              className={`${collapse && "increase-width"}`}
            />{" "}
            {!collapse && messages["profile"]}
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["subcriptions"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.subscrip}
            className={`nav-link ${
              useMatch(PATH_PAGE.subscrip) ? "active-link" : ""
            } ${collapse ? "justify-content-center" : ""}`}
          >
            <FaCrown className={`${collapse && "increase-width"}`} />{" "}
            {!collapse && messages["subcriptions"]}
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["reservations"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.UserReserv}
            className={`nav-link ${
              useMatch(PATH_PAGE.UserReserv) ? "active-link" : ""
            } ${collapse ? "justify-content-center" : ""}`}
          >
            <LuPhoneCall className={`${collapse && "increase-width"}`} />{" "}
            {!collapse && messages["sessions"]}
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["messages_chat"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.chatUser}
            className={`nav-link ${
              useMatch(PATH_PAGE.chatUser) ? "active-link" : ""
            }  ${collapse ? "justify-content-center" : ""}`}
          >
            <BsChatDots className={`${collapse && "increase-width"}`} />{" "}
            {!collapse && messages["messages_chat"]}
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["wallet"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.walletUser}
            className={`nav-link ${
              useMatch(PATH_PAGE.walletUser) ? "active-link" : ""
            } ${collapse ? "justify-content-center" : ""}`}
          >
            <BsWallet2 className={`${collapse && "increase-width"}`} />{" "}
            {!collapse && messages["wallet"]}
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["Favourites"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.favourites}
            className={`nav-link ${
              useMatch(PATH_PAGE.favourites) ? "active-link" : ""
            } ${collapse ? "justify-content-center" : ""}`}
          >
            <BsFillHeartFill className={`${collapse && "increase-width"}`} />{" "}
            {!collapse && messages["Favourites"]}
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["privacy_policy"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.UserPrivacyPolicyDashboard}
            className={`nav-link ${
              useMatch(PATH_PAGE.UserPrivacyPolicyDashboard)
                ? "active-link"
                : ""
            } ${collapse ? "justify-content-center" : ""}`}
          >
            <BsQuestionCircle className={`${collapse && "increase-width"}`} />{" "}
            {!collapse && messages["privacy_policy"]}
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["about"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.UserAboutDashboard}
            className={`nav-link ${
              useMatch(PATH_PAGE.UserAboutDashboard) ? "active-link" : ""
            } ${collapse ? "justify-content-center" : ""}`}
          >
            <BsList className={`${collapse && "increase-width"}`} />{" "}
            {!collapse && messages["about"]}
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["contact_us"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.UserContact}
            className={`nav-link ${
              useMatch(PATH_PAGE.UserContact) ? "active-link" : ""
            } ${collapse ? "justify-content-center" : ""}`}
          >
            <BsMailbox className={`${collapse && "increase-width"}`} />{" "}
            {!collapse && messages["contact_us"]}
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["FAQ"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <NavLink
            to={PATH_PAGE.UserFaq}
            className={`nav-link ${
              useMatch(PATH_PAGE.UserFaq) ? "active-link" : ""
            } ${collapse ? "justify-content-center" : ""}`}
          >
            <BsBodyText className={`${collapse && "increase-width"}`} />{" "}
            {!collapse && messages["FAQ"]}
          </NavLink>
        </Tooltip>

        <Tooltip
          title={messages["logout"]}
          placement="right"
          TransitionComponent={Zoom}
        >
          <p
            onClick={() => refetch()}
            className={`nav-link ${
              collapse ? "justify-content-center increase-width" : ""
            }`}
          >
            <BsBoxArrowInRight className={`${collapse && "increase-width"}`} />{" "}
            {!collapse && messages["logout"]}
          </p>
        </Tooltip>

        {/* Add more NavLink items with icons as needed */}
      </Nav>

      {/* Toggle Button */}
      <div
        className={`toggle-btn ${collapse && "left-postion"}`}
        onClick={() => setCollapse(!collapse)}
      >
        {collapse ? ">" : "<"}
      </div>
    </div>
  );
};
