import * as React from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TextField } from "@mui/material";
import { addDays, subDays } from "date-fns";

export default function DateTimePickerValue({ dateValue, setDateValue }) {
  //   const [value, setValue] = React.useState(dayjs("2022-04-17T15:30"));

  const isVacation = (date) => {
    const day = date.getDay();

    return (
      day !== 0 && day !== 1 && day !== 2 && day !== 3 && day !== 4 && day !== 5
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label="Controlled picker"
        value={dateValue}
        name="date"
        onChange={(newValue) => setDateValue(newValue)}
        format="YYYY-MM-DD"
        views={["year", "month", "day"]}
        disablePast={true}
        minDate={dayjs(new Date())}
        filterDate={isVacation}

        // slots={{
        //   textField: (params) => <TextField variant="filled" {...params} />
        // }}

        // excludeDateIntervals={[
        //   {
        //     start: subDays(new Date(), 5),
        //     end: addDays(new Date(), 5),
        //   },
        // ]}
      />
    </LocalizationProvider>
  );
}
