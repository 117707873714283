import React, { useContext } from "react";
import { ButtonCustom } from "../../../../components/sharedComponents/ButtonCustom";
import { CallAPI } from "../../../../config/api/ApiConfig";
import { toast } from "react-toastify";
import { AxiosContext } from "../../../../config/api/AxiosConfig";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { BsPaperclip } from "react-icons/bs";
import { AuthContext } from "../../../../auth/AuthProvider";
import { PATH_PAGE } from "../../../../routes/Paths";
import { LocaleContext } from "../../../../config/localization/LanguageProvider";

export const PublicResum = () => {
  const { user } = useContext(AuthContext);
  const { setLoader } = useContext(AxiosContext);
  const { messages } = useIntl();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      personal_introduction: "",
      pdf: "",
    },
  });

  const { data: publicProfile } = CallAPI({
    name: "callProfile",
    url: "/mentor/v1/auth/public-profile",
    refetchOnWindowFocus: false,
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      setValue("personal_introduction", res?.personal_introduction);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const { locale } = useContext(LocaleContext);

  const registerUser = async () => {
    const formData = new FormData();

    formData.append("personal_introduction", watch("personal_introduction"));
    if (Object.values(watch("pdf"))[0]) {
      formData.append("resume_file", Object.values(watch("pdf"))[0] || "");
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/mentor/v1/auth/edit-public-profile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user?.token}`,
            "Accept-Language": locale,
          },
        }
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
      setLoader(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoader(false);
    }
  };

  const onSubmit = (data) => {
    setLoader(true);
    registerUser();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="profile">
      <div className="container-fluid">
        <div className="head_box mb-4">
          <h2 className="heading_dash mb-1">
            {messages["edit_public_profile"]}
          </h2>
          <p className="heading_dis">{messages["edit_public_profile_info"]}</p>
        </div>
        <h2 className="heading_dash mb-3">
          <small>{messages["resume_info"]}</small>
        </h2>

        <div className="row">
          <div className="col-md-7">
            <div className="prof_card shadow-none p-0">
              {/* <h3>Upload resume</h3>

              <div className="input-group p-1 mb-3 justify-content-between">
                <label htmlFor="pdfInput" className="file-input-label w-75">
                  <span className="input-group-text bg-transparent border-0 p-0">
                    <SVG src={`${path}attash.svg`} />
                  </span>
                  <input
                    type="file"
                    id="pdfInput"
                    accept=".pdf"
                    className="file-input"
                  />
                </label>

                <button className="btn border-0">
                  <span className="input-group-text bg-transparent border-0 p-0">
                    <SVG src={`${path}trash.svg`} />
                  </span>
                </button>
              </div> */}
              <div className="mb-4">
                <div className="mentor-input">
                  <label className="label-file">{messages["resume"]}</label>
                  <label htmlFor="pdfInput" className="file-input-label">
                    <BsPaperclip className="file-pdf" />
                    {watch("pdf") && Object?.values(watch("pdf"))[0]
                      ? Object.values(watch("pdf"))[0]?.name
                      : publicProfile?.resume_file
                      ? "Resume.pdf"
                      : messages["upload_resume_required"]}
                  </label>
                  <input
                    type="file"
                    id="pdfInput"
                    accept=".pdf"
                    className="file-input"
                    {...register("pdf")}
                  />{" "}
                  {errors.pdf && (
                    <span className="mentor-input__field-error">
                      {errors.pdf?.message}
                    </span>
                  )}
                </div>
              </div>
              {/* <h3>personal introduction</h3>
              <div className="res_box">
                <p>{publicProfile?.personal_introduction}</p>
              </div> */}
              <div className="mb-3">
                <div className=" position-relative">
                  <label
                    className="textAreaLabelDefault"
                    htmlFor="personal_introduction"
                  >
                    {messages["personla_introduction"]}
                  </label>
                  <textarea
                    className="textAreaDefault"
                    id="personal_introduction"
                    name="personal_introduction"
                    {...register("personal_introduction", {
                      required: messages["personal_introduction_required"],
                      minLength: {
                        value: 10,
                        message: messages["personal_introduction_min"],
                      },
					  maxLength: {
                        value: 100,
                        message: messages["personal_introduction_max"],
                      },
                    })}
                  ></textarea>

                  {errors.personal_introduction && (
                    <span className="mentor-input__field-error-text-box">
                      {errors.personal_introduction?.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-2">
              <ButtonCustom className="w-100" text="Save" type="submit" />
            </div>
            <div className="col-md-2">
              <ButtonCustom
                className="w-100 btn_dark"
                text="Decline"
                type="button"
                onClick={() => navigate(PATH_PAGE?.publicProfile)}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
