import React from "react";
import { iconPath } from "../../constants/constants";

const Spinner = ({ isLoading = false }) => {
  const path = iconPath();

  return (
    isLoading && (
      <div className="spinner-container">
        <div className="spinner">
          <img src={`${path}loading.gif`} alt="imgLoader" />
        </div>
      </div>
    )
  );
};

export default Spinner;
