import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../auth/AuthProvider";
import { ButtonCustom } from "../../../../components/sharedComponents/ButtonCustom";
import { CallAPI } from "../../../../config/api/ApiConfig";
import { AxiosContext } from "../../../../config/api/AxiosConfig";
import { validateMaxThreeDigits } from "../../../../constants/constants";

export const AddPackage = ({ goBackToallServices }) => {
  const { messages } = useIntl();
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const { setLoader } = useContext(AxiosContext);
  const [selectedImage, setSelectedImage] = useState(null);

  const searchParams = new URLSearchParams(location.search);

  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const { data: service } = CallAPI({
    name: "getPackage",
    url: `/mentor/v1/services/${id}`,
    refetchOnWindowFocus: false,
    enabled: id && type === "private_sessions_package" ? true : false,
    cacheTime: 500,
    staleTime: 500,
    select: (res) => res?.data?.data,
    onError: (err) => {
      toast.error(err?.response?.message);
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      title: "",
      price: "",
      number: "",
      expiry: "",
      description: "",
      period: "",
      service_type: "private_sessions_package",
    },
  });

  const submitData = async () => {
    const formData = new FormData();

    formData.append("title", watch("title"));
    formData.append("price", watch("price"));
    formData.append("package_session_number", watch("number"));
    formData.append("package_validity", watch("expiry"));
    formData.append("description", watch("description"));
    formData.append("service_type", watch("service_type"));
    formData.append("period", watch("period"));
    if (selectedImage?.name) {
      formData.append("image", selectedImage);
    }

    try {
      let url = `${process.env.REACT_APP_DOMAIN}/mentor/v1/services`;
      if (service?.id) {
        url += `/${service.id}/update`;
      }
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user?.token}`,
        },
      });

      if (response?.data?.success) {
        if (service?.id) {
          toast.success(messages["updated.successfully"]);
        } else {
          toast.success(messages["created.successfully"]);
        }
        goBackToallServices();
      } else {
        toast.error(response?.data?.message);
      }
      setLoader(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoader(false);
    }
  };

  const onSubmit = (data) => {
    setLoader(true);
    submitData();
  };

  useEffect(() => {
    if (watch("image")) {
      const file = Object.values(watch("image"))[0];
      setSelectedImage(file);
    }
  }, [watch, watch("image")]);

  useEffect(() => {
    if (service?.id) {
      setValue("title", service?.name);
      setValue("price", service?.price);
      setValue("period", service?.period);
      setValue("description", service?.description);
      setValue("service_type", service?.service);
      setValue("number", service?.session_number);
      setValue("expiry", service?.package_validity);
    }
  }, [service?.id]);

  const expirations = [
    {
      id: 1,
      name: "expiry.month",
    },
    {
      id: 2,
      name: "expiry.two.month",
    },
    {
      id: 3,
      name: "expiry.three.month",
    },
    {
      id: 6,
      name: "expiry.six.month",
    },
    {
      id: 12,
      name: "expiry.year",
    },
  ];
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col xs={12}>
          <Col md={12} className="mb-4">
            <div className="mentor-input">
              <label className="mentor-input__label">
                {messages["service_title"]}
              </label>
              <input
                className="mentor-input__field"
                type="text"
                name="title"
                {...register("title", {
                  required: messages["service_title_required"],
                  minLength: {
                    value: 4,
                    message: messages["service_title_min"],
                  },
                  maxLength: {
                    value: 40,
                    message: messages["service_title_max"],
                  },
                })}
              />
              {errors.title && (
                <span className="mentor-input__field-error">
                  {errors.title?.message}
                </span>
              )}
            </div>
          </Col>
          <Col md={12} className="mb-4">
            <div className="mentor-input">
              <label className="mentor-input__label">
                {messages["servicec_price"]}
              </label>
              <input
                className="mentor-input__field"
                type="text"
                name="price"
                {...register("price", {
                  required: messages["servicec_price_required"],
                  pattern: {
                    value: /^[0-9]+(?:\.[0-9]{1,2})?$/, // Regex pattern for a valid price (e.g., 10 or 10.99)
                    message: messages["servicec_price_valid"],
                  },
                })}
              />
              {errors.price && (
                <span className="mentor-input__field-error">
                  {errors.price?.message}
                </span>
              )}
            </div>
          </Col>

          <Col md={12} className="mb-4">
            <div className="mentor-input">
              <label className="mentor-input__label">
                {messages["serivce_num"]}
              </label>
              <input
                className="mentor-input__field"
                type="text"
                name="number"
                {...register("number", {
                  required: messages["service_num_required"],
                  pattern: {
                    value: /^\d{1,2}$/, // Regular expression for 1 or 2 digits
                    message: messages["service_num_pattern"],
                  },
                })}
              />
              {errors.number && (
                <span className="mentor-input__field-error">
                  {errors.number?.message}
                </span>
              )}
            </div>
          </Col>

          <Col md={12} className="mb-4">
            <div className="d-flex flex-column position-relative mb-10">
              <label className="selectInputLabel" htmlFor="expiry">
                {messages["service_expiry_date"]}
              </label>
              <select
                {...register("expiry", {
                  required: messages["service_expiry_required"],
                })}
                control={control}
                name="expiry"
                className="sign-select selectInputDefault"
                onChange={(e) => {
                  setValue("expiry", e?.target?.value);
                  clearErrors("expiry");
                }}
              >
                <option value=""></option>
                {expirations?.map((coun) => (
                  <option key={coun?.id} value={coun?.id}>
                    {messages[coun?.name]}
                  </option>
                ))}
              </select>
              {errors.expiry && (
                <span className="mentor-input__field-error">
                  {errors.expiry?.message}
                </span>
              )}
            </div>
          </Col>
        </Col>

        <Col xs={12}>
          <Col md={12} className="mb-4 pb-1">
            <div className=" position-relative">
              <label className="textAreaLabelDefault" htmlFor="description">
                {messages["service_description"]}
              </label>
              <textarea
                className="textAreaDefault"
                id="description"
                name="description"
                {...register("description", {
                  required: messages["service_desc_required"],
                  minLength: {
                    value: 3,
                    message: messages["service_desc_min"],
                  },
                  maxLength: {
                    value: 200,
                    message: messages["service_desc_max"],
                  },
                })}
              ></textarea>

              {errors.description && (
                <span className="mentor-input__field-error-text-box">
                  {errors.description?.message}
                </span>
              )}
            </div>
          </Col>

          <Col md={12} className="mb-4 pt-2">
            <div className="mentor-input">
              <label className="mentor-input__label">
                {messages["session_duration"]}
              </label>
              <input
                className="mentor-input__field"
                type="text"
                name="period"
                {...register("period", {
                  required: messages["session_duration_required"],
                  validate: (val) => validateMaxThreeDigits(val, messages),
                })}
              />
              {errors.period && (
                <span className="mentor-input__field-error">
                  {errors.period?.message}
                </span>
              )}
            </div>
          </Col>
          <Col md={12} className="mb-4">
            <div className="mentor-input">
              <label className="label-file">
                {messages["service_upload_image"]}
              </label>
              <label htmlFor="imageInput" className="file-input-label">
                {watch("image") && Object?.values(watch("image"))[0]
                  ? Object.values(watch("image"))[0]?.name
                  : service?.image
                  ? "image.jpg"
                  : messages["service_upload_image_required"]}
              </label>
              <input
                type="file"
                id="imageInput"
                className="file-input"
                accept=".jpeg, .jpg, .png"
                {...register("image", {
                  ...(id
                    ? {}
                    : { required: messages["service_upload_image_required"] }),
                })}
              />{" "}
              {errors.image && (
                <span className="mentor-input__field-error">
                  {errors.image?.message}
                </span>
              )}
            </div>
          </Col>
        </Col>
        <Col xs={12} className="mt-4">
          <ButtonCustom
            className="sm-btn"
            text={`${service?.id ? "Update" : "Add"}`}
            type="submit"
          />
        </Col>
      </Row>
    </form>
  );
};
