import React from "react";

import Pagination from "react-bootstrap/Pagination";

export const PaginationBar = ({
  handlePageChange = () => {},
  currentPage = 1,
  totalPages = 1,
}) => {
  const renderPagination = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  return (
    <Pagination className="custom_pag">
      <Pagination.Prev
        disabled={currentPage === 1}
        onClick={() => currentPage !== 1 && handlePageChange(currentPage - 1)}
      />
      {renderPagination()}
      <Pagination.Next
        disabled={currentPage === totalPages}
        onClick={() =>
          currentPage !== totalPages && handlePageChange(currentPage + 1)
        }
      />
    </Pagination>
  );
};
