import React, { useContext, useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { IoIosClose } from "react-icons/io";
import { FirstHeader } from "../../../components/sharedComponents/FirstHeader";
import { CallAPI } from "../../../config/api/ApiConfig";
import axios from "axios";
import { AuthContext } from "../../../auth/AuthProvider";

export const InterestsUser = () => {
  const { user } = useContext(AuthContext);
  const { messages, locale } = useIntl();
  const [selectedItem, setSelectedItem] = useState([]);
  const [searchBy, setSearchBy] = useState(null);
  const industriesConst = "industries";
  const categoriesConst = "categories";
  const experiencesConst = "experiences";
  const toolsConst = "tools";
  const languagesConst = "languages";

  const { refetch: callInterest } = CallAPI({
    name: "interest",
    url: "/client/v1/auth/interests",
    refetchOnWindowFocus: false,
    select: (res) => res?.data,
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    onSuccess: (res) => {
      setSelectedItem([
        {
          name: categoriesConst,
          data: res?.categories,
        },
        {
          name: experiencesConst,
          data: res?.experiences,
        },
        {
          name: industriesConst,
          data: res?.industries,
        },
        {
          name: languagesConst,
          data: res?.languages,
        },
        {
          name: toolsConst,
          data: res?.tools,
        },
      ]);
    },
  });

  const { data: industries } = CallAPI({
    name: "industyInterest",
    url: "/industries",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const { data: categories } = CallAPI({
    name: "categoryInterest",
    url: `/categories`,
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) =>
      res?.data?.data?.map((d) => ({ name: d?.name, id: d?.id })),
  });

  const { data: langs } = CallAPI({
    name: "languageInterest",
    url: "/languages",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) =>
      res?.data?.data?.map((d) => ({
        id: d?.id,
        name: d?.name,
        // name: locale === "ar" ? d?.name_ar : d?.name_en,
      })),
  });

  const { data: tools } = CallAPI({
    name: "toolInterest",
    url: "/tools",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) =>
      res?.data?.data?.map((d) => ({
        id: d?.id,
        name: d?.name,
        // name: locale === "ar" ? d?.name_ar : d?.name_en,
      })),
  });

  const { data: experiences } = CallAPI({
    name: "callExperience",
    url: "/mentor/v1/experiences",
    refetchOnWindowFocus: false,
    enabled: true,
    select: (res) => res?.data?.data,
  });

  const checkSelectedOrNot = (id, interstedType) => {
    const arrayOfIds = selectedItem
      ?.find((d) => d?.name === interstedType)
      ?.data?.map((d) => +d?.id);
    if (arrayOfIds?.includes(id)) {
      return true;
    }
    return false;
  };

  const addInterest = async (newData = selectedItem) => {
    const formData = new FormData();
    const industries = newData?.find((d) => d?.name === industriesConst);
    const categories = newData?.find((d) => d?.name === categoriesConst);
    const experiences = newData?.find((d) => d?.name === experiencesConst);
    const languages = newData?.find((d) => d?.name === languagesConst);
    const tools = newData?.find((d) => d?.name === toolsConst);

    if (categories?.data?.length) {
      categories?.data?.forEach((info, index) => {
        formData.append(`categories[${index}]`, info?.id);
      });
    }

    if (experiences?.data?.length) {
      experiences?.data?.forEach((info, index) => {
        formData.append(`experiences[${index}]`, info?.id);
      });
    }

    if (industries?.data?.length) {
      industries?.data?.forEach((info, index) => {
        formData.append(`industries[${index}]`, info?.id);
      });
    }

    if (languages?.data?.length) {
      languages?.data?.forEach((info, index) => {
        formData.append(`languages[${index}]`, info?.id);
      });
    }

    if (tools?.data?.length) {
      tools?.data?.forEach((info, index) => {
        formData.append(`tools[${index}]`, info?.id);
      });
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/client/v1/auth/interests`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );

      if (response?.data?.success) {
        callInterest();
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const addNewValue = (newObj, interstedType) => {
    setSelectedItem((prevState) => {
      const newState = [...prevState];
      const industriesIndex = newState.findIndex(
        (item) => item.name === interstedType
      );
      if (industriesIndex !== -1) {
        newState[industriesIndex].data.push(newObj);
      }
      return newState;
    });
    setTimeout(() => {
      addInterest();
    }, 100);
  };

  const removeSavedValue = (objToRemove, interstedType) => {
    setSelectedItem((prevState) => {
      const newState = prevState.map((item) => {
        const industriesIndex = selectedItem.findIndex(
          (item) => item.name === interstedType
        );
        if (item.name === interstedType) {
          const newData = selectedItem[industriesIndex].data?.filter(
            (d) => d?.id !== objToRemove?.id
          );

          return { ...item, data: newData };
        }
        return item;
      });
      return newState;
    });

    addInterest(
      selectedItem.map((item) => {
        const industriesIndex = selectedItem.findIndex(
          (item) => item.name === interstedType
        );
        if (item.name === interstedType) {
          const newData = selectedItem[industriesIndex].data?.filter(
            (d) => d?.id !== objToRemove?.id
          );

          return { ...item, data: newData };
        }
        return item;
      })
    );
  };

  const WhenSearchBy = (data) => {
    if (searchBy) {
      return data?.filter(
        (item) =>
          searchBy && item.name.toLowerCase().includes(searchBy.toLowerCase())
      );
    } else {
      return data;
    }
  };

  const renderTabs = (data, title, keyName) => (
    <Tab eventKey={title} title={messages[title]}>
      <div className="input-group mb-3 p-2">
        <input
          className="form-control border-0 bg-transparent"
          placeholder={messages["Search_for_new_skill"]}
          type="search"
          value={searchBy}
          onChange={(e) => setSearchBy(e?.target?.value)}
        />
      </div>
      <div className="interested">
        {WhenSearchBy(data)?.map((item) => (
          <div
            className={`interest ${
              checkSelectedOrNot(+item?.id, keyName) && "selected"
            }`}
            onClick={() => {
              if (!checkSelectedOrNot(+item?.id, keyName)) {
                addNewValue(item, keyName);
              }
            }}
          >
            {item?.name}{" "}
            {checkSelectedOrNot(+item?.id, keyName) && (
              <IoIosClose
                onClick={(e) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                  removeSavedValue(item, keyName);
                }}
              />
            )}
          </div>
        ))}
      </div>
    </Tab>
  );

  return (
    <div className="container-fluid faq_wrapper intersed_user mb-5">
      <div className="text-center mb-5">
        <FirstHeader text={"interests"} />
      </div>
      <div className="row">
        <div className="col-lg-9 col-md-11 col-12">
          <Tabs
            defaultActiveKey="industry"
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={() => setSearchBy("")}
          >
            {renderTabs(industries, "industry", industriesConst)}
            {renderTabs(categories, "specialization", categoriesConst)}
            {renderTabs(experiences, "experiences", experiencesConst)}
            {renderTabs(tools, "tools", toolsConst)}
            {renderTabs(langs, "languages", languagesConst)}
          </Tabs>
        </div>
      </div>
    </div>
  );
};
