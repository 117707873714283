import { DashboaredHeader } from "../../components/sharedComponents/DashboaredHeader";
import React from "react";
import ReservBars from "./ReservBars";
import SalesBars from "./SalesBars";
import { useIntl } from "react-intl";

const Chart = () => {
  const { messages } = useIntl();
  return (
    <div className="chart_wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 ">
            <DashboaredHeader
              text={`${messages["reservationsGraph"]}`}
              className="heading_dash"
            />

            <div className="chart_box mb-3 ">
              <ReservBars />
            </div>
          </div>
          <div className="col-md-6">
            <DashboaredHeader
              text={messages["potential_hours"]}
              className="heading_dash"
            />

            <div className="chart_box mb-3">
              <SalesBars />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chart;
